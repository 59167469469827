// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcZKniTDtK5sx7K9n6KbeDZgsHNBF7h4Q",
  authDomain: "sound-arcade-303810.firebaseapp.com",
  projectId: "sound-arcade-303810",
  storageBucket: "sound-arcade-303810.appspot.com",
  messagingSenderId: "95818451674",
  appId: "1:95818451674:web:f12ebbc7b72534afa133e3",
  measurementId: "G-711NY2RXLD",
};

const vapidKey =
  "BGd7qTLEcDwgItUQSyq2jclH4Go4wEpnq4J_JRbQ-qtHi99ZDsDlrqkg15iicSE24zagpBPQTo4bFPRt50KeO4o";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getFCMToken = () =>
  new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey,
    })
      .then((currentToken) => {
        resolve(currentToken);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
