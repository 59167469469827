import axios from "utils/axios";

export const getCompanyDetails = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`company/getcompany?companyId=${companyId}`)
      .then((response) => {
        resolve(response?.data?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAdminDetails = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/company/admin/company/${companyId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// export const updateCompanyDetails = (companyId) =>
//   new Promise((resolve, reject) => {
//     axios
//       .patch(`company/update/${companyId}`)
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });

export const updateCompanyDetails = (companyId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`company/update/${companyId}`, data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateCompanyPublishData = (companyId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/company/update/unpublish/${companyId}`, data)
      .then((response) => resolve(response?.data))
      .catch((error) => reject(error));
  });

export const uploadCompanySceneProfileVideo = (companyId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/company/upload/profile_video/${companyId}`, data)
      .then((response) => resolve(response?.data))
      .catch((error) => reject(error));
  });

export const getCompanyList = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/company/getcompany/list`)
      .then((response) => resolve(response?.data))
      .catch((error) => reject(error));
  });

 
  
