import axios from "utils/axios";

export const createScene = (data: any) => new Promise((resolve, reject) => {
    axios
    .post(`/scene/create`, data)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const updateSceneById = (id: string, data: any) => new Promise((resolve, reject) => {
    axios
    .post(`/scene/update/by/id/${id}`, data)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});

export const updateSceneByCreator = (id: string, data: any) => new Promise((resolve, reject) => {
    axios
    .post(`/scene/update/by/created/${id}`, data)
    .then((response) => {
      resolve(response);
    })
    .catch((error) => {
      reject(error);
    });
});


export const getSceneById = (id: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/scene/find/by/id/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getSceneByCreated = (id: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/scene/find/by/created/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getSceneList = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`/scene/find/list`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
