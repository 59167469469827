import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ConfigState {
  config: {
    sidebar: {
      isExpanded: boolean;
    };
    supportChatWindow: {
      open: boolean;
    };
  };
}

const initialState: ConfigState = {
  config: {
    sidebar: {
      isExpanded: true,
    },
    supportChatWindow: {
      open: false,
    },
  },
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateSidebarConfiguration: (
      state: any,
      action: PayloadAction<ConfigState>,
    ) => {
      state.config.sidebar = action.payload;
    },
    updateSupportWindowState: (state: any, action: PayloadAction<boolean>) => {
      state.config.supportChatWindow.open = action.payload;
    },
  },
});

export const { updateSidebarConfiguration, updateSupportWindowState } =
  configSlice.actions;

export default configSlice.reducer;
