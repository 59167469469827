import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PipelineFilterState {
  pipeline: any;
}

interface UpdatePipelineFilterPayload {
  // Define a type for the payload object
  [key: string]: any;
}

const initialState: PipelineFilterState = {
  pipeline: {},
};

export const pipelineFilterSlice = createSlice({
  name: "pipeline",
  initialState,
  reducers: {
    updatePipelineFilter: (state, action: PayloadAction<UpdatePipelineFilterPayload>) => {
      state.pipeline = { ...state.pipeline, ...action.payload };
    },
  },
});

export const { updatePipelineFilter } = pipelineFilterSlice.actions;

export default pipelineFilterSlice.reducer;

