import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChatState {
  userChat: {
    [userId: string]: {
      messages: array[];
      page?: number;
    };
  };
  users: {};
  selectedCandidate: {};
}

const initialState: ChatState = {
  userChat: {},
  users: {},
  selectedCandidate: {},

};

export const chatSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateSelectChatUser: (state, action: PayloadAction<any>) => {
      state.selectedChatUser = action.payload;
    },

    updateSelectedCandidate: (state, action: PayloadAction<ChatState>) => {
      state.selectedCandidate = action.payload;
    },
    updateUserList: (state, action: PayloadAction<ChatState>) => {
      state.users = action.payload;
    },

    createUserChat: (state, action: PayloadAction<any>) => {
      // state.userChat = {};
      state.userChat = {
        ...state.userChat,
        [action?.payload?.userId]: {
          messages: [...action?.payload?.chatList],
          limit: action?.payload?.limit,
          page: action?.payload?.page,
        },
      };
    },

    updateUserChat: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        userChat: {
          ...state.userChat,
          [action?.payload?.userId]: {
            messages: [
              ...action?.payload?.chatList,
              ...state.userChat[action.payload?.userId]?.messages,
            ],
            limit: action?.payload?.limit,
            page: action?.payload?.page,
          },
        },
      };
    },

    updateUserNewChat: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        userChat: {
          ...state.userChat,
          [action?.payload?.userId]: {
            messages: [
              ...state.userChat[action.payload?.userId]?.messages,
              action?.payload?.chat,
            ],
          },
        },
      };
    },
  },
});

export const {
  updateSelectedCandidate,
  updateUserList,
  createUserChat,
  updateUserChat,
  updateUserNewChat,
  updateSelectChatUser
} = chatSlice.actions;

export default chatSlice.reducer;
