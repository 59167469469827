import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  DEFAULT_AVATAR,
  DEFAULT_AVATAR_BACKGROUND,
} from "constants/production";

const initialState = {
  avatarCreator: {
    preset: {
      _id: "",
      name: "",
    },
    camera: {
      cameraPosition:
      "[0.6966650351019719,8.318481167468148,12.335823127179461]",
    orbitalTarget:
      "[1.8639481371001467,0.3742773829268109,-8.286327131234016]",
    },
    avatar: {
      url: DEFAULT_AVATAR,
      type: "female",
      position: {
        x: 0,
        y: -3.1,
        z: 0,
      },
      rotate: {
        x: 0,
        y: 6.3,
        z: 0,
      },
      scale: 7,
      speechVoice: "en-US-EmmaNeural",
      speechText: "Hello, How can I help you?",
      welcomeText: "Hi, Welcome",
      background: {
        id: "a443b8a0a2314a55ae5dee4dd6a151a0",
        title: "White round exhibition gallery",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "white_round_exhibition_gallery_1k.glb",
        url: "https://sketchfab.com/models/a443b8a0a2314a55ae5dee4dd6a151a0/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 17,
          z: 0,
        },
        scale: 4,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
};

export const avatarCreatorSlice = createSlice({
  name: "avatarCreator",
  initialState,
  reducers: {
    updateAvatarCreator: (state, action: PayloadAction<any>) => {
      state.avatarCreator = action.payload;
    },
    updateAvatarCameraConfig: (state, action: PayloadAction<any>) => {
      state.avatarCreator.camera = action.payload;
    },
    updateAvatarType: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.type = action.payload;
    },
    updateAvatarPosition: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.position = action.payload;
    },
    updateAvatarRotate: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.rotate = action.payload;
    },
    updateAvatarScale: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.scale = action.payload;
    },
    updateAvatarUrl: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.url = action.payload;
    },
    updateAvatarSpeechText: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.speechText = action.payload;
    },
    updateAvatarSpeechVoice: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.speechVoice = action.payload;
    },
    updateAvatarWelcomeText: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.welcomeText = action.payload;
    },
    updateAvatarBackground: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.background = action.payload;
    },
    updateRoomPosition: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.room.position = action.payload;
    },
    updateRoomRotate: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.room.rotate = action.payload;
    },
    updateRoomScale: (state, action: PayloadAction<any>) => {
      state.avatarCreator.avatar.room.scale = action.payload;
    },
    resetAvatarCreator: (state) => {
      state.avatarCreator = initialState.avatarCreator;
    },
  },
});

export const {
  updateAvatarCreator,
  updateAvatarCameraConfig,
  updateAvatarType,
  updateAvatarPosition,
  updateAvatarRotate,
  updateAvatarScale,
  updateAvatarUrl,
  updateAvatarSpeechText,
  updateAvatarSpeechVoice,
  updateAvatarWelcomeText,
  updateAvatarBackground,
  updateRoomPosition,
  updateRoomRotate,
  updateRoomScale,
  resetAvatarCreator,
} = avatarCreatorSlice.actions;

export default avatarCreatorSlice.reducer;
