import axios from "utils/axios";

type AuthUserType = {
  name: string | undefined;
  username: string | undefined;
  email: string | undefined;
  userType: string | undefined;
  company?: any | undefined;
  country: any;
  phone: string | undefined;
  password: string | undefined;
};

type UserDataType = {
  email: string;
  password: string;
};

/**
 * get company list
 * @returns Array of company details with id and name
 */
export const getCompanyList = () =>
  new Promise((resolve, reject) => {
    axios
      .get("/company/getcompany/list")
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

/**
 * create a new user
 * @param data : user data
 * @returns Promise resolved with user account details
 */
export const createAuthUser = (data: AuthUserType) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/create", data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const userLogin = ({ email, password }: UserDataType) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/users/login`, {
        email,
        password,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// verify token
export const verifyToken = (token: string) => {
  if (token === null) {
    return Promise.reject(new Error("Token is null"));
  } else {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    return axios
      .post("/users/verify-token")
      .then((response) => response.data)
      .catch((error) => Promise.reject(error));
  }
};

export const verifyCandidateAccount = (userId: string, code: string) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/users/candidate/verify/account/${userId}/${code}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateFirstLoginData = (userId: string, fistLogin: Object) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/login/${userId}`, fistLogin)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const updateSpaceSetupPageVisited = (userId: string, pageVisited: Object) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/space-setup/${userId}`, pageVisited)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

  export const getPlan = (companyId) =>
    new Promise((resolve, reject) => {
      axios
        .get(`/users/findPlan/plan/company/${companyId}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
