import { createTheme } from "@mui/system";

export const lightTheme = createTheme({
  mode: "light",
  palette: {
    type: "main",
    primary: {
      main: "#007bff",
      light: "#448aff",
      dark: "#2962ff",
    },
    secondary: {
      main: "#f50057",
      light: "#ff4081",
      dark: "#c51162",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: "#fff",
      default: "#fff",
    },
    error: {
      main: "#ff1744",
      light: "#ff5252",
      dark: "#d50000",
    },
    warning: {
      main: "#ff9800",
      light: "#ffab40",
      dark: "#ff6d00",
    },
    info: {
      main: "#0091ea",
      light: "#80d8ff",
      dark: "#01579b",
    },
    success: {
      main: "#00e676",
      light: "#69f0ae",
      dark: "#00c853",
    },
    divider: "rgba(0,0,0,0.08)",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Poppins"',
      '"Inter"',
      '"Josefin Sans"',
      "Roboto",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Poppins"',
            '"Inter"',
            '"Josefin Sans"',
            "Roboto",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  mode: "dark",
  palette: {
    type: "main",
    primary: {
      main: "#007bff",
      light: "#448aff",
      dark: "#2962ff",
    },
    secondary: {
      main: "#f50057",
      light: "#ff4081",
      dark: "#c51162",
    },
    text: {
      primary: "#fff",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
      icon: "rgba(255, 255, 255, 0.5)",
    },
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#121212",
      default: "#121212",
    },
    error: {
      main: "#ff1744",
      light: "#ff5252",
      dark: "#d50000",
    },
    warning: {
      main: "#ff9800",
      light: "#ffab40",
      dark: "#ff6d00",
    },
    info: {
      main: "#0091ea",
      light: "#80d8ff",
      dark: "#01579b",
    },
    success: {
      main: "#00e676",
      light: "#69f0ae",
      dark: "#00c853",
    },
    divider: "rgba(0,0,0,0.08)",
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Poppins"',
      '"Inter"',
      '"Josefin Sans"',
      "Roboto",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Poppins"',
            '"Inter"',
            '"Josefin Sans"',
            "Roboto",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(","),
        },
      },
    },
  },
});
