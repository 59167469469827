import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { DEFAULT_AVATAR } from "constants/production";

// Define the type for the avatar state
type AvatarState = {
  preset: {
    _id: string;
    name: string;
  };
  camera: {
    cameraPosition: string;
    orbitalTarget: string;
  };
  avatar: {
    url: string;
    type: string;
    position: {
      x: number;
      y: number;
      z: number;
    };
    rotate: {
      x: number;
      y: number;
      z: number;
    };
    scale: number;
    speechVoice: string;
    speechText: string;
    welcomeText: string;
    background: {
      id: string;
      title: string;
      author: {
        name: string;
        url: string;
      };
      file: string;
      url: string;
    };
    room: {
      position: {
        x: number;
        y: number;
        z: number;
      };
      scale: number;
      rotate: {
        x: number;
        y: number;
        z: number;
      };
    };
  };
};

type SpaceContextDataType = {
  profile: any;
  spaceContextData: { space: AvatarState; profile: any };
};

// Define the type for the context state, including the setter function
type SpaceContextType = {
  spaceContextData: { space: AvatarState; profile: any };

  setSpaceContextData: Dispatch<SetStateAction<SpaceContextDataType>>;
};

// Define the initial context value with the correct type for setSpaceContextData
export const initialContext: SpaceContextType = {
  spaceContextData: {
    space: {
      preset: {
        _id: "",
        name: "",
      },
      camera: {
        cameraPosition:
          "[36.88198436566039,-6.644599339391277,-2.3356798570944277]",
        orbitalTarget:
          "[-0.009305758408176915,-6.64459933939128,-4.61252014903409]",
      },
      avatar: {
        url: DEFAULT_AVATAR,
        type: "female",
        position: {
          x: 24,
          y: -15,
          z: 0,
        },
        rotate: {
          x: 0,
          y: 8,
          z: 0,
        },
        scale: 6,
        speechVoice: "en-US-EmmaNeural",
        speechText: "",
        welcomeText: "Hi, Welcome",
        background: {
          id: "5540183da1c7452f810f8de33734879a",
          title: "Minimalistic Modern Office",
          author: {
            name: "dylanheyes",
            url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
          },
          file: "minimalistic_modern_office_1k.glb",
          url: "https://sketchfab.com/models/5540183da1c7452f810f8de33734879a/embed?autospin=0&preload=1&dnt=1",
        },
        room: {
          position: {
            x: 0,
            y: -8,
            z: 0,
          },
          scale: 5.1,
          rotate: {
            x: 0,
            y: 0,
            z: 0,
          },
        },
      },
    },
    profile: {},
  },
  setSpaceContextData: () => {},
};

const SpaceContext = createContext(initialContext);

// Listen for changes in localStorage to catch the broadcast of userId
window.addEventListener("storage", (event) => {
  if (event.key === "broadcastUserId") {
    const userId = event.newValue;
    sessionStorage.setItem("userId", userId);
    // Optionally, clear the broadcastUserId from localStorage after capturing it
    localStorage.removeItem("broadcastUserId");
  }
});

const SpaceProvider = ({ children }) => {
  // const [spaceContextData, setSpaceContextData] = useState<any>(
  //   initialContext.spaceContextData,
  // );

  // Attempt to retrieve userId from sessionStorage
  const userId = sessionStorage.getItem("userId");

  const [spaceContextData, setSpaceContextData] = useState(() => {
    // Load context from storage on initial load
    return loadContextFromStorage(userId) || initialContext.spaceContextData;
  });

  useEffect(() => {
    // Save context to storage whenever it changes
    saveContextToStorage(userId, spaceContextData);
  }, [spaceContextData, userId]);

  return (
    <SpaceContext.Provider value={{ spaceContextData, setSpaceContextData }}>
      {children}
    </SpaceContext.Provider>
  );
};

const useSpaceContext = () => useContext(SpaceContext);

export { SpaceProvider, useSpaceContext };

function saveContextToStorage(userId, contextData) {
  const serializedData = JSON.stringify(contextData);
  sessionStorage.setItem(`spaceContextData-${userId}`, serializedData);
}

function loadContextFromStorage(userId) {
  const data = sessionStorage.getItem(`spaceContextData-${userId}`);
  return data ? JSON.parse(data) : null;
}
