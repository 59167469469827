import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";

const index = ({ data, handleClose, show, autoHide }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    let timer;
    setVisible(show);
    if (autoHide) {
      timer = setTimeout(() => {
        setVisible(false);
      }, autoHide);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [show]);

  return (
    <>
      {visible ? (
        <div className="absolute right-5 top-[60px] z-50 flex min-w-64 flex-row bg-white capitalize shadow-md dark:bg-gray-800">
          <div className="flex items-center justify-center p-3">
            <img
              className="rounded-md"
              src={data?.body?.data?.icon}
              style={{ width: 60, height: 60, resize: "contain" }}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-1 flex-row items-center justify-between font-poppins text-base font-bold">
              <div>{data?.body?.notification?.title}</div>
              <button
                onClick={handleClose}
                className="h-11 w-11 cursor-pointer rounded-full border-none text-gray-600 shadow-sm outline-none hover:text-red-500 hover:shadow-lg"
              >
                <CloseIcon className="text-inherit" />
              </button>
            </div>
            <div className="flex flex-1 flex-row p-3 pl-0 font-poppins text-sm">
              {data?.body?.notification?.body}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default index;
