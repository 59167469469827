import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface NotificationPayload {
  message: string;
}

interface NotificationState {
  notification: NotificationPayload[];
  notificationPath: number[];
  notificationCount: number;
}

const initialState: NotificationState = {
  notification: [],
  notificationPath: [],
  notificationCount: 0,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationPayload>) => {
      state.notification = [action.payload, ...state.notification];
    },
    updateNotificationList: (
      state,
      action: PayloadAction<NotificationPayload[]>
    ) => {
      state.notification = action.payload;
    },
    setNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload;
    },
    updateNotificationCount: (state, action: PayloadAction<number>) => {
      state.notificationCount = state.notificationCount + action.payload;
    },
    setNotificationPath: (state, action: PayloadAction<number[]>) => {
      state.notificationPath = _.union(state.notificationPath, action.payload);
    },
    updateNotificationPath: (state, action: PayloadAction<number>) => {
      state.notificationCount = action.payload;
    },
    pushNotificationPath: (state, action: PayloadAction<number>) => {
      state.notificationPath = [...state.notificationPath, action.payload];
    },
    removeNotificationPath: (state, action: PayloadAction<number>) => {
      state.notificationPath = state.notificationPath.filter((item) => {
        return item !== action.payload;
      });
    },
  },
});

export const {
  addNotification,
  updateNotificationList,
  setNotificationCount,
  updateNotificationCount,
  setNotificationPath,
  updateNotificationPath,
  pushNotificationPath,
  removeNotificationPath,
} = notificationSlice.actions;

export default notificationSlice.reducer;
