import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./userSlice";
import emailNotificationReducer from "./emailNotificationSlice";
import settingsReducer from "./settingsSlice";
import candidateReducer from "./candidateSlice";
import jobReducer from "./jobSlice";
import jobApplicationReducer from "./jobApplicationSlice";
import chatReducer from "./chatSlice";
import configReducer from "./configSlice";
import interviewOptionsReducer from "./interviewOptionsSlice";
import inboxReducer from "./inboxSlice";
import notificationReducer from "./notificationSlice";
import CandidateNotificationReducer from "./CandidateNotificationSlice";
import pipelineFilterReducer from "./pipelineFilterSlice";
import scheduleReducer from "./scheduleSlice";
import screeningReducer from "./screeningSlice";
import setupaiReducer from "./setupaiSlice";
import statusChangeReducer from "./statusChangeSlice";
import sceneSetupReducer from "./sceneSetupSlice";
import avatarCreatorReducer from "./avatarCreatorSlice";

import assistantReducer from "./assistantSlice";

const userPersistConfig = {
  key: "user",
  storage: storage,
  whitelist: ["user"],
};
const candidatePersistConfig = {
  key: "candidate",
  storage: storage,
  whitelist: ["candidate"],
};
const emailNotificationPersistConfig = {
  key: "emailNotification",
  storage: storage,
  whitelist: ["emailNotification"],
};

const settingsPersistConfig = {
  key: "settings",
  storage: storage,
  whitelist: ["settings"],
};
const chatPersistConfig = {
  key: "userChat",
  storage: storage,
  whitelist: ["userChat"],
};

const configPersistConfig = {
  key: "config",
  storage: storage,
  whitelist: ["config"],
};
const inboxPersistConfig = {
  key: "config",
  storage: storage,
  whitelist: ["config"],
};
const interviewOptionsConfig = {
  key: "interview",
  storage: storage,
  whitelist: ["interview"],
};

const jobApplicationPersistConfig = {
  key: "job",
  storage: storage,
  whitelist: ["job"],
};
const jobPersistConfig = {
  key: "job",
  storage: storage,
  whitelist: ["job"],
};

const notificationPersistConfig = {
  key: "notification",
  storage: storage,
  whitelist: ["notification"],
};
const pipelineFilterPersistConfig = {
  key: "pipeline",
  storage: storage,
  whitelist: ["pipeline"],
};
const schedulePersistConfig = {
  key: "schedule",
  storage: storage,
  whitelist: ["schedule"],
};

const screeningPersistConfig = {
  key: "screening",
  storage: storage,
  whitelist: ["screening"],
};

const setupaiPersistConfig = {
  key: "groupData",
  storage: storage,
  whitelist: ["groupData"],
};
const statusChangePersistConfig = {
  key: "status",
  storage: storage,
  whitelist: ["status"],
};
const sceneSetupPersistConfig = {
  key: "scene",
  storage: storage,
  whitelist: ["scene"],
};
const assistantPersistConfig = {
  key: "assistant",
  storage: storage,
  whitelist: ["assistant"],
};

const avatarCreatorPersistConfig = {
  key: "avatarCreator",
  storage: storage,
  whitelist: ["avatarCreator"],
  blacklist: ["avatar.speechText"],
};

export default {
  userReducer: persistReducer(userPersistConfig, userReducer),
  jobReducer: persistReducer(jobPersistConfig, jobReducer),
  candidateReducer: persistReducer(candidatePersistConfig, candidateReducer),
  chatReducer: persistReducer(chatPersistConfig, chatReducer),
  configReducer: persistReducer(configPersistConfig, configReducer),
  screeningReducer: persistReducer(screeningPersistConfig, screeningReducer),
  emailNotificationReducer: persistReducer(
    emailNotificationPersistConfig,
    emailNotificationReducer,
  ),
  inboxReducer: persistReducer(inboxPersistConfig, inboxReducer),
  interviewOptionsReducer: persistReducer(
    interviewOptionsConfig,
    interviewOptionsReducer,
  ),
  jobApplicationReducer: persistReducer(
    jobApplicationPersistConfig,
    jobApplicationReducer,
  ),
  pipelineFilterReducer: persistReducer(
    pipelineFilterPersistConfig,
    pipelineFilterReducer,
  ),
  notificationReducer: persistReducer(
    notificationPersistConfig,
    notificationReducer,
  ),
  candidateNotificationReducer: persistReducer(
    notificationPersistConfig,
    CandidateNotificationReducer,
  ),

  settingsReducer: persistReducer(settingsPersistConfig, settingsReducer),
  setupaiReducer: persistReducer(setupaiPersistConfig, setupaiReducer),
  scheduleReducer: persistReducer(schedulePersistConfig, scheduleReducer),
  statusChangeReducer: persistReducer(
    statusChangePersistConfig,
    statusChangeReducer,
  ),
  sceneSetupReducer: persistReducer(sceneSetupPersistConfig, sceneSetupReducer),
  avatarCreatorReducer: persistReducer(
    avatarCreatorPersistConfig,
    avatarCreatorReducer,
  ),
  assistantReducer: persistReducer(assistantPersistConfig, assistantReducer),
};
