import { useState, useEffect, useRef } from "react";
import "assets/dashboard/css/login.css";
import {
  IconButton,
  Divider,
  Typography,
  InputBase,
  InputLabel,
  FormControl,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha, styled } from "@mui/material/styles";

import * as Yup from "yup";

import { useHistory, Link, useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "redux/hooks";

import {
  addUser,
  userPermission,
  departmentPermission,
  sceneSetupPermission,
  allowRefferedCandidates,
  aiAssistantPermission,
  departmentCreatePermission,
  loggedUser,
  addRequestScoreActionData,
  updateSessionStatus,
  addBot,
  flowStep,
} from "redux/dashboard/reducers/userSlice";

import { updateInboxConfig } from "redux/dashboard/reducers/inboxSlice";

import { roleData, getUserRole } from "api/dashboard/permissions";
import { getCompanyDetails } from "api/dashboard/company";
import { updateUserToken } from "api/dashboard/auth";
import { findCandidateBot } from "api/dashboard/bot";

import { userLogin, verifyToken } from "api/auth";
import SnackBarComponent from "components/Snackbar";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

import "./loginForm.css";
import GithubAuth from "components/SocialAuth/GithubAuth";
import GoogleAuth from "components/SocialAuth/GoogleAuth";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  GOOGLE_CAPTCHA_KEY,
  SERVER_URL,
  isTesting,
} from "constants/production";

import ReCAPTCHA from "react-google-recaptcha";
import broadcastChannel from "utils/BroadcastChannel";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),

  password: Yup.string()
    .required("Password is required.")
    .min(4, "Password must be at least 4 characters."),
});

const formControlStyle = {
  display: "flex",
  flex: 1,
  "& label.MuiFormLabel-root": {
    color: "#242424 !important",
  },
};

const label = { inputProps: { "aria-label": "Checkbox" } };

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
    color: "#242424",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: "#fafafa",
    border: "1px solid",
    borderColor: "rgba(0, 123, 255, 0.2)",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "'Poppins'",
      "'Inter'",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha("#007bff", 0.25)} 0 0 0 0.2rem`,
      borderColor: "#007bff",
    },
  },
  "&.MuiInputBase-root.error": {
    "& .MuiInputBase-input": {
      boxShadow: `${alpha("#ff1744", 0.25)} 0 0 0 0.2rem !important`,
      borderColor: "#ff1744 !important",
    },
  },
  "& .MuiInputAdornment-root": {
    marginRight: "-48px",
    position: "relative",
    right: "55px",
  },
  "&.email-input": {
    "& .MuiInputAdornment-root": {
      display: "flex",
      position: "relative",
      marginRight: "-20px",
      right: "35px",
      zIndex: "1000",
      width: 10,
      transition: "all 0.2s ease-in",
    },
  },
  "&.password-input": {
    "& .MuiInputAdornment-root": {
      display: "flex",
      position: "relative",
      marginRight: "-48px",
      right: "55px",
      zIndex: "1000",
      transition: "all 0.2s ease-in",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  paper: {
    border: 1,
    borderRadius: 18,
    backgroundColor: "rgba(255, 255, 255, 0.65)",
    backdropFilter: "blur(4px)",
    margin: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow:
      "0 0.9px 1.3px rgba(0, 0, 0, 0.021), 0 2.4px 3.6px rgba(0, 0, 0, 0.03), 0 5.7px 8.7px rgba(0, 0, 0, 0.039),0 19px 29px rgba(0, 0, 0, 0.06)",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
    "& .MuiTextField-root": {
      background: "none",
    },
    "& label.Mui-focused": {
      color: "#007bff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#007bff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#007bff",
      },
      "&:hover fieldset": {
        borderColor: "#007bff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#007bff",
      },
    },
  },
  submit: {
    width: "60%",
    margin: theme.spacing(4, 0, 4),
  },
  icon: {
    color: "#808080",
  },
  logo: {
    maxWidth: 160,
  },
  error: {
    fontSize: 14,
    color: "#e74c3c",
    padding: 0,
    margin: 0,
    marginTop: 5,
    marginLeft: "30px",
  },
}));

const passwordIconButton = {
  color: "#bdbdbd",
  background: "rgba(0,0,0, 0.0)",
  "&:hover": {
    background: "rgba(0,0,0, 0.0)",
    color: "#007bff",
  },
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

type UserTypeData = {
  // Define the properties and their types here
  name: string;
  age: number;
};

type Props = {
  signUpUrl: string;
  forgotPasswordUrl: string;
  onSubmit: any;
  onTokenVerified: any;
  style?: Object;
};

const LoginForm = ({
  signUpUrl,
  forgotPasswordUrl,
  onSubmit,
  onTokenVerified,
  style = {},
}: Props) => {
  const dispatch = useAppDispatch();
  let history = useHistory();
  const classes = useStyles();

  const recaptchaRef = useRef();

  const userToken = useAppSelector((state) => state?.userReducer?.user?.token);

  const authStatus = useAppSelector(
    (state) => state?.userReducer?.user?.authStatus,
  );
  const actionUrl = useAppSelector(
    (state) => state?.userReducer?.user?.scoreActionData?.actionUrl,
  );
  const actionStatus = useAppSelector(
    (state) => state?.userReducer?.user?.scoreActionData?.actionStatus,
  );

  const url = window.location.href;

  const initialValues = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errors, setErrors] = useState();
  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarStatus, setSnackbarStatus] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  const [response, setResponse] = useState<any>(null);

  const [userTypeData, setUserTypeData] = useState<UserTypeData | undefined>(
    undefined,
  );

  const [showPassword, setShowPassword] = useState(false);

  const [reCaptchaResponse, setReCaptchaResponse] = useState("");
  const [formSubmitMessage, setFormSubmitMessage] = useState("");

  // validate yup schema
  const validateItem = (key: string, value: string) =>
    new Promise((resolve, reject) => {
      validationSchema
        .validateAt(key, { [key]: value })
        .then(() => resolve(""))
        .catch((error) => resolve(error.message));
    });

  const handleReCaptchaChange = (token) => {
    setReCaptchaResponse(token);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const updateSnackbar = (
    message: string,
    open: boolean,
    status: number,
    autoHide = true,
  ) => {
    setSnackbarOpen(open);
    setSnackbarStatus(status);
    setSnackbarMessage(message);

    if (autoHide) {
      setTimeout(() => {
        setSnackbarOpen(false);
        setSnackbarStatus(0);
        setSnackbarMessage("");
      }, 5000);
    }
  };

  useEffect(() => {
    dispatch(updateInboxConfig({ selectedDepartment: "", selectedJob: "" }));
    return () => {};
  }, []);

  const validateFormData = async (data) => {
    try {
      const validValues = await validationSchema.validate(data, {
        abortEarly: false,
      });
      // Validation succeeded, use the validValues as needed
      return true;
    } catch (errors) {
      // Validation failed, handle errors
      if (errors.inner && errors.inner.length > 0) {
        const errorMessages = {};
        errors.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        setErrors((prev) => ({ ...prev, ...errorMessages }));
      }
      return false;
    }
  };

  //test
  const submit = async (e) => {
    e.preventDefault();

    const formData = {
      email: formValues?.email,
      password: formValues?.password,
    };

    const validationResult = await validateFormData(formData);

    if (!validationResult) return false;

    setIsSubmitting(true);

    function login() {
      userLogin(formData)
        .then((data) => {
          const userId = data?.response?._id;
          const id = data?.id;
          const matchToken = data?.token;
          const userType = data?.response?.userType;

          if (matchToken) {
            const data = {
              token: matchToken,
            };

            updateUserToken(id, data).catch((error) => {
              console.log("Failed to updateToken, Error : ", error);
            });
          }
          localStorage.setItem("userId", userId);
          localStorage.setItem("user_matchToken", matchToken);
          localStorage.setItem("user_type", userType);
          setFormValues(initialValues);

          if (userType === "user" || userType === "Admin") {
            const PermissionData = {
              permissions: data?.response?.role?.permissions,
            };
            const DeptPermissionData = {
              permissions: data?.response?.role?.departmentPermission,
            };

            const sceneSetupPermissionData = {
              permissions: data?.response?.role?.sceneSetupPermission,
            };

            const aiAssistantPermissionData = {
              permissions: data?.response?.role?.aiAssistantPermission,
            };

            const allowRefferedCandidatesData = {
              permissions: data?.response?.role?.onlyAllowRefferedCandidates,
            };

            const departmentCreatePermissionData = {
              permissions: data?.response?.role?.departmentCreate,
            };

            dispatch(
              addUser({
                id: userId,
                token: matchToken,
                emailUsername: formValues?.email,
                authStatus: true,
                company: data?.response?.company,
                userName: response?.name,
                status: response?.status,
                userId: userId,
                userKey: data?.id,
                sessionStatus: true,
                isFirstLogin: data?.response?.isFirstLogin,
                isSpaceSetupPageVisited: data?.response?.isSpaceSetupVisited,
                bot: data?.response?.bot,
              }),
            );

            dispatch(userPermission(PermissionData));
            dispatch(departmentPermission(DeptPermissionData));
            dispatch(sceneSetupPermission(sceneSetupPermissionData));
            dispatch(aiAssistantPermission(aiAssistantPermissionData));
            dispatch(allowRefferedCandidates(allowRefferedCandidatesData));
            dispatch(
              departmentCreatePermission(departmentCreatePermissionData),
            );

            const loggedInUserData = {
              user: "user",
              role: data?.response?.role?.role,
              email: data?.response?.email,
              userId: data?.response?._id,
              userKey: data?.id,
              username: data?.response?.name,
              profile_photo: data?.response?.profile_image,
            };
            broadcastChannel.postMessage(loggedInUserData);
            dispatch(loggedUser(loggedInUserData));

            if (actionStatus) {
              dispatch(
                addRequestScoreActionData({
                  actionStatus: false,
                  actionUrl: actionUrl,
                }),
              );
              history.push(`${actionUrl}`);
            } else {
              dispatch(
                addRequestScoreActionData({
                  actionStatus: false,
                  actionUrl: "",
                }),
              );
              if (data?.response?.twoFactorAuth?.status) {
                updateSnackbar("OTP has been send to your mail", true, 1, true);
              } else {
                updateSnackbar("Login successful, redirect...", true, 1, true);
              }

              return onSubmit({
                userId: data?.response?._id,
                userType,
                twoFactor: data?.response?.twoFactorAuth,
              });
            }
          }
          // candidate user
          if (userType === "candidate") {
            const loggedInUserData = {
              user: "candidate",
              role: "candidate",
              email: data?.response?.email,
              userId: data?.response?._id,
              userKey: data?.id,
              username: data?.response?.name,
              profile_photo:
                data?.response?.profile_image || data?.response?.profile_photo,
            };

            const userData = {
              id: data?.response?._id,
              token: data?.token,
              emailUsername: data?.response?.email,
              authStatus: true,
              userName: data?.response?.name,
              status: data?.response?.status,
              email: data?.response?.email,
              userId: data?.response?._id,
              userKey: data?.id,
              username: data?.response?.name,
              profile_photo:
                data?.response?.profile_image || data?.response?.profile_photo,
              loggedUser: loggedInUserData,
              sessionStatus: true,
              isFirstLogin: data?.isFirstLogin,
              bot: data?.response?.bot,
              flowStep: data?.flow,
            };
            dispatch(addUser(userData));
            broadcastChannel.postMessage(loggedInUserData);

            if (
              data?.flow?.status ||
              url.includes("company_space") ||
              url.includes("candidate_space")
            ) {
              if (actionStatus) {
                dispatch(
                  addRequestScoreActionData({
                    actionStatus: false,
                    actionUrl: actionUrl,
                  }),
                );

                history.push(`${actionUrl}`);
              } else {
                dispatch(
                  addRequestScoreActionData({
                    actionStatus: false,
                    actionUrl: "",
                  }),
                );
                if (data?.response?.twoFactorAuth?.status) {
                  updateSnackbar(
                    "OTP has been send to your mail, redirecting...",
                    true,
                    1,
                    true,
                  );
                  // history.push(`/login/verify/user/${data?.response?._id}`);
                } else {
                  updateSnackbar(
                    "Login successful, please wait...",
                    true,
                    1,
                    true,
                  );
                }
                return onSubmit({
                  userId: data?.response?._id,
                  userType,
                  twoFactor: data?.response?.twoFactorAuth,
                  flowStatus: data?.flow,
                  userKey: data?.id,
                  userName: data?.response?.name,
                });
              }
            } else {
              history.push(
                `/start/flow/candidate/${data?.flow?.stage}/${data?.flow?.userId}`,
              );
            }
          }
        })
        .catch((err) => {
          setIsSubmitting(false);
          setErrorMessage("Login failed, Please check your email and password");
          console.error("Login failed : ", err);
        });
    }

    if (isTesting) {
      return login();
    }

    // Perform your form submission logic here, including sending the reCAPTCHA token to your backend for validation
    try {
      // if (!isTesting && recaptchaRef?.current) {
      //   const captchaToken = await recaptchaRef.current.executeAsync();

      //   const response = await fetch(`${SERVER_URL}/api/verify-recaptcha`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ recaptchaResponse: captchaToken }),
      //   });

      //   const { success } = await response.json();

      //   if (success) {
      //     // reCAPTCHA verification succeeded, continue with form submission
      //     if (formValues && Object.keys(formValues).length) {
      //       login();
      //     }
      //     setFormSubmitMessage("");
      //   } else {
      //     // reCAPTCHA verification failed
      //     setFormSubmitMessage("reCAPTCHA verification failed");
      //   }
      // }
      return login();
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form:", error);
      setFormSubmitMessage("An error occurred while submitting the form");
    }
  };

  const handleSignUp = () => {
    dispatch(addUser());
    history.push(signUpUrl);
  };

  //input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorMessage("");

    validateItem(name, value).then((error) =>
      setErrors((prev) => ({ ...prev, [name]: error })),
    );

    setFormValues({ ...formValues, [name]: value });
  };

  const handleGithubLogin = (response) => {};

  const handleGithubLoginError = (error) => {};

  const handleGoogleLogin = (response) => {};

  const handleGoogleLoginError = (error) => {};

  useEffect(() => {
    if (userToken && userToken.length && !isTesting) {
      verifyToken(userToken)
        .then((data) => {
          if (data?.user) {
            const { userType, twoFactorAuth, _id } = data.user;
            if (!twoFactorAuth?.status) {
              onTokenVerified({
                userId: _id,
                userType,
                twoFactor: twoFactorAuth,
              });
            }
          }
        })
        .catch((error) => {
          console.error("Failed to verify token, Error: ", error);
        });
    }

    return () => {};
  }, [userToken]);

  return (
    <>
      <SnackBarComponent
        open={snackbarOpen}
        status={snackbarStatus}
        message={snackbarMessage}
      />
      <PerfectScrollbar className="flex flex-1" id="login-scroll-container">
        <div
          className="flex flex-1 flex-col items-center justify-center"
          data-testid="login-account-form-container"
          style={style}
        >
          <div
            className="flex flex-col items-center justify-center rounded-lg bg-white px-5 py-8 shadow-xl dark:bg-gray-700"
            data-testid="login-form"
          >
            <h1 className="form-title text-black dark:text-white">
              Welcome back!
            </h1>
            <div className="form-wrapper">
              <div className="form-container">
                <div
                  className="social-auth-wrapper"
                  style={{ display: "none" }}
                >
                  <div className="social-auth-title">Sign in with</div>
                  <div className="form-row">
                    <GithubAuth
                      mode="light"
                      onSignIn={handleGithubLogin}
                      onError={handleGithubLoginError}
                    >
                      Github
                    </GithubAuth>

                    <GoogleAuth
                      mode="light"
                      onSignIn={handleGoogleLogin}
                      onError={handleGoogleLoginError}
                    >
                      Google
                    </GoogleAuth>
                  </div>
                </div>
                <div className="divider-wrapper" style={{ display: "none" }}>
                  <Divider orientation="horizontal" sx={{ width: "150px" }} />
                  <p>or</p>
                  <Divider orientation="horizontal" sx={{ width: "150px" }} />
                </div>

                <div className="form-row" style={{ alignItems: "center" }}>
                  <div className="form-element-wrapper">
                    <input
                      className="block rounded border border-gray-300 bg-white px-4 py-2 text-sm text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      data-testid="name-input"
                      id="name-input"
                      value={formValues?.email}
                      onChange={(e) => handleChange(e)}
                      type="email"
                      name="email"
                      placeholder="Email"
                    />

                    <div className="error-text">{errors?.email}</div>
                  </div>
                </div>

                <div className="form-row" style={{ alignItems: "center" }}>
                  <div className="form-element-wrapper">
                    <div className="relative flex flex-row items-end">
                      <input
                        data-testid="password-input"
                        id="password-input"
                        className={`block w-full rounded border border-gray-300 bg-white px-4 py-2 pr-10 text-sm text-gray-700 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-300 dark:focus:border-blue-500 dark:focus:ring-blue-500`}
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formValues?.password}
                        onChange={(e: any) => handleChange(e)}
                        placeholder={"Password"}
                      />
                      <button
                        type="button"
                        onClick={handleTogglePasswordVisibility}
                        className="absolute inset-y-0 right-0 flex cursor-pointer items-center border-none bg-transparent px-2 text-gray-600 outline-none dark:text-gray-300"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </button>
                    </div>

                    <div className="error-text">{errors?.password}</div>
                    {forgotPasswordUrl && (
                      <div className="mt-3 flex flex-row items-end justify-end">
                        <Link
                          to={forgotPasswordUrl}
                          className="rounded-lg px-3 py-1 font-poppins text-blue-400 hover:bg-white/[0.05] hover:text-blue-500 hover:shadow-md dark:text-white"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    )}
                  </div>
                </div>

                <span className={classes.error}>
                  {errorMessage ? errorMessage : null}
                </span>

                <div className="form-row" style={{ alignItems: "center" }}>
                  {!isTesting && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={GOOGLE_CAPTCHA_KEY}
                      onChange={handleReCaptchaChange}
                    />
                  )}
                </div>

                <div data-testid="submit" className="form-row">
                  <button className="login-account-button" onClick={submit}>
                    {isSubmitting && !errorMessage && !errors?.password ? (
                      <>
                        <CircularProgress
                          size={14}
                          style={{ color: "#fff", marginRight: 5 }}
                        />
                        Please wait...
                      </>
                    ) : (
                      "SIGN IN"
                    )}
                  </button>
                </div>
                {signUpUrl && (
                  <div className="sign-up-container">
                    <span className="text-black dark:text-white">
                      Don't have an account? &nbsp;
                    </span>
                    <span
                      onClick={handleSignUp}
                      // to={signUpUrl}
                      className="cursor-pointer rounded-lg py-1 font-poppins text-blue-500 transition-all delay-75 ease-linear hover:bg-white/[0.05] hover:px-3 hover:shadow-md"
                    >
                      SIGN UP
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default LoginForm;
