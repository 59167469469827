import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CandidateState {
  candidate: {
    name: any;
    attemptScore: number;
  };
}

const initialState: CandidateState = {
  candidate: {
    attemptScore: 0,
  },
};

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    updateCurrentCandidate: (
      state,
      action: PayloadAction<CandidateState["candidate"]>
    ) => {
      state.candidate = action.payload;
    },
    updateCandidateAttemptScore: (state, action: PayloadAction<any>) => {
     state.candidate.attemptScore = {
        ...state.candidate.attemptScore,
        ...action.payload
     };
    },
  },
});

export const { updateCurrentCandidate, updateCandidateAttemptScore } =
  candidateSlice.actions;

export default candidateSlice.reducer;
