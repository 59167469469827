import React, { Suspense, useMemo } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

import CircularProgressLoader from "components/dashboard/Progress/CircularProgressLoader";
import NotFoundScreen from "views/error/NotFoundScreen";
import routes from "./routes";
import companySpaceRoutes from "./companySpaceRoutes";
import candidateSpaceRoutes from "./candidateSpaceRoutes";
import CompanySpaceLayout from "layouts/Space/CompanySpaceLayout";
import CandidateSpaceLayout from "layouts/Space/BasicLayout";
const StartScreen = React.lazy(() => import("views/space/Start"));

const loadingContainerStyle = {
  width: "100vw",
  height: "100vh",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden !important",
};

const Router = () => {
  const isAuthenticated = useAppSelector(
    (state) => state?.userReducer?.user?.sessionStatus,
  );

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((prop, key) => (
          <Route
            exact={prop?.exact}
            path={prop.path}
            key={key}
            render={(props) => (
              <Suspense
                fallback={
                  <>
                    <div style={loadingContainerStyle}>
                      <CircularProgressLoader />
                    </div>
                  </>
                }
              >
                {prop?.protected ? (
                  !!isAuthenticated ? (
                    <MemoizedRouteComponentWithLayout
                      layout={prop?.layout}
                      component={prop?.component}
                      {...props}
                    />
                  ) : (
                    <Redirect to={prop?.redirectPath || "/login"} />
                  )
                ) : (
                  <MemoizedRouteComponentWithLayout
                    layout={prop?.layout}
                    component={prop?.component}
                    {...props}
                  />
                )}
              </Suspense>
            )}
          />
        ))}
        {/* company space start */}
        <Route
          exact={true}
          path="/company_space/start/:companyId"
          render={(props) => (
            <Suspense
              fallback={
                <div style={loadingContainerStyle}>
                  <CircularProgressLoader />
                </div>
              }
            >
              <StartScreen {...props} />
            </Suspense>
          )}
        />

        <Route path="/company_space/:path?">
          {/* company space routes */}
          <CompanySpaceLayout>
            <Switch>
              {companySpaceRoutes.map((prop, key) => (
                <Route
                  exact={prop?.exact}
                  path={prop.path}
                  key={key}
                  render={(props) => (
                    <Suspense
                      fallback={
                        <div style={loadingContainerStyle}>
                          <CircularProgressLoader />
                        </div>
                      }
                    >
                      <prop.component {...props} />
                    </Suspense>
                  )}
                />
              ))}
            </Switch>
          </CompanySpaceLayout>
        </Route>

        <Route path="/candidate_space/:candidateId?">
          {/* candidate space routes */}
          <CandidateSpaceLayout>
            <Switch>
              {candidateSpaceRoutes.map((prop, key) => (
                <Route
                  exact={prop?.exact}
                  path={prop.path}
                  key={key}
                  render={(props) => (
                    <Suspense
                      fallback={
                        <div style={loadingContainerStyle}>
                          <CircularProgressLoader />
                        </div>
                      }
                    >
                      <prop.component {...props} />
                    </Suspense>
                  )}
                />
              ))}
            </Switch>
          </CandidateSpaceLayout>
        </Route>

        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  );
};

const MemoizedRouteComponentWithLayout = ({
  layout: Layout,
  component: Component,
  ...rest
}) => {
  const MemoizedLayout = useMemo(() => Layout, [Layout]);

  if (MemoizedLayout) {
    return (
      <MemoizedLayout>
        <Component {...rest} />
      </MemoizedLayout>
    );
  } else {
    return <Component {...rest} />;
  }
};

export default Router;
