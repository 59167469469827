import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

interface NotificationPayload {
  message: string;
}

interface NotificationState {
  notification: NotificationPayload[];
  notificationPath: number[];
  notificationCount: number;
}

const initialState: NotificationState = {
  notification: [],
  notificationPath: [],
  notificationCount: 0,
};

export const candidateNotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addCandidateNotification: (state, action: PayloadAction<NotificationPayload>) => {
      state.notification = [action.payload, ...state.notification];
    },
    updateCandidateNotificationList: (
      state,
      action: PayloadAction<NotificationPayload[]>
    ) => {
      state.notification = action.payload;
    },
  },
});

export const {
  addCandidateNotification,
  updateCandidateNotificationList,
} = candidateNotificationSlice.actions;

export default candidateNotificationSlice.reducer;
