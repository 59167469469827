export const deleteIconButton = {
  color: "#bdbdbd !important",
  borderRadius: 50,
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    color: "#fff !important",
    backgroundColor: "#ff1744",
    boxShadow: "0px 0px 0px 2px rgba(255, 23, 68, 0.1)",
  },
};

export const deleteButton = {
  color: "#ff1744",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#ff1744",
    color: "#fff",
    boxShadow: "rgba(213,0,0,0.16) 0px 3px 6px, rgba(213,0,0,0.23) 0px 3px 6px",
  },
};

export const primaryButton = {
  color: "#2A8BF2",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};
export const primaryFilledButton = {
  backgroundColor: "#2A8BF2 !important",
  color: "#fff",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

export const secondaryFilledButton = {
  backgroundColor: "#fff",
  color: "#2A8BF2",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const primaryIconButton = {
  color: "#212121",
  marginLeft: 1,
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

export const cancelButton = {
  color: "#bdbdbd",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#757575",
    color: "#fff",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const whiteFilledButton = {
  color: "#242424",
  backgroundColor: "#fff",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#fff",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const hoverStyleButton = {
  margin: 1,
  paddingLeft: 1,
  width: 25,
  backgroundColor: "#FFF",
  borderRadius: 55,
  height: 28,
  color: "#757575",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  boxShadow:
    "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

export const cancelFilledButton = {
  backgroundColor: "#bdbdbd",
  color: "#757575",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",

  "&:hover": {
    backgroundColor: "#757575",
    color: "#fff",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const disabledFilledButton = {
  backgroundColor: "#bdbdbd",
  color: "#757575",
  fontFamily: "Inter, poppins sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "##bdbdbd !important",
    color: "#757575 !important",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const disabledButton = {
  color: "#bdbdbd",
  fontFamily: "'Poppins', 'Inter', sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#bdbdbd",
    color: "#bdbdbd",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const disabledIconButton = {
  margin: "0px 5px",
  color: "#bdbdbd",
  fontFamily: "'Poppins', 'Inter', sans-serif",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    color: "#bdbdbd",
    boxShadow:
      "rgba(117, 117, 117, 0.16) 0px 3px 6px, rgba(117, 117, 117, 0.23) 0px 3px 6px",
  },
};

export const inputFieldStyle = {
  "& .MuiInput-root": {
    "&:hover": {
      "&:before": {
        borderBottom: "2px solid rgba(42, 139, 242, 0.95) !important",
      },
    },
    "&:before": {
      borderColor: "#bdbdbd",
      // borderBottom: "none !important",
    },
    "&:after": {
      borderColor: "#2A8BF2",
      // borderBottom: "none !important",
      "&:hover": {
        borderColor: "#2A8BF2",
      },
    },
  },
};

export const textFieldStyle = {
  "& .MuiTextField-root": {
    "& .MuiInputBase-root": {
      "&:hover": {
        borderBottom: "2px solid rgba(42, 139, 242, 0.95) !important",
      },
    },
  },
};

export const tabStyle = {
  textTransform: "capitalize !important",
  fontFamily: "Inter, poppins",
  transition: "all 0.2s ease-in-out",
  borderColor: "#2A8BF2 !important",
};
