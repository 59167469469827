import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface StatusState {
  status: {
    statusData: unknown[]; // Use `unknown` instead of `any` if possible
    authStatus: boolean;
  };
}

const initialState: StatusState = {
  status: {
    statusData: [],
    authStatus: false,
  },
};

export const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    addStatusChange: (state, action: PayloadAction<unknown>) => {
      state.status.statusData = [...state.status.statusData, action.payload];
      state.status.authStatus = true;
    },
    clearStatusChange: (state) => {
      state.status.statusData = [];
      state.status.authStatus = false;
    },
  },
});

export const { addStatusChange, clearStatusChange } = statusSlice.actions;

export default statusSlice.reducer;
