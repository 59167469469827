import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface JobRecommendationsState {
  cs_recommendations: Record<string, any>;
}

const initialState: JobRecommendationsState = {
  cs_recommendations: {},
};

export const jobRecommendationsSlice = createSlice({
  name: "cs_recommendations",
  initialState,
  reducers: {
    updateJobRecommendation: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.cs_recommendations = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateJobRecommendation } = jobRecommendationsSlice.actions;

export default jobRecommendationsSlice.reducer;
