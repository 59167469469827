import axios from "utils/axios";

type ResponseType = {
  data: {
    transcription: string | undefined;
  };
};

export const getSpeechToText = (data: any) =>
  new Promise((resolve, reject) => {
    axios
      .post("/speech/to/text", data)
      .then(({ data }: ResponseType) => resolve(data))
      .catch((err: any) => reject(err));
  });
