import React from "react";
import { Button } from "@mui/material";
import googleIcon from "./assets/google-icon.svg";
import { signInWithGoogle } from "auth";
import { useAppSelector } from "redux/hooks";

interface GoogleAuthProps {
  mode: string;
  onSignIn: (userData: {
    name?: string;
    accessToken?: string;
    email?: string;
    phone?: string;
    profilePhoto?: string;
    uid?: string;
  }) => void;
  onError: (error: any) => void;
  children: React.ReactNode;
}

const darkStyle =
  "text-white font-poppins px-5 py-10 font-bold bg-black hover:shadow-md transition duration-300 ease-in-out";

const GoogleAuth: React.FC<GoogleAuthProps> = ({
  mode = "light",
  onSignIn,
  onError,
  children,
}) => {
  const handleGoogleSignUp = () => {
    signInWithGoogle()
      .then((response) => {
        if (response?.user) {
          const { user } = response;
          const userData = {
            name: user?.displayName,
            accessToken: user?.accessToken,
            email: user?.email,
            phone: user?.phoneNumber,
            profilePhoto: user?.photoURL,
            uid: user?.uid,
          };
          onSignIn(userData);
        }
      })
      .catch((error) => {
        console.error("google signIn failed", error);
        onError(error);
      });
  };

  const isLoggedIn = useAppSelector((state) => state?.auth?.isLoggedIn);

  return (
    <button
      onClick={handleGoogleSignUp}
      className="mx-3 flex h-10  items-center justify-center rounded-md border-none bg-white px-3 font-poppins font-bold text-black shadow-sm outline-none transition duration-100 ease-in-out hover:shadow-md"
    >
      <img src={googleIcon} className="mx-1 h-6 w-6" />
      {children}
    </button>
  );
};

export default GoogleAuth;
