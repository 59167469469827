import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CandidateState {
  cs_candidate: any;
}

const initialState: CandidateState = {
  cs_candidate: {},
};

export const candidateSlice = createSlice({
  name: "cs_candidate",
  initialState,
  reducers: {
    addCandidate: (state, action: PayloadAction<any>) => {
      state.cs_candidate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addCandidate } = candidateSlice.actions;

export default candidateSlice.reducer;
