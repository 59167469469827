import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserState {
  cs_user: {
    authStatus: boolean;
  };
}

const initialState: UserState = {
  cs_user: {
    authStatus: false,
  },
};

export const userSlice = createSlice({
  name: "cs_user",
  initialState,
  reducers: {
    addUser: (state, action: PayloadAction<UserState>) => {
      state.cs_user = action.payload.cs_user;
    },
    updateAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.cs_user.authStatus = action.payload;
    },
    updateProfileImage: (state, action: PayloadAction<string>) => {
      state.cs_user.user.loggedUser.profile_photo = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addUser, updateAuthStatus, updateProfileImage } =
  userSlice.actions;

export default userSlice.reducer;
