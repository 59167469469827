import axios from "utils/axios";

export const updateUserData = (userId, profileImage) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/update/${userId}`, {
        profile_image: profileImage,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
export const updateUserStatus = (userDataModal, updateStatus) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/update/${userDataModal?._id}`, {
        email: userDataModal?.email,
        status: updateStatus?.value,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const DisableUser = (userDataModal) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/update/${userDataModal._id}`, {
        email: userDataModal.email,
        status: "disable",
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const EnableUser = (userDataModal) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/update/${userDataModal._id}`, {
        email: userDataModal.email,
        status: "Active",
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const DeleteUser = (userDataModal) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/users/delete/${userDataModal.company}`, {
        company: userDataModal.company,
        email: userDataModal.email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const GetUserList = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/userInvitation/list/${companyId}`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const InviteUser = (
  companyId,
  username,
  userEmail,
  inviteNewRole,
  user_type
) =>
  new Promise((resolve, reject) => {
    axios
      .post("/userInvitation/invite", {
        company: companyId,
        name: username,
        email: userEmail,
        role_id: inviteNewRole?._id,
        role_name: inviteNewRole?.role,
        user_type: user_type,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// role list get
export const GetRoleList = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/userRole/list/${companyId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// role list get
export const GetAdminRole = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/userRole/admin/get/${companyId}`)
      .then((response) => {
        resolve(response.data.response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const RoleAssign = (userDetails, userRoleModalData, companyId, user) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/update/role/${userDetails._id}`, {
        email: userDetails?.email,
        name: userDetails?.name,
        role_name: userRoleModalData?.role,
        role_id: userRoleModalData?._id,
        company: companyId,
        user: user,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const RoleUnAssign = (userDetails) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/userInvitation/unassign/${userDetails._id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const roleData = (roleId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/userRole/fulllist/${roleId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUserRole = (companyId, email) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/userInvitation/find/user/role/company/${companyId}`, {
        email,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// role list get
export const getUserData = (userId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/users/userdetails/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

  export const getUserCompanyData = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/users/userdetails/company/${companyId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const changePassword = (email, existingPassword, newPassword) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/change/password", {
        email,
        existingPassword,
        newPassword,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getAdminRoleData = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/userRole/find/role/company/${companyId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
