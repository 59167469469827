import io from "socket.io-client";

let socket;

const connectionOptions = {
  "force new connection": true,
  reconnection: true,
  reconnectionDelay: 2000, // starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
  reconnectionDelayMax: 60000, // 1 minute maximum delay between connections
  reconnectionAttempts: "Infinity", // to prevent dead clients, having the user to having to manually reconnect after a server restart.
  timeout: 10000, // before connect_error and connect_timeout are emitted.
  transports: ["websocket"], // forces the transport to be only websocket. Server needs to be setup as well
  extraHeaders: {
    access_token: "",
  },
};

export const initiateSocket = (url, token, room = null) => {
  const hostUrl = `${url}?access_token=${encodeURIComponent(token)}`;
  socket = io(hostUrl, connectionOptions);

  if (socket) {
    socket.emit("join", { access_token: token, room });
    return socket;
  }
};
export const initiateSupportChatSocket = (url, token, room = null) => {
  const hostUrl = `${url}?access_token=${encodeURIComponent(token)}`;
  socket = io(hostUrl, connectionOptions);

  if (socket) {
    socket.emit("join_support_chat", { access_token: token, room });
    return socket;
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

export const subscribeToEvent = (event, cb) => {
  if (!socket) {
    throw new Error("Socket connection missing");
  }
  socket.on(event, (msg) => {
    cb(msg);
  });
};

export const sendEvent = (event, message, token) => {
  if (socket) {
    socket.emit(event, message, token);
  }
};

export default socket;
