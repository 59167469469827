import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ChatWindowConfigState {
  cs_chatWindowConfig: {
    isClosed: boolean;
    isMinimized: boolean;
  };
}

const initialState: ChatWindowConfigState = {
  cs_chatWindowConfig: {
    isClosed: true,
    isMinimized: false,
  },
};

export const chatWindowConfigSlice = createSlice({
  name: "cs_chatWindowConfig",
  initialState,
  reducers: {
    updateChatWindowConfig: (
      state,
      action: PayloadAction<Record<string, unknown>>
    ) => {
      state.cs_chatWindowConfig = {
        ...state.cs_chatWindowConfig,
        ...action.payload,
      };
    },
  },
});

export const { updateChatWindowConfig } = chatWindowConfigSlice.actions;

export default chatWindowConfigSlice.reducer;
