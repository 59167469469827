import { SetStateAction } from 'react';

type RecorderState = {
  initRecording: boolean;
  mediaStream: MediaStream | null;
};

type SetRecorderState = SetStateAction<RecorderState>;

export async function startRecording(
  setRecorderState: SetRecorderState,
): Promise<void> {
  try {
    const navigator = window.navigator;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setRecorderState((prevState) => {
          return {
            ...prevState,
            initRecording: true,
            mediaStream: stream,
          };
        });
      })
      .catch((error) => {
        console.error("Recorder Error: ", error);
      });
  } catch (err) {
    console.log(err);
  }
}

export function saveRecording(recorder: MediaRecorder): void {
  if (recorder.state !== "inactive") recorder.stop();
}

