import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./userSlice";
import departmentReducer from "./departmentSlice";
import jobReducer from "./jobSlice";
import companyReducer from "./companySlice";
import jobDetailsReducer from "./jobDetailsSlice";
import screeningReducer from "./screeningSlice";
import interviewReducer from "./interviewSlice";
import candidateReducer from "./candidateSlice";
import sceneReducer from "./sceneSlice";
import avatarSpeechReducer from "./avatarSpeechSlice";
import jobRecommendationReducer from "./jobRecommendationSlice";
import csCandidateReducer from "./candidateSpace/candidateSpaceSlice";
import chatWindowConfigReducer from "./chatWindowConfigSlice";

const userPersistConfig = {
  key: "cs_user",
  storage: storage,
  whitelist: ["cs_user"],
};
const departmentPersistConfig = {
  key: "cs_department",
  storage: storage,
  whitelist: ["cs_department"],
};
const jobPersistConfig = {
  key: "cs_job",
  storage: storage,
  whitelist: ["cs_job"],
};

const companyPersistConfig = {
  key: "cs_company",
  storage: storage,
  whitelist: ["cs_company"],
};
const jobDetailsPersistConfig = {
  key: "cs_jobDetails",
  storage: storage,
  whitelist: ["cs_jobDetails"],
};
const jobRecommendationPersistConfig = {
  key: "cs_jobRecommendation",
  storage: storage,
  whitelist: ["cs_recommendations"],
};
const screeningPersistConfig = {
  key: "cs_screening",
  storage: storage,
  whitelist: ["cs_screening"],
};
const interviewPersistConfig = {
  key: "cs_interview",
  storage: storage,
  whitelist: ["cs_interview"],
};
const candidatePersistConfig = {
  key: "cs_candidate",
  storage: storage,
  whitelist: ["cs_candidate"],
};
const scenePersistConfig = {
  key: "cs_scene",
  storage: storage,
  whitelist: ["cs_scene"],
};
const avatarSpeechPersistConfig = {
  key: "cs_avatarSpeech",
  storage: storage,
  whitelist: ["cs_avatarSpeech"],
  blackList: ["speechText"],
};

const csCandidatePersistConfig = {
  key: "cs_candidateSpace",
  storage: storage,
  whitelist: ["cs_candidateSpace"],
};

const csChatWindowPersistConfig = {
  key: "cs_chatWindowConfig",
  storage: storage,
  whitelist: ["cs_chatWindowConfig"],
};

export default {
  cs_userReducer: persistReducer(userPersistConfig, userReducer),
  cs_departmentReducer: persistReducer(
    departmentPersistConfig,
    departmentReducer
  ),
  cs_jobReducer: persistReducer(jobPersistConfig, jobReducer),
  cs_companyReducer: persistReducer(companyPersistConfig, companyReducer),
  cs_jobDetailsReducer: persistReducer(
    jobDetailsPersistConfig,
    jobDetailsReducer
  ),
  cs_jobRecommendationReducer: persistReducer(
    jobRecommendationPersistConfig,
    jobRecommendationReducer
  ),
  cs_screeningReducer: persistReducer(screeningPersistConfig, screeningReducer),
  cs_interviewReducer: persistReducer(interviewPersistConfig, interviewReducer),
  cs_candidateReducer: persistReducer(candidatePersistConfig, candidateReducer),
  cs_sceneReducer: persistReducer(scenePersistConfig, sceneReducer),
  cs_avatarSpeechReducer: persistReducer(
    avatarSpeechPersistConfig,
    avatarSpeechReducer
  ),
  cs_candidateSpaceReducer: persistReducer(
    csCandidatePersistConfig,
    csCandidateReducer
  ),
  cs_chatWindowConfigReducer: persistReducer(
    csChatWindowPersistConfig,
    chatWindowConfigReducer
  ),
};
