import React from "react";
import { DateTime } from "luxon";

import Lottie from "lottie-react";
import emptyBox from "./empty-box.json";

import "./chat-component.css";

const formatMessageDate = (message) => {
  const messageDate = DateTime.fromISO(message.createdAt);
  return messageDate.toLocaleString(DateTime.TIME_SIMPLE);
};

const formatTimelineLabel = (messageDate) => {
  const today = DateTime.local().startOf("day");
  if (messageDate.hasSame(today, "day")) {
    return "Today";
  } else {
    return messageDate.toLocaleString(DateTime.DATE_MED);
  }
};

const ChatComponent = ({ messageList, sender, recipient }) => {
  if (!messageList || messageList.length === 0) {
    return (
      <div className="empty-list-section">
        <Lottie
          animationData={emptyBox}
          loop={true}
          className="no-message-image"
        />
        <div className="error-text">No messages found</div>
      </div>
    );
  }

  const formattedMessages = messageList.map((item, index) => {
    const previousMessage = messageList[index - 1];
    const nextMessage = messageList[index + 1];
    const messageType = item?.sender === sender ? "outgoing" : "incoming";

    const messageDate = DateTime.fromISO(item.createdAt);
    let timelineLabel;

    let messageTypeStyle = messageType;
    const classList = ["chat-message-bubble", messageType];

    if (previousMessage && previousMessage.createdAt) {
      const previousMessageDate = DateTime.fromISO(previousMessage.createdAt);
      if (previousMessageDate.hasSame(messageDate, "day")) {
        if (index === 0) {
          classList.push("Today");
        }
      } else {
        classList.push("date-label");
        timelineLabel = formatTimelineLabel(messageDate);
      }
    }

    if (index === 0) {
      if (DateTime.now().hasSame(messageDate, "day")) {
        classList.push("Today");
      } else {
        classList.push("date-label");
        timelineLabel = formatTimelineLabel(messageDate);
      }
    }

    if (previousMessage) {
      if (
        previousMessage.sender === item.sender ||
        previousMessage.recipient === item.recipient
      ) {
        classList.push("follow-up");
      }
    }

    if (nextMessage) {
      if (
        item.sender !== nextMessage.sender ||
        item.recipient !== nextMessage.recipient
      ) {
        classList.push("end");
      }

      const nextMessageDate = DateTime.fromISO(nextMessage.createdAt);
      if (!messageDate.hasSame(nextMessageDate, "day")) {
        classList.push("end");
      }
    } else {
      classList.push("end");
    }

    return (
      <div className={`message-wrapper ${messageType}`} key={item._id}>
        {timelineLabel && (
          <div className="chat-timeline-indicator font-poppins">
            <div className="timeline-text  bg-gray-300 text-black shadow-md dark:bg-gray-800 dark:text-gray-200">
              {timelineLabel}
            </div>
          </div>
        )}

        <div className={`${classList.join(" ")} shadow-sm font-poppins`}>
          <div className="message-text font-poppins">{item?.message?.text}</div>
        </div>
        {classList.includes("end") && (
          <div className="time font-poppins">{formatMessageDate(item)}</div>
        )}
      </div>
    );
  });

  return formattedMessages;
};

export default ChatComponent;
