export const rooms = [
  "minimalistic_modern_office_1k",
  "dining_room__kichen_baked_1k",
  "cozy_modern_living_room_1k",
  "white_round_exhibition_gallery_1k",
  "vr_apartment_loft_interior__baked_1k",
  "vr_exhibition_gallery_baked_1k",
  "scifi_tron_studio__baked_1k",
  "cozy_modern_living_room_1k"
]

export const roomSettings = {
  "5540183da1c7452f810f8de33734879a": {
    camera: {
      cameraPosition:
        "[36.88198436566039,-6.644599339391277,-2.3356798570944277]",
      orbitalTarget:
        "[-0.009305758408176915,-6.64459933939128,-4.61252014903409]",
    },
    avatar: {
      position: {
        x: 24,
        y: -15,
        z: 0,
      },
      rotate: {
        x: 0,
        y: 8,
        z: 0,
      },
      scale: 6,
      background: {
        id: "5540183da1c7452f810f8de33734879a",
        title: "Minimalistic Modern Office",
        author: {
          name: "dylanheyes",
          url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "minimalistic_modern_office_1k.glb",
        url: "https://sketchfab.com/models/5540183da1c7452f810f8de33734879a/embed?autospin=0&preload=1&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: -8,
          z: 0,
        },
        scale: 5.1,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
  "4831c2ce6a0044d9bee9eacefcc0f2bd": {
    camera: {
      cameraPosition:
        "[0.29973653474779316,8.393737904061593,19.98685734062697]",
      orbitalTarget:
        "[1.7125757530441241,8.393737904061592,-2.0982029996666327]",
    },
    avatar: {
      position: {
        x: 0,
        y: 0,
        z: 5,
      },
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 6,
      background: {
        id: "4831c2ce6a0044d9bee9eacefcc0f2bd",
        title: "Dining room | Kitchen baked",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "dining_room__kichen_baked_1k.glb",
        url: "https://sketchfab.com/models/4831c2ce6a0044d9bee9eacefcc0f2bd/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: 6,
        rotate: {
          x: 0,
          y: 90,
          z: 0,
        },
      },
    },
  },
  "581238dc5fda4dc990571cdc02827783": {
    camera: {
      cameraPosition:
        "[0.6880805765856549,11.97511625292191,4.548836338465648]",
      orbitalTarget:
        "[1.041443371958431,10.90924991324856,-0.5931374757159151]",
    },
    avatar: {
      position: {
        x: 0,
        y: 1,
        z: -9,
      },
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 7.5,
      background: {
        id: "581238dc5fda4dc990571cdc02827783",
        title: "Cozy living room baked",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "cozy_living_room_baked_1k.glb",
        url: "https://sketchfab.com/models/581238dc5fda4dc990571cdc02827783/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: 8,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
  a443b8a0a2314a55ae5dee4dd6a151a0: {
    camera: {
      cameraPosition:
        "[0.6966650351019719,8.318481167468148,12.335823127179461]",
      orbitalTarget:
        "[1.8639481371001467,0.3742773829268109,-8.286327131234016]",
    },
    avatar: {
      position: {
        x: 0,
        y: -3.1,
        z: 0,
      },
      rotate: {
        x: 0,
        y: 6.3,
        z: 0,
      },
      scale: 7,
      background: {
        id: "a443b8a0a2314a55ae5dee4dd6a151a0",
        title: "White round exhibition gallery",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "white_round_exhibition_gallery_1k.glb",
        url: "https://sketchfab.com/models/a443b8a0a2314a55ae5dee4dd6a151a0/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 17,
          z: 0,
        },
        scale: 4,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
  "54bc929a26094b6cb36f977903fbcd97": {
    camera: {
      cameraPosition: "[4.867280962748174,9.8531591151308,18.636157231187518]",
      orbitalTarget:
        "[1.7125757530441241,8.393737904061592,-2.0982029996666327]",
    },
    avatar: {
      position: {
        x: 5,
        y: 0.7,
        z: 5,
      },
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 6,
      background: {
        id: "54bc929a26094b6cb36f977903fbcd97",
        title: "VR apartment loft interior | baked",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "vr_apartment_loft_interior__baked_1k.glb",
        url: "https://sketchfab.com/models/54bc929a26094b6cb36f977903fbcd97/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: 6,
        rotate: {
          x: 0,
          y: 275,
          z: 0,
        },
      },
    },
  },
  ee6f3b0d9db14b62a1b3aeef04315313: {
    camera: {
      cameraPosition:
        "[2.259136177608796,8.850381692267003,0.34547397244415434]",
      orbitalTarget: "[6.710627830092317,8.954224585854606,1.0776590387387597]",
    },
    avatar: {
      position: {
        x: 10,
        y: 0,
        z: 0,
      },
      rotate: {
        x: 0,
        y: 4.7,
        z: 0,
      },
      scale: 6,
      background: {
        id: "ee6f3b0d9db14b62a1b3aeef04315313",
        title: "VR exhibition gallery baked",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "vr_exhibition_gallery_baked_1k.glb",
        url: "https://sketchfab.com/models/ee6f3b0d9db14b62a1b3aeef04315313/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: 2,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
  "88c7b7b5adc54b2eab451ebaf55ce941": {
    camera: {
      cameraPosition:
        "[1.5788058702318573,12.23387445781712,19.399473936649187]",
      orbitalTarget: "[6.515443445369885,8.772144975436209,0.3588011455252593]",
    },
    avatar: {
      position: {
        x: 0,
        y: 2.15,
        z: 10,
      },
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 6,
      background: {
        id: "88c7b7b5adc54b2eab451ebaf55ce941",
        title: "Sci-fi Tron Studio | Baked",
        author: {
          name: "ChristyHsu",
          url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "scifi_tron_studio__baked_1k.glb",
        url: "https://sketchfab.com/models/88c7b7b5adc54b2eab451ebaf55ce941/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 0,
          z: 0,
        },
        scale: 2,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
  "6a030bdb839e4e74bb58d7edb8f329e7": {
    camera: {
      cameraPosition:
        "[-19.392187185237322,2.9704119031023337,3.4367469384323774]",
      orbitalTarget: "[3.7853622568433294,2.5717629613689956,1.13519660317449]",
    },
    avatar: {
      position: {
        x: -7,
        y: -6,
        z: 0,
      },
      rotate: {
        x: 0,
        y: 5,
        z: 0,
      },
      scale: 5.5,
      background: {
        id: "6a030bdb839e4e74bb58d7edb8f329e7",
        title: "Cozy Modern Living Room",
        author: {
          name: "dylanheyes",
          url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "cozy_modern_living_room_1k.glb",
        url: "https://sketchfab.com/models/6a030bdb839e4e74bb58d7edb8f329e7/embed?autospin=0&preload=1&dnt=1",
      },
      room: {
        position: {
          x: 1,
          y: 0,
          z: -3,
        },
        scale: 6,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
};

export const backgroundImages = [
  {
    id: "5540183da1c7452f810f8de33734879a",
    title: "Minimalistic Modern Office",
    author: {
      name: "dylanheyes",
      url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "minimalistic_modern_office_1k.glb",
    url: "https://sketchfab.com/models/5540183da1c7452f810f8de33734879a/embed?autospin=0&preload=1&dnt=1",
  },
  {
    id: "4831c2ce6a0044d9bee9eacefcc0f2bd",
    title: "Dining room | Kitchen baked",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "dining_room__kichen_baked_1k.glb",
    url: "https://sketchfab.com/models/4831c2ce6a0044d9bee9eacefcc0f2bd/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "581238dc5fda4dc990571cdc02827783",
    title: "Cozy living room baked",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "cozy_living_room_baked_1k.glb",
    url: "https://sketchfab.com/models/581238dc5fda4dc990571cdc02827783/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "a443b8a0a2314a55ae5dee4dd6a151a0",
    title: "White round exhibition gallery",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "white_round_exhibition_gallery_1k.glb",
    url: "https://sketchfab.com/models/a443b8a0a2314a55ae5dee4dd6a151a0/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "54bc929a26094b6cb36f977903fbcd97",
    title: "VR apartment loft interior | baked",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "vr_apartment_loft_interior__baked_1k.glb",
    url: "https://sketchfab.com/models/54bc929a26094b6cb36f977903fbcd97/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "ee6f3b0d9db14b62a1b3aeef04315313",
    title: "VR exhibition gallery baked",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "vr_exhibition_gallery_baked_1k.glb",
    url: "https://sketchfab.com/models/ee6f3b0d9db14b62a1b3aeef04315313/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "88c7b7b5adc54b2eab451ebaf55ce941",
    title: "Sci-fi Tron Studio | Baked",
    author: {
      name: "ChristyHsu",
      url: "https://sketchfab.com/ida61xq?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "scifi_tron_studio__baked_1k.glb",
    url: "https://sketchfab.com/models/88c7b7b5adc54b2eab451ebaf55ce941/embed?autostart=0&autospin=0&camera=0&preload=1&ui_hint=0&dnt=1",
  },
  {
    id: "6a030bdb839e4e74bb58d7edb8f329e7",
    title: "Cozy Modern Living Room",
    author: {
      name: "dylanheyes",
      url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
    },
    file: "cozy_modern_living_room_1k.glb",
    url: "https://sketchfab.com/models/6a030bdb839e4e74bb58d7edb8f329e7/embed?autospin=0&preload=1&dnt=1",
  },
];
