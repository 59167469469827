import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
  settings: {
    candidatePage: {
      filterKey: "all" | "active" | "archived";
      sortKey: number;

      indexes: {
        departmentIndex: number;
        jobIndex: number;
        candidateIndex: number;
      };
    };
  };
}

const initialState: SettingsState = {
  settings: {
    candidatePage: {
      filterKey: "all",
      sortKey: 0,

      indexes: {
        departmentIndex: 0,
        jobIndex: 0,
        candidateIndex: 0,
      },
    },
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateCandidatePageDepartmentIndex: (
      state,
      action: PayloadAction<{ departmentIndex: number }>
    ) => {
      state.settings = {
        ...state.settings,
        candidatePage: {
          ...state.settings.candidatePage,
          indexes: {
            ...state.settings.candidatePage.indexes,
            departmentIndex: action.payload.departmentIndex,
          },
        },
      };
    },
    updateCandidatePageJobIndex: (
      state,
      action: PayloadAction<{ jobIndex: number }>
    ) => {
      state.settings = {
        ...state.settings,
        candidatePage: {
          ...state.settings.candidatePage,
          indexes: {
            ...state.settings.candidatePage.indexes,
            jobIndex: action.payload.jobIndex,
          },
        },
      };
    },
    updateCandidatePageCandidateIndex: (
      state,
      action: PayloadAction<{ candidateIndex: number }>
    ) => {
      state.settings = {
        ...state.settings,
        candidatePage: {
          ...state.settings.candidatePage,
          indexes: {
            ...state.settings.candidatePage.indexes,
            candidateIndex: action.payload.candidateIndex,
          },
        },
      };
    },
    updateCandidatePageFilter: (
      state,
      action: PayloadAction<{ filterKey: "all" | "active" | "archived" }>
    ) => {
      state.settings = {
        ...state.settings,
        candidatePage: {
          ...state.settings.candidatePage,
          filterKey: action.payload.filterKey,
        },
      };
    },
    updateCandidatePageSort: (
      state,
      action: PayloadAction<{ sortKey: number }>
    ) => {
      state.settings = {
        ...state.settings,
        candidatePage: {
          ...state.settings.candidatePage,
          sortKey: action.payload.sortKey,
        },
      };
    },
  },
});

export const {
  updateCandidatePageDepartmentIndex,
  updateCandidatePageJobIndex,
  updateCandidatePageCandidateIndex,
  updateCandidatePageFilter,
  updateCandidatePageSort,
} = settingsSlice.actions;

export default settingsSlice.reducer;
