import axios from "utils/axios";

export const loginUser = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/users/login`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUserstatusData = (userId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/${userId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const publishUser = (userId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/publish/${userId}`, { data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUserData = (userId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/${userId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const resendLoginOtp = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/resend/otp/login", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const verifyLoginOtp = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/verify/login/otp", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const verifyUserLoginOtp = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/verifyuser/login/otp", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const findByEmail = (email) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/users/find/by/mail`, { email })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const FindUser = (userId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/users/userdetails/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
