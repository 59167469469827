import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SceneState {
  cs_scene: {
    name: string;
  };
}

const initialState: SceneState = {
  cs_scene: {
    name: "",
  },
};

export const sceneSlice = createSlice({
  name: "cs_scene",
  initialState,
  reducers: {
    addScene: (state, action: PayloadAction<SceneState["cs_scene"]>) => {
      state.cs_scene = action.payload;
    },
    clearScene: (state) => {
      state.cs_scene = {
        name: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addScene, clearScene } = sceneSlice.actions;

export default sceneSlice.reducer;
