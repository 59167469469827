import axios from "utils/axios";

export const login = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/auth/admin/login", {
        data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getlistAdmin = (adminId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/admin/list/${adminId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const register = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/company/create", {
        data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const verifyOTP = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/userInvitation/verify/otp", {
        data,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const resendVerification = (adminId) =>
  new Promise((resolve, reject) => {
    axios
      .post("/userInvitation/resend/otp", {
        adminId,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const forgotPassword = (email) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/reset/password", {
        email,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const resetPassword = (email, token, password, company) =>
  new Promise((resolve, reject) => {
    axios
      .post("/users/create/new/password", {
        email,
        token,
        password,
        company,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// get profile image
export const getProfileImage = (profileImage) =>
  new Promise((resolve, reject) => {
    axios
      .get("/candidate/resume/getfile?key=" + profileImage)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateUserToken = (userId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/token/${userId}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
