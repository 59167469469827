import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InterviewOption {
  jobId: string;
  questions: any[];
  options?: any[];
}

interface InterviewOptionsState {
  interview: InterviewOption[];
}

const initialState: InterviewOptionsState = {
  interview: [],
};

function elementExists(arr: any[], key: string, value: any): boolean {
  const check = arr.some(function (el) {
    return el[key] === value;
  });
  return check;
}

export const interviewOptionsStateSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    addJobOptions: (state, action: PayloadAction<InterviewOption>) => {
      state.interview =
        state.interview.length > 0
          ? state.interview.map((item) => {
              if (item.jobId === action.payload.jobId) {
                return {
                  ...item,
                  questions: item.questions.concat(action.payload.questions),
                };
              } else {
                return item;
              }
            })
          : [
              {
                jobId: action.payload.jobId,
                questions: action.payload.questions,
              },
            ];
    },

    editJobOptions: (state, action: PayloadAction<any>) => {
      state.interview = state.interview.map((item) => {
        let updatedQuestions: { question: { id: string } }[] = [];
        if (item.jobId === action.payload.jobId) {
          updatedQuestions = item.questions.map((question) => {
            if (question.question.id === action.payload.question.id) {
              return action.payload;
            }
            return question;
          });
          return { ...item, questions: updatedQuestions };
        }
        return item;
      });
    },

    editJobOptionsTitle: (state, action: PayloadAction<any>) => {
      state.interview = state.interview.map((item) => {
        if (item.jobId === action.payload.jobId) {
          return {
            ...item,
            options: item?.options
              ? item.options.concat({
                  jobId: action.payload.jobId,
                  id: action.payload.option.id,
                  data: [],
                })
              : [
                  {
                    jobId: action.payload.jobId,
                    id: action.payload.option.id,
                    data: [],
                  },
                ],
          };
        } else {
          return item;
        }
      });
    },

    editJobOptionsResponse: (
      state,
      action: PayloadAction<{
        jobId: number;
        option: {
          id: string;
          optionId: string;
          response: any;
        };
      }>
    ) => {
      state.interview = state.interview.map((item) => {
        let updatedOptions: {
          id: string;
          data: { id: string; response: any }[];
        }[] = [];
        if (item.jobId === String(action.payload.jobId)) {
          updatedOptions = (item.options ?? []).map((result) => {
            const resultOptionData: { id: string; response: any }[] = [];
            if (result.id === action.payload.option.id) {
              result?.data?.length
                ? result.data.map((option) => {
                    if (option.id === action.payload.option.optionId) {
                      resultOptionData.push({
                        ...option,
                        response: action.payload.option.response,
                      });
                    } else {
                      if (
                        !elementExists(
                          result.data,
                          "id",
                          action.payload.option.optionId
                        )
                      ) {
                        resultOptionData.push({
                          id: action.payload.option.optionId,
                          response: action.payload.option.response,
                        });
                      }
                      resultOptionData.push(option);
                    }
                  })
                : resultOptionData.push({
                    id: action.payload.option.optionId,
                    response: action.payload.option.response,
                  });
            }
            return { ...result, data: resultOptionData };
          });
          return { ...item, options: updatedOptions };
        }
        return item;
      });
    },

    editJobOptionsIsEnd: (state, action: PayloadAction<any>) => {
      state.interview = state.interview.map((item) => {
        let updatedOptions: { id: string; isEnd: boolean }[] = [];
        if (String(item.jobId) === String(action.payload.jobId)) {
          if (item.options) {
            updatedOptions = item.options.map((result) => {
              const resultOptionData: { id: string; isEnd: boolean }[] = [];
              if (result.id === action.payload.option.id) {
                result?.data?.length
                  ? result.data.map((option) => {
                      if (option.id === action.payload.option.optionId) {
                        resultOptionData.push({
                          ...option,
                          isEnd: action.payload.option.isEnd,
                        });
                      } else {
                        resultOptionData.push(option);
                      }
                    })
                  : resultOptionData.push({
                      id: action.payload.option.optionId,
                      isEnd: action.payload.option.isEnd,
                    });
              }
              return { ...result, data: resultOptionData };
            });
          }
        }
        return { ...item, options: updatedOptions };
      });
    },
  },
});

export const {
  addJobOptions,
  editJobOptions,
  editJobOptionsTitle,
  editJobOptionsResponse,
  editJobOptionsIsEnd,
} = interviewOptionsStateSlice.actions;

export default interviewOptionsStateSlice.reducer;
