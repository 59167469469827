import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  body: {
    width: "100vw",
  },
  root: {
    backgroundColor: "#E0E0E0",
    height: "100vh",
    overflowY: "hidden",
  },
  TopBar: {
    display: "flex",
    background: "#fff",
    borderRadius: "0px 0px 7px 7px",
    width: "99%",
    marginLeft: 9,
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "10vh",
  },
  SceneBar: {
    background: "#fff",
    minWidth: "35%",
    width: "50%",
    height: "90vh",
    borderRadius: "5px",
    marginLeft: 8,
    marginRight: 6,
    display: "inline-block",
    verticalAlign: "middle",
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    overflowX: "hidden",

    ["@media screen and (orientation: portrait)"]: {
      height: "98vh",
    },
    // ["@media screen and (orientation: landscape)"]: {
    //   height: "98vh",
    // },
  },
  Sidebar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    verticalAlign: "middle",
    background: "#fff",
    width: "100%",
    // margin: 6,
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    minHeight: "86vh",
    maxHeight: "90vh",
    overflowX: "hidden",
  },
  mikeBar: {
    display: "flex",
    background: "#fff",
    width: "20%",
    minWidth: "34%",
    ["@media (max-width: 1200px)"]: {
      width: "40%",
      left: "40px",
    },
    ["@media (max-width: 570px)"]: {
      width: "50%",
      left: "40px",
    },
    ["@media (max-width: 539px)"]: {
      width: "80%",
      left: "40px",
    },
    // left: "12px",
    borderRadius: "6px",
    alignItems: "center",
    margin: 6,
    position: "absolute",
    top: "85%",
    bottom: "20%",
    minHeight: "13vh",
    zIndex: 20000,
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
  },
  loaderWrapper: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    top: 0,
    left: 0,
    zIndex: 20000,
    backgroundColor: "rgba(255, 255, 255, 0.90)",
    backdropFilter: "blur(10px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  avatarSpeechWrapper: {
    // margin: 5,
    // display: "flex",
    // flex: 1,
    // flexDirection: "row",
    // wordBreak: "break-word",
    // flexWrap: "wrap",
    // alignItems: "center",
    flex: "1 1 0",
    fontFamily: "Decimal Light",
    fontWeight: 300,
    lineHeight: "24px",
    wordWrap: "break-word",
  },
  micContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  recorderTimeWrapper: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    backgroundColor: "#fff",
    alignItems: "center",
    color: "#ff1744",
    padding: "0px 10px",
    borderRadius: 50,
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
  },
  recorderText: {
    fontSize: 12,
    margin: 0,
    fontFamily: "'Poppins', 'Inter', sans-serif !important",
  },
  messageInput: {
    border: "none !important",
    width: "95%",
    marginLeft: 5,
    marginRight: 10,
    "& .MuiInputBase-input": {
      paddingTop: "8px",
    },
    "& .MuiInputBase-root": {
      padding: 0,
      backgroundColor: "rgba(255, 255, 255, 0.0) !important",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.0) !important",
      },
      "&::before": {
        border: "none !important",
        content: "",
      },
      "&::after": {
        border: "none !important",
        content: "",
      },
    },
  },
  button: {
    color: "#007BFF",
    backgroundColor: "#D4F1F4 !important",
    textTransform: "none !important",
    marginRight: "10px !important",
    height: 40,
    fontFamily: "'Poppins', 'Inter', sans-serif !important",
  },
  logoutButton: {
    // color: "#007BFF",
    // backgroundColor: "#D4F1F4 !important",
    // fontFamily: "'Poppins', 'Inter', sans-serif !important",
    marginTop: "20px !important",
  },
  profileWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "auto",
    minHeight: 45,
    borderRadius: 35,
    margin: 5,
    backgroundColor: "#e1f5fe !important",
    marginRight: 20,
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    marginRight: 15,
  },
  modalContainer: {
    marginTop: -30,
    padding: 20,
  },
  controlWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  avatar: {
    marginRight: 15,
    marginLeft: 10,
    "&:hover": {
      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    },
  },
  avatarData: {
    display: "flex",
    width: 150,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "'Poppins', 'Inter', sans-serif !important",
  },
}));

export const primaryBtn = {
  color: "#bdbdbd",
  margin: "0px 5px",
  fontFamily: "'Poppins', 'Inter', sans-serif !important",
  textTransform: "capitalize",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

export const closeBtn = {
  color: "#ff1744",
  marginRight: "5px",
  fontFamily: "'Poppins', 'Inter', sans-serif !important",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#ff1744",
    color: "#fff",
    boxShadow: "rgba(213,0,0,0.16) 0px 3px 6px, rgba(213,0,0,0.23) 0px 3px 6px",
  },
};

export const micRecordingButton = {
  color: "#ff1744",
  flexDirection: "row",
  fontFamily: "'Poppins', 'Inter', sans-serif !important",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#ff1744",
    color: "#fff",
    boxShadow: "rgba(213,0,0,0.16) 0px 3px 6px, rgba(213,0,0,0.23) 0px 3px 6px",
  },
};

export const micButton = {
  color: "#bdbdbd",
  flexDirection: "row",
  fontFamily: "'Poppins', 'Inter', sans-serif !important",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    backgroundColor: "#2A8BF2",
    color: "#fff",
    boxShadow:
      "rgba(42,139,242,0.16) 0px 3px 6px, rgba(42,139,242,0.23) 0px 3px 6px",
  },
};

export const formControlStyle = {
  marginBottom: "30px !important",
  "& label.MuiFormLabel-root": {
    color: "#242424 !important",
  },
};
