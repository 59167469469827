import axios from "utils/axios";

export const getCandidateByUserId = (userId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/candidate/get/by/user/id/${userId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// read departments
export const getDepartmentDetails = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get("/department/read_department?company=" + companyId)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDepartmentJobList = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/department/read/company/${companyId}/jobs`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// department job read
export const departmentJobsRead = (departmentIndex) =>
  new Promise((resolve, reject) => {
    axios
      .get("/jobs/read?deptId=" + departmentIndex)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

//candidate read
export const getJobCandidateList = (jobId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/jobs/get/applications/${jobId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// add candidate
export const addCandidate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/add`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// create conversation
export const createMessage = (message) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/create", message, {
        headers: {
          Authorization: localStorage.getItem("matchToken"),
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createStatusMessage = (message) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/create", message, {
        headers: {
          Authorization: localStorage.getItem("matchToken"),
        },
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
/**
 * Inserts one or more message objects
 * @param {Array} messages : Array of message objects
 * @returns {Promise} Promise : message insert response
 */
export const insertMessage = (messages) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/create", messages, {
        headers: {
          Authorization: localStorage.getItem("matchToken"),
        },
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findMessage = (query) =>
  new Promise((resolve, reject) => {
    axios
      .post("/conversation/find", query)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getConversations = (candidateId, jobId, limit, page) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/get/${candidateId}/job/${jobId}/${limit}/${page}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const readConversations = (candidateProfile) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/conversation/get/${candidateProfile?._id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// candidate list

export const candidateFilter = (candidateJobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/candidate/filter_id/${candidateJobId}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const candidateStatusRead = (candidateId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/candidate/read_status/${candidateId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Update candidate record data
 * @param {String} candidate : candidate id
 * @param {Object} data : data need to updated
 * @returns Promise: update response
 */
export const updateCandidateData = (candidate, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/update/${candidate}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const candidateStatusUpdate = (candidateId, StatusData) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/users/update/${candidateId}`, StatusData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const userDetails = (userId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/users/userDetails/${userId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getCandidateDetails = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/candidate/find/${candidateId}`, data)

      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const uploadPrivateNoteAttachment = (file) =>
  new Promise((resolve, reject) => {
    axios
      .post("/candidate/resume/upload", file)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const uploadPrivateNoteAttachments = (formData) =>
  new Promise((resolve, reject) => {
    axios
      .post("/privateNoteAttachment/upload", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUploadFileKey = (resumePath) =>
  new Promise((resolve, reject) => {
    axios
      .get("/candidate/resume/getfile?key=" + resumePath, {
        headers: { Authorization: localStorage.getItem("matchToken") },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const moveCandidate = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/move/candidate/${candidateId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const copyCandidate = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/copy/candidate/${candidateId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const documentUpload = (formData) =>
  new Promise((resolve, reject) => {
    axios
      .post("/candidate/documents/upload", formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const saveResumeData = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/upload/resume", { data: data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const MultiArchiveCandidate = (selected) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/multiarchive`, selected)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const MultiUnarchiveCandidate = (selected) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/multiunarchive`, selected)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const MultiMoveCandidate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/multimove`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const MultiCopyCandidate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/multicopy`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// register candidate details
export const registerCandidate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/create`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// login candidate details
export const loginCandidate = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/login`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// login candidate details
export const candidateForgotPassword = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/reset/password`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

// login candidate details
export const candidateChangePassword = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/create/new/password`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

//social auth
export const socialAuth = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/social/auth`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

//create/update candidate scenesetup
export const updateCandidateSceneSetup = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/update/avatar/${candidateId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateCandidateUnPublishedSceneData = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/candidate/update/unpublish/${candidateId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const listSceneSetup = (candidateId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/candidate/list/scenesetup/${candidateId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

//change candidate password settings

export const candidateSettingChangePassword = (
  email,
  candidateId,
  existingPassword,
  newPassword,
) =>
  new Promise((resolve, reject) => {
    axios
      .post("/candidate/change/password", {
        email,
        candidateId,
        existingPassword,
        newPassword,
      })

      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const deleteAccount = (candidateId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/candidate/delete/account/candidate/${candidateId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

//login otp verification
export const verifyLoginOtp = (data) =>
  new Promise((resolve, reject) => {
    axios
      .post("/candidate/verify/login/otp", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

/**
 * Upload candidate scene as profile video
 * @param {OBJECT} data : profile video form data
 * @returns Promise
 */
export const uploadCandidateSceneProfileVideo = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/upload/profile_video/${candidateId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const searchByFilter = (query, type, path = null) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/search/filter/query`, { query, path, type })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const spaceListCandidate = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/candidate/data/job/company/${companyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
