import React from "react";
import { makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";

import FacebookIcon from "./icons/facebook.svg";
import TwitterIcon from "./icons/twitter.svg";
import LinkedInIcon from "./icons/linkedin.svg";
import PinterestIcon from "./icons/pinterest.svg";
import RedditIcon from "./icons/reddit.svg";
import TumblrIcon from "./icons/tumblr.svg";
import EmailIcon from "./icons/email.svg";
import SmsIcon from "./icons/sms.svg";
import WhatsappIcon from "./icons/whatsapp.svg";

const useStyles = makeStyles(() => ({
  shareContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  icon: {
    width: "30px",
    height: "30px",
    resizeMode: "contain",
  },
  icon1: {
    width: "40px",
    height: "40px",
    resizeMode: "contain",
  },
}));

const SocialShare = ({ shareUrl }) => {
  const classes = useStyles();

  const handleClick = (type) => {
    switch (type) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?text=${shareUrl}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`,
          "_blank"
        );
        break;

      case "whatsapp":
        window.open(
          `https://wa.me/?text=${encodeURIComponent(shareUrl)}`,
          "_blank"
        );
        break;

      case "pinterest":
        window.open(
          `https://pinterest.com/pin/create/button/?url=${shareUrl}`,
          "_blank"
        );
        break;
      case "reddit":
        window.open(
          `https://reddit.com/submit?url=${shareUrl}&title=`,
          "_blank"
        );
        break;
      case "tumblr":
        window.open(
          `https://www.tumblr.com/widgets/share/tool?canonicalUrl=${shareUrl}&title=&caption=`,
          "_blank"
        );
        break;

      case "email":
        window.open(`mailto:?body=${shareUrl}`, "_blank");
        break;
      case "sms":
        window.open(`sms:?body=${shareUrl}`, "_blank");
        break;

      default:
        break;
    }
  };

  return (
    <div className={classes.shareContainer}>
      <IconButton onClick={() => handleClick("facebook")}>
        <img src={FacebookIcon} alt="facebook" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("twitter")}>
        <img src={TwitterIcon} alt="Twitter" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("linkedin")}>
        <img src={LinkedInIcon} alt="LinkedIn" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("whatsapp")}>
        <img src={WhatsappIcon} alt="Whatsapp" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("pinterest")}>
        <img src={PinterestIcon} alt="Pinterest" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("reddit")}>
        <img src={RedditIcon} alt="Reddit" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("tumblr")}>
        <img src={TumblrIcon} alt="Tumblr" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("email")}>
        <img src={EmailIcon} alt="Email" className={classes.icon} />
      </IconButton>
      <IconButton onClick={() => handleClick("sms")}>
        <img src={SmsIcon} alt="SMS" className={classes.icon} />
      </IconButton>
    </div>
  );
};

export default SocialShare;
