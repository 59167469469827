import React, { useState, useEffect, useRef } from "react";
import { Popover, Typography, Button, Avatar } from "@mui/material";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Link } from "react-router-dom";
import moment from "moment";
import { Divider } from "@mui/material";

import RenderBadge from "./RenderBadge";

import {
  updateNotificationStatus,
  updateOne,
} from "api/dashboard/notification";
import { removeNotificationPath } from "redux/dashboard/reducers/notificationSlice";
import { setDayOfYear } from "date-fns";

const useStyles = makeStyles(() => ({
  TopBar: {
    display: "flex",
    // background: "#fff",
    borderRadius: "0px 0px 7px 7px",
    marginLeft: 18,
    marginRight: 16,
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    alignItems: "center",
    minHeight: "10vh",
    margin: "10px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    justifyItems: "flex-end",
  },
  menuStyle: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    width: "350px",
    marginTop: 20,
  },
  notificationWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "8px",
    paddingRight: "15px",
    paddingLeft: "15px",
    padding: "15px",
    width: "400px",
    maxHeight: "450px",
  },
  notificationHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    color: "#616161",
    padding: "10px",
  },
  notificationTitle: {
    fontSize: "22px",
    paddingLeft: "10px",
    paddingTop: "5px",
  },
  notificationFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    color: "#9e9e9e",
    paddingLeft: "10px",
  },
  showAllButton: {
    color: "#007bff",
    cursor: "pointer",
    padding: "2px 5px",
    borderRadius: 5,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#007bff",
    },
  },
  markAllRead: {
    color: "#007bff",
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    alignSelf: "flex-end",
  },
  notificationBody: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    paddingRight: "10px",
    paddingLeft: "10px",
    borderRadius: "8px",
    alignItems: "center",
    color: "#212121",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  notificationContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingTop: "10px",
    paddingBottom: "5px",
    fontSize: "15px",
  },
  notificationTime: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    fontSize: "12px",
    color: "#9e9e9e",
  },
  notificationAvatar: {
    padding: "8px",
  },
  notifRegisterIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifRejectIcon: {
    backgroundColor: "#e53935",
    borderRadius: "50%",
    padding: "2px",
  },
  notifCompletedIcon: {
    backgroundColor: "#00bfa5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifMailIcon: {
    backgroundColor: "#ef6c00",
    borderRadius: "50%",
    padding: "2px",
  },
  notifAssignmentIcon: {
    backgroundColor: "#00bfa5",
    borderRadius: "50%",
    padding: "2px",
  },

  chatTimeLineMarkerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  chatTimeLineMarkerWrapper: {
    padding: "5px 10px",
    margin: "2px 5px",
    background: "#e1f5fe",
    color: "#03a9f4",
    fontSize: 12,
    fontFamily: "poppins, Inter, sans-serif",
    fontWeight: "400",
    borderRadius: 20,
  },
}));

const notificationLink = {
  flexDirection: "column",
  textDecoration: "none !important",
  "&:hover": {
    textDecoration: "none !important",
  },
};

// const convertHtmlToPlainText = (htmlText) => {
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(htmlText, "text/html");
//   return doc.body.textContent;
// };

const NotificationList = ({
  popoverId,
  notifAnchorEl,
  notifOpen,
  notifClose,
}) => {
  const classes = useStyles();
  const routerHistory = useHistory();
  const history = useHistory();
  const dispatch = useAppDispatch();

  // const ps = useRef(null);
  // const messagesContainerRef = useRef(null);

  // const userId = useAppSelector(
  //   (state) => state?.userReducer?.user?.loggedUser?.userId
  // );

  const notificationData = useAppSelector(
    (state) => state?.notificationReducer?.notification,
  );

  // const [notificationData, setNotificationData] = useState([]);
  const [isNotificationEnd, setIsNotificationEnd] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPages] = useState(0);

  const limit = 5;

  // const handleSeeAll = () => {
  //   history.push("/candidate/notification/inbox");
  // };

  // useEffect(() => {
  //   if (notifOpen) {
  //     setPages(0);
  //     fetchNotificationData();
  //   }
  //   return () => {};
  // }, [notifOpen]);

  // useEffect(() => {
  //   if (messagesContainerRef.current) {
  //     messagesContainerRef.current.scrollTop =
  //       messagesContainerRef.current.scrollHeight;
  //   }
  // }, []);

  // const fetchNotificationData = () => {
  //   if (!isNotificationEnd) {
  //     getCandidateNotificationList(userId, limit, page)
  //       .then((res) => {

  //         setPages((previousState) => previousState + 1);
  //         if (res.length > 0) {
  //           setNotificationData([...notificationData, ...res]);
  //         } else {
  //           setIsNotificationEnd(true);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("failed to get candidate notification : ", error);
  //       });
  //   }
  // };

  // const handleNotificationMessageScroll = () => {
  //   setLoadMore(true);
  //   fetchNotificationData();
  // };

  // useEffect(() => {
  //   if (loadMore) {
  //     fetchNotificationData();
  //   }
  // }, [loadMore]);

  // const handleNotificationClick = (item) => {
  //   if (item?.redirect_link) {
  //     window.open(item?.redirect_link);
  //   } else {
  //     history.push("/candidate/notification/inbox");
  //   }
  //   notifClose();
  // };

  const handleNotificationClick = (item) => {
    const notificationId = item?._id;
    updateOne(notificationId, "read")
      .then((response) => {})
      .catch((error) => {
        console.error("Failed to update notification status", error);
      });
    dispatch(removeNotificationPath(item?.body?.data?.link));
    routerHistory.push(item?.body?.data?.link || "/");
    notifClose();
  };

  // return (
  //   <Popover
  //     data-testid="check-popeover"
  //     id={popoverId}
  //     open={notifOpen}
  //     anchorEl={notifAnchorEl}
  //     onClose={notifClose}
  //     anchorOrigin={{
  //       vertical: "bottom",
  //       horizontal: "right",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     PaperProps={{
  //       elevation: 0,
  //       borderRadius: 15,
  //       sx: {
  //         borderRadius: 2,
  //         overflow: "visible",
  //         backgroundColor: "#fff",
  //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
  //         "&:before": {
  //           content: '""',
  //           display: "block",
  //           position: "absolute",
  //           top: 6,
  //           right: 5,
  //           width: 25,
  //           height: 12,
  //           bgcolor: "background.paper",
  //           transform: "translateY(-50%) rotate(45deg)",
  //           borderRadius: "2px",
  //           zIndex: 0,
  //         },
  //       },
  //     }}
  //   >
  //     <div className={classes.notificationWrapper}>
  //       <div className={classes.notificationTitle}>Notifications</div>
  //       <PerfectScrollbar
  //         onYReachEnd={() => handleNotificationMessageScroll()}
  //         containerRef={(el) => (ps.current = el)}
  //       >
  //         {notificationData && notificationData.length ? (
  //           notificationData.map((item, index) => {
  //             return (
  //               <div
  //                 data-testid="notification-data"
  //                 key={item?._id || Math.random()}
  //               >
  //                 <div
  //                   data-testid="notifications-click"
  //                   style={{
  //                     justifyContent: "left",
  //                     alignItems: "left",
  //                     textAlign: "left",
  //                   }}
  //                 >
  //                   <div className={classes.chatTimeLineMarkerContainer}>
  //                     <Divider>
  //                       {moment(item?.createdAt).format("MM/DD/YYYY ") ===
  //                       moment().format("MM/DD/YYYY ") ? (
  //                         notificationData[index - 1]?.createdAt !==
  //                         undefined ? (
  //                           (moment(
  //                             notificationData[index - 1]?.createdAt
  //                           ).format("MM/DD/YYYY ") ===
  //                             moment().format("MM/DD/YYYY ")) ===
  //                           true ? (
  //                             ""
  //                           ) : (
  //                             <div
  //                               className={classes.chatTimeLineMarkerWrapper}
  //                             >
  //                               TODAY
  //                             </div>
  //                           )
  //                         ) : (
  //                           <div className={classes.chatTimeLineMarkerWrapper}>
  //                             TODAY
  //                           </div>
  //                         )
  //                       ) : moment(item?.createdAt).format("MMMM D YYYY") ===
  //                         moment(notificationData[index - 1]?.createdAt).format(
  //                           "MMMM D YYYY"
  //                         ) ? (
  //                         ""
  //                       ) : (
  //                         <div className={classes.chatTimeLineMarkerWrapper}>
  //                           {moment(item?.createdAt).format("MMMM D YYYY")}
  //                         </div>
  //                       )}
  //                     </Divider>
  //                   </div>

  //                   <div
  //                     className={classes.notificationBody}
  //                     onClick={() => handleNotificationClick(item)}
  //                   >
  //                     <div className={classes.notificationAvatar}>
  //                       {/* <RenderBadge
  //                         badgeType={item?.body?.data?.badgeType}
  //                         icon={item?.body?.data?.icon}
  //                       /> */}
  //                       <Avatar src={item?.sender?.profile_photo} />
  //                     </div>
  //                     <div className={classes.notificationContent}>
  //                       <Typography
  //                         style={{
  //                           fontWeight: "bold",
  //                           fontSize: 13,
  //                           fontFamily:
  //                             "'Poppins','Inter', sans-serif !important",
  //                           textTransform: "none",
  //                           textAlign: "left",
  //                         }}
  //                       >
  //                         {item?.email?.subject}
  //                       </Typography>
  //                       <Typography
  //                         style={{
  //                           fontSize: 11,
  //                           fontFamily: "poppins",
  //                           textAlign: "start",
  //                           textTransform: "capitalize",
  //                         }}
  //                       >
  //                         {convertHtmlToPlainText(item?.email?.body).length &&
  //                         convertHtmlToPlainText(item?.email?.body).length > 100
  //                           ? convertHtmlToPlainText(item?.email?.body) &&
  //                             convertHtmlToPlainText(item?.email?.body).slice(
  //                               0,
  //                               100
  //                             )
  //                           : convertHtmlToPlainText(item?.email?.body)}
  //                       </Typography>

  //                       {moment(item?.createdAt).format("MM/DD//YYYY") ===
  //                       moment().format("MM/DD//YYYY") ? (
  //                         <div
  //                           className={classes.notificationTime}
  //                           style={{ textTransform: "lowercase" }}
  //                         >
  //                           {moment(item?.createdAt).fromNow()}
  //                         </div>
  //                       ) : (
  //                         <div className={classes.notificationTime}>
  //                           {item?.createdAt
  //                             ? DateTime.fromISO(item?.createdAt).toFormat(
  //                                 "hh:mm a"
  //                               )
  //                             : null}
  //                         </div>
  //                       )}
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             );
  //           })
  //         ) : (
  //           <div
  //             data-testid="no-notifications-found"
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignItem: "center",
  //               padding: 20,
  //             }}
  //           >
  //             <Typography
  //               style={{
  //                 color: "#616161",
  //                 fontSize: 16,
  //                 fontFamily: "'Poppins','Inter', sans-serif !important",
  //               }}
  //             >
  //               No Notifications
  //             </Typography>
  //           </div>
  //         )}
  //       </PerfectScrollbar>
  //       <div>
  //         <Button
  //           data-testid="see-all-notifications"
  //           className={classes.showAllButton}
  //           onClick={handleSeeAll}
  //         >
  //           SEE ALL
  //         </Button>
  //       </div>
  //     </div>
  //   </Popover>
  // );

  return (
    <Popover
      id={popoverId}
      open={notifOpen}
      anchorEl={notifAnchorEl}
      onClose={notifClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,
        borderRadius: 15,
        sx: {
          borderRadius: 2,
          overflow: "visible",
          backgroundColor: "#fff",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 6,
            right: 5,
            width: 25,
            height: 12,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            borderRadius: "2px",
            zIndex: 0,
          },
        },
      }}
    >
      <div className={classes.notificationWrapper}>
        <div className={classes.notificationHeader}>
          <div className={classes.notificationTitle}>Notifications</div>
          {notificationData && notificationData.length ? (
            <div
              data-testid="show-all-notifications"
              className={classes.showAllButton}
              onClick={() => {
                notifClose();
                routerHistory.push("/candidate/notifications");
              }}
            >
              Show all
            </div>
          ) : (
            ""
          )}
        </div>
        <PerfectScrollbar data-testid="scroll-notifications">
          {notificationData && notificationData.length ? (
            <Typography
              style={{ paddingLeft: "20px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              New
            </Typography>
          ) : (
            <></>
          )}

          {notificationData && notificationData.length ? (
            notificationData.map((item, index) => {
              return (
                <div key={item?._id || Math.random()}>
                  <div
                    style={{
                      justifyContent: "left",
                      alignItems: "left",
                      textAlign: "left",
                    }}
                  >
                    <div className={classes.chatTimeLineMarkerContainer}>
                      <Divider>
                        {moment(item?.createdAt).format("MM/DD/YYYY ") ===
                        moment().format("MM/DD/YYYY ") ? (
                          notificationData[index - 1]?.createdAt !==
                          undefined ? (
                            (moment(
                              notificationData[index - 1]?.createdAt,
                            ).format("MM/DD/YYYY ") ===
                              moment().format("MM/DD/YYYY ")) ===
                            true ? (
                              ""
                            ) : (
                              <div
                                className={classes.chatTimeLineMarkerWrapper}
                              >
                                TODAY
                              </div>
                            )
                          ) : (
                            <div className={classes.chatTimeLineMarkerWrapper}>
                              TODAY
                            </div>
                          )
                        ) : moment(item?.createdAt).format("MMMM D YYYY") ===
                          moment(notificationData[index - 1]?.createdAt).format(
                            "MMMM D YYYY",
                          ) ? (
                          ""
                        ) : (
                          <div className={classes.chatTimeLineMarkerWrapper}>
                            {moment(item?.createdAt).format("MMMM D YYYY")}
                          </div>
                        )}
                      </Divider>
                    </div>

                    {/* <Button
                      className={classes.notificationLink}
                      onClick={() => handleNotificationClick(item)}
                      // style={{
                      //   paddingLeft: "20px",
                      //   fontSize: 14,

                      // }}
                    > */}
                    <div className={classes.notificationBody}>
                      <div className={classes.notificationAvatar}>
                        <RenderBadge
                          badgeType={item?.body?.data?.badgeType}
                          icon={item?.body?.data?.icon}
                        />
                      </div>
                      <div className={classes.notificationContent}>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: 13,
                            fontFamily:
                              "'Poppins','Inter', sans-serif !important",
                            textTransform: "none",
                            textAlign: "left",
                          }}
                        >
                          {item?.body?.notification?.title}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 11,
                            fontFamily: "poppins",
                            textAlign: "start",
                            textTransform: "capitalize",
                          }}
                        >
                          {item?.body?.notification?.body}
                        </Typography>

                        {moment(item?.createdAt).format("MM/DD//YYYY") ===
                        moment().format("MM/DD//YYYY") ? (
                          <div
                            className={classes.notificationTime}
                            style={{ textTransform: "lowercase" }}
                          >
                            {moment(item?.createdAt).fromNow()}
                          </div>
                        ) : (
                          <div className={classes.notificationTime}>
                            {item?.createdAt
                              ? DateTime.fromISO(item?.createdAt).toFormat(
                                  "hh:mm a",
                                )
                              : null}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* </Button> */}
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                padding: 20,
              }}
            >
              <Typography
                style={{ color: "#616161", fontSize: 16, fontFamily: "inter" }}
              >
                No Notifications
              </Typography>
            </div>
          )}
        </PerfectScrollbar>
      </div>
    </Popover>
  );
};

export default NotificationList;
