import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface JobState {
  job: any[];
  selectedJob: any;
}

const initialState: JobState = {
  job: [],
  selectedJob: null,
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    addJob: (state, action: PayloadAction<any>) => {
      state.job = [...state.job, action.payload];
    },
    editJob: (state, action: PayloadAction<any>) => {
      state.job = state.job.map((job: any) =>
        job.id === action.payload.id ? action.payload : job
      );
    },
    deleteJob: (state, action: PayloadAction<any>) => {
      state.job = state.job.filter((job: any) => {
        return job.job_id !== action.payload.job_id;
      });
    },
    setSelectedJob: (state, action: PayloadAction<any>) => {
      state.selectedJob = action.payload;
    },
  },
});

export const { addJob, editJob, deleteJob, setSelectedJob } = jobSlice.actions;

export default jobSlice.reducer;
