import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Route, Switch, useHistory } from "react-router-dom";
import Sidebar from "components/dashboard/Sidebar/Sidebar";
import routes from "../router/routes";
import sidebarImage from "assets/dashboard/img/sidebar-3.jpg";
import TopBar from "components/dashboard/TopBar";
import { useAppDispatch, useAppSelector } from "redux/hooks";

import SupportWidget from "components/Support"

import { DateTime } from "luxon";

import {
  updateNotificationList,
  setNotificationCount,
  updateNotificationPath,
} from "redux/dashboard/reducers/notificationSlice";

import {
  updateEmailNotificationList,
  setEmailNotificationCount,
} from "redux/dashboard/reducers/emailNotificationSlice";

import { getNotificationById } from "api/dashboard/notification";
import { updateUserToken } from "api/dashboard/auth";

import { getFCMToken, onMessageListener } from "../firebase";
import Notification from "components/dashboard/Notification";
import { updateSidebarConfiguration } from "redux/dashboard/reducers/configSlice";

import broadcastChannel from "utils/BroadcastChannel";
import {
  updateFcmToken,
  updateSessionStatus,
} from "redux/dashboard/reducers/userSlice";
import SessionExpiredOverlay from "components/SessionExpiredOverlay";
import { isTesting } from "constants/production";

function Admin({ children }) {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userData = useAppSelector((state) => state?.userReducer?.user);
  const adminId = useAppSelector((state) => state?.userReducer?.user?.id);
  const notificationId = useAppSelector((state) => state?.notificationReducer);

  const supportChatWindowOpen = useAppSelector(
    (state) => state?.configReducer?.config?.supportChatWindow?.open,
  );

  const userPermission = useAppSelector(
    (state) => state?.userReducer?.user?.permissions,
  );

  const [permissionData, setPermissionData] = useState();
  const [user, setUser] = useState(
    useAppSelector((state) => state?.userReducer?.user),
  );

  useEffect(() => {
    broadcastChannel.onmessage = (event) => {
      const message = event.data;
      if (message?.userId && !isTesting) {
        // Handle the received message here
        if (message?.userId !== userData?.loggedUser?.userId) {
          dispatch(updateSessionStatus(false));
        }
      }
    };
  }, []);

  useEffect(() => {
    if (
      location?.pathname.includes("/scene-setup") ||
      location?.pathname.includes("/avatar") ||
      location.pathname.includes("/dashboard/inbox")
    ) {
      dispatch(updateSidebarConfiguration({ isExpanded: false }));
    }

    return () => {};
  }, [location?.pathname]);

  useEffect(() => {
    let permissions = ["insights", "departments", "scene-setup", "oauth"];
    if (userPermission?.permissions) {
      const temp = userPermission?.permissions.map((_permission) => {
        return _permission?.name.toLowerCase().replace(/ /g, "-");
      });
      setPermissionData(permissions.concat(temp));
    } else {
      setPermissionData(permissions.concat(permissions));
    }
    return () => {};
  }, [userPermission]);

  const notificationData = useAppSelector(
    (state) => state.notificationReducer.notification,
  )[0];

  const isExpanded = useAppSelector(
    (state) => state?.configReducer?.config?.sidebar?.isExpanded,
  );

  const [isInbox, setIsInbox] = useState(false);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("white");
  const [hasImage, setHasImage] = React.useState(true);

  const mainPanel = React.useRef(null);

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({});
  const [isTokenFound, setTokenFound] = useState(false);
  const [emailNotification, setEmailtNotification] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const getNotificationList = () => {
    if (adminId) {
      getNotificationById(adminId, 20)
        .then((result) => {
          if (result && result?.response.length) {
            const emailNotify = result?.response.filter(
              (item) => item?.body?.data?.type === "email",
            );
            const notify = result?.response.filter(
              (item) => item?.body?.data?.type !== "email",
            );

            const unreadEmails = emailNotify.filter(
              (item) => item?.status === "unread",
            );
            const unreadNotifications = notify.filter(
              (item) => item?.status === "unread",
            );

            if (unreadNotifications && unreadNotifications.length) {
              const notificationPathList = [];

              unreadNotifications.map((item) => {
                if (item?.body?.data?.link) {
                  notificationPathList.push(item?.body?.data?.link);
                }
              });

              if (notificationPathList.length) {
                dispatch(updateNotificationPath(notificationPathList));
              }
            }

            dispatch(setNotificationCount(unreadNotifications.length));
            dispatch(updateNotificationList(notify));
            dispatch(setEmailNotificationCount(unreadEmails.length));
            dispatch(updateEmailNotificationList(emailNotify));
          }
        })
        .catch((error) => {
          console.log("Failed to get notification", error);
        });
    }
  };

  // TODO uncomment

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     const visibilityState = document.visibilityState;
  //     const readyState = document.readyState;

  //     if (visibilityState === "visible" && readyState === "complete") {
  //       return getNotificationList();
  //     }
  //     if (visibilityState === "hidden") {
  //       setIsUpdated(false);
  //     }
  //   };

  //   let hidden, visibilityChange;
  //   if (typeof document.hidden !== "undefined") {
  //     // Opera 12.10 and Firefox 18 and later support
  //     hidden = "hidden";
  //     visibilityChange = "visibilitychange";
  //   } else if (typeof document.msHidden !== "undefined") {
  //     hidden = "msHidden";
  //     visibilityChange = "msvisibilitychange";
  //   } else if (typeof document.webkitHidden !== "undefined") {
  //     hidden = "webkitHidden";
  //     visibilityChange = "webkitvisibilitychange";
  //   }

  //   const visibilityListener = document.addEventListener(
  //     visibilityChange,
  //     handleVisibilityChange,
  //     false,
  //   );

  //   return () => {
  //     document.removeEventListener(visibilityListener, handleVisibilityChange);
  //   };
  // }, []);

  // reset badge
  useEffect(() => {
    dispatch(updateNotificationPath([]));
  }, []);

  useEffect(() => {
    if (adminId) {
      console.log("adminId", adminId);
      getFCMToken()
        .then((token) => {
          setTokenFound(true);
          if (token) {
            console.log("token", token);
            updateUserToken(adminId, token).catch((error) => {
              console.log("Failed to updateToken, Error : ".error);
            });
            dispatch(updateFcmToken(token));
          }
        })
        .catch((error) => {
          // reject(error);
        });
    }

    return () => {};
  }, [adminId]);

  onMessageListener()
    .then((payload) => {
      getNotificationList();
      setShow(true);
      setNotification({ body: payload });
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    getNotificationList();
    return () => {};
  }, []);

  const handleNotificationClose = () => {
    setShow(false);
  };

  // const [routerDataList, setRouterDataList] = useState();

  // useEffect(() => {
  //   if (user) {
  //     const routerList = getRoutes(routes);
  //     setRouterDataList(routerList);
  //   }

  //   return () => {};
  // }, [permissionData, user]);

  // const getRoutes = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.layout === "/admin") {
  //       const actualPath = prop.path.split("/")[1];
  //       if (permissionData && permissionData.includes(actualPath)) {
  //         return (
  //           <Route
  //             exact={prop?.exact}
  //             path={prop.layout + prop.path}
  //             render={(props) => <prop.component {...props} />}
  //             key={key}
  //           />
  //         );
  //       } else {
  //         return null;
  //       }
  //     }
  //   });
  // };

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  const handleSessionExpireOnGoBack = () => {
    history.push("/login");
  };

  return (
    <>
      {!userData?.sessionStatus &&
        !isTesting(
          <SessionExpiredOverlay onGoBack={handleSessionExpireOnGoBack} />,
        )}

      <div
        className="relative top-0 flex w-full flex-1 flex-col !overflow-hidden"
        data-testid="wrapper"
      >
        <Notification
          show={show}
          autoHide={5000}
          handleClose={handleNotificationClose}
          data={notification}
        />
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <TopBar />
        <div
          className={`flex h-[calc(100vh-52px)] max-h-[calc(100vh-52px)] flex-1 flex-col transition-all delay-200 ease-linear ${isExpanded ? "ml-[260px] w-[calc(100vw-260px)]" : "ml-[70px] w-[calc(100vw-70px)]"}`}
          ref={mainPanel}
          id="main-panel"
        >
          <div className="content">{children}</div>
          <SupportWidget />
        </div>
      </div>
    </>
  );
}

export default Admin;
