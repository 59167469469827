import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ISceneData {
  scene: any;
}

const initialState: ISceneData = {
  scene: {},
};

export const sceneSetupSlice = createSlice({
  name: "scene",
  initialState,
  reducers: {
    updateSceneSetupData: (state, action: PayloadAction<any>) => {
      state.scene = action.payload;
    },
  },
});

export const { updateSceneSetupData } = sceneSetupSlice.actions;

export default sceneSetupSlice.reducer;
