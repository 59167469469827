import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  DEFAULT_AVATAR,
  DEFAULT_AVATAR_BACKGROUND,
} from "constants/production";

const initialState = {
  cs_avatar: {
    preset: {
      _id: "",
      name: "",
    },
    camera: {
      cameraPosition: JSON.stringify([
        -1.3718756949120707, 5.41560311888367, 39.552857271663136,
      ]),
      orbitalTarget: JSON.stringify([
        0.0731354966405521, 5.415603118883667, -1.376195165469518,
      ]),
    },
    avatar: {
      url: DEFAULT_AVATAR,
      type: "female",
      position: {
        x: -1.1,
        y: -2.6,
        z: 21.9,
      },
      rotate: {
        x: 0,
        y: 0,
        z: 0,
      },
      scale: 6.0,
      speechVoice: "en-US-EmmaNeural",
      speechText: "",
      welcomeText: "Hi, Welcome",
      background: {
        id: "5540183da1c7452f810f8de33734879a",
        title: "Minimalistic Modern Office",
        author: {
          name: "dylanheyes",
          url: "https://sketchfab.com/dylanheyes?utm_medium=embed&utm_campaign=share-popup&utm_content=",
        },
        file: "minimalistic_modern_office_1k.glb",
        url: "https://sketchfab.com/models/5540183da1c7452f810f8de33734879a/embed?autospin=0&preload=1&dnt=1",
      },
      room: {
        position: {
          x: 0,
          y: 4.4,
          z: -1.5,
        },
        scale: 5.1,
        rotate: {
          x: 0,
          y: 0,
          z: 0,
        },
      },
    },
  },
};

export const avatarSpeechSlice = createSlice({
  name: "cs_avatarSpeech",
  initialState,
  reducers: {
    cs_updateAvatar: (state, action: PayloadAction<any>) => {
      state.cs_avatar = action.payload;
    },
    cs_addAvatarSpeech: (state, action: PayloadAction<any>) => {
      state.cs_avatar.avatar.speechText = action.payload;
    },
    cs_resetAvatar: (state) => {
      state.cs_avatar = initialState.cs_avatar;
    },
  },
});
// Action creators are generated for each case reducer function
export const { cs_updateAvatar, cs_addAvatarSpeech, cs_resetAvatar } =
  avatarSpeechSlice.actions;

export default avatarSpeechSlice.reducer;
