import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface JobDetailsState {
  cs_jobDetails: {
    _id: any;
    attempt: string;
  };
}

const initialState: JobDetailsState = {
  cs_jobDetails: {
    attempt: "",
  },
};

export const jobDetailsSlice = createSlice({
  name: "cs_jobDetails",
  initialState,
  reducers: {
    addJobDetails: (
      state,
      action: PayloadAction<JobDetailsState["cs_jobDetails"]>
    ) => {
      state.cs_jobDetails = action.payload;
    },

    updateLastAttempt: (state, action: PayloadAction<string>) => {
      state.cs_jobDetails.attempt = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addJobDetails, updateLastAttempt } = jobDetailsSlice.actions;

export default jobDetailsSlice.reducer;
