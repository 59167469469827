import React from "react";
import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100vh"
  },
  messageContainer: {
    marginLeft: 10,
    padding: 10,
    height: 80,
    flexDirection: "column",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    borderLeftColor: "#212121"
  },
  goBackBtn: {
    marginTop: "10px !important",
    padding: "5px 25px !important",
    color: "#212121 !important",
    backgroundColor: "#fff !important",
    borderStyle: "solid !important",
    borderColor: "#212121 !important",
    borderWidth: "1px !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#212121 !important"
    }
  }
}));

const NotFoundScreen = () => {
  const classes = useStyles();

  const history = useHistory();

  const handleClick = () => {
    history.push("/");
  };

  return (
    <div className={classes.container}>
      <Typography variant="h1">404</Typography>
      <div className={classes.messageContainer}>
        <Typography variant="body2">Page Not Found </Typography>
        <Button
          className={classes.goBackBtn}
          variant="text"
          onClick={handleClick}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundScreen;
