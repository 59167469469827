import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from '@mui/system';
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    display: "flex",
    flex: 1,
    borderRadius: 10,
    // maxWidth: "100vw",
    boxShadow:
      "0 0.9px 1.8px rgba(0, 0, 0, 0.006), 0 2.2px 4.4px rgba(0, 0, 0, 0.009),0 4.4px 9px rgba(0, 0, 0, 0.011), 0 9.1px 18.6px rgba(0, 0, 0, 0.014),0 25px 51px rgba(0, 0, 0, 0.02) !important",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  closeBtn: {
    color: "#000000",
    "&:hover": {
      color: "#f44336",
    },
    marginTop: "-5px !important",
  },
  
}));

const BootstrapDialogTitle = (props) => {
  const classes = useStyles();

  const { children, onClose, hideClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <div
        style={{ display: "flex", flex: 1, flexDirection: "row", padding: 5 }}
      >
        <div style={{ display: "flex", flex: 1 }}>{children}</div>
        {onClose && !hideClose ? (
          <IconButton
            className={classes.closeBtn}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const classes = useStyles();

  const {
    open,
    handleClose,
    title,
    children,
    actions,
    headerStyle,
    contentStyle,
    hideBackdrop,
    hideClose = false,
    ...other
  } = props;

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      hideBackdrop={hideBackdrop}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        hideClose={hideClose}
        style={{ alignItems: "center", ...headerStyle }}
      >
        {title}
      </BootstrapDialogTitle>
      <PerfectScrollbar>
        {children ? (
          <DialogContent style={contentStyle}>{children}</DialogContent>
        ) : (
          <></>
        )}
      </PerfectScrollbar>
      {actions ? <DialogActions>{actions}</DialogActions> : <></>}
    </BootstrapDialog>
  );
}

CustomizedDialogs.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  headerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  hideBackdrop: PropTypes.bool,
  hideClose: PropTypes.bool,
};
