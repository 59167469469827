import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader.js";

export const loadAsset = (assetPath: string, onLoad: any) => {
  return new Promise((resolve) => {
    const loader = new FBXLoader();

    loader.load(assetPath, async (asset) => {
      if (onLoad[Symbol.toStringTag] === "AsyncFunction") {
        const result = await onLoad(asset);
        resolve(result);
      } else {
        resolve(onLoad(asset));
      }
    });
  });
};

export const loadAndInitializeAnimations = (animationFile: string, title: string) =>
  new Promise((resolve, reject) => {
    loadAsset(`/assets/animations/${animationFile}`, (asset) => {
      asset.animations[0].name = title;

      resolve({ [title]: asset });
    });
  });

  export interface VisemeMapType {
    [key: number]: string;
  }
// export const visemeMap: VisemeMapType = {
//   sil: "viseme_sil",
//   p: "viseme_PP",
//   t: "viseme_FF",
//   S: "viseme_TH",
//   T: "viseme_DD",
//   f: "viseme_kk",
//   k: "viseme_CH",
//   i: "viseme_SS",
//   r: "viseme_nn",
//   s: "viseme_RR",
//   u: "viseme_aa",
//   "@": "viseme_E",
//   a: "viseme_I",
//   e: "viseme_O",
//   E: "viseme_U",
//   o: "viseme_O",
//   O: "viseme_U",
// };


// mapping from Microsoft viseme IDs to custom viseme IDs
export const visemeMap : VisemeMapType = {
  0: "viseme_sil",  // Silence
  1: "viseme_PP",   // p, b, m
  2: "viseme_PP",   // p, b, m
  3: "viseme_FF",   // f, v
  4: "viseme_I",    // ih, ix
  5: "viseme_E",    // eh
  6: "viseme_SS",   // s, z
  7: "viseme_AA",   // ae
  8: "viseme_AA",   // aa
  9: "viseme_AA",   // ah
  10: "viseme_O",   // ao
  11: "viseme_E",   // ey
  12: "viseme_U",   // aw
  13: "viseme_U",   // ow
  14: "viseme_U",   // uh
  15: "viseme_U",   // uw
  16: "viseme_I",   // er
  17: "viseme_I",   // ax
  18: "viseme_CH",  // ch, jh
  19: "viseme_DD",  // dh, t, d
  20: "viseme_nn",  // n, l
  21: "viseme_RR",  // r
  22: "viseme_TH"   // th
};
