import React, { useState, useEffect } from "react";
import { HelpOutlineRounded } from "@mui/icons-material";
import Draggable from "react-draggable"; // Import Draggable
import ChatWindow from "./ChatWindow";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { updateSupportWindowState } from "redux/dashboard/reducers/configSlice";
import {
  findAssistantByCandidate,
  findAssistantByCompany,
} from "api/dashboard/assistant";

interface IAssistant {
  _id?: String;
  id?: String;
  description?: String;
  file_ids?: Array<String>;
  instructions?: String;
  model?: String;
  fileNames?: [];
  name?: String;
  tools?: [
    {
      type: String;
    },
    {
      type: String;
    },
  ];
  createdAt?: String;
  updatedAt?: String;
}

type Props = {};

const SupportWidget = (props: Props) => {
  const dispatch = useAppDispatch();

  const open: any = useAppSelector(
    (state) => state?.configReducer?.config?.supportChatWindow?.open,
  );

  const user = useAppSelector((state) => state.userReducer?.user);

  const [sender, setSender] = useState<String>("");
  const [senderToken, setSenderToken] = useState<String>("");
  const [account, setAccount] = useState<String>("");
  const [assistant, setAssistant] = useState<IAssistant>();

  console.log("SupportWindow: assistant: ", assistant);

  // findAssistantByCompany

  const getAssistantData = async () => {
    try {
      if (user?.loggedUser?.user === "user") {
        setAccount(user?.company?._id);
        setSender(user?.loggedUser?.userKey);
        setSenderToken(user?.token);
        if (user?.company?._id) {
          const result = await findAssistantByCompany(user?.company?._id);
          setAssistant(result?.data);
        }
      } else if (user?.loggedUser?.user === "candidate") {
        setAccount(user?.loggedUser?.userKey);
        setSender(user?.loggedUser?.userKey);
        setSenderToken(user?.token);
        if (user?.loggedUser?.userKey) {
          const result = await findAssistantByCandidate(
            user?.loggedUser?.userKey,
          );
          setAssistant(result?.data);
        }
      }
    } catch (error) {
      console.log("Failed to getAssistantData, Error: ", error);
    }
  };

  useEffect(() => {
    if (user) {
      getAssistantData();
    }
    return () => {};
  }, [user]);

  const onClose = (value: boolean) => {
    dispatch(updateSupportWindowState(false));
  };

  return (
    <>
      <ChatWindow
        assistant={assistant}
        sender={sender}
        senderToken={senderToken}
        account={account}
        open={open}
        onClose={onClose}
      />
    </>
  );
};

export default SupportWidget;
