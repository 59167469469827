import React, { useState, useEffect } from "react";
import { Badge, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import AssignmentIndRoundedIcon from "@mui/icons-material/AssignmentIndRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import TaskRoundedIcon from "@mui/icons-material/TaskRounded";
import CreditScoreRoundedIcon from "@mui/icons-material/CreditScoreRounded";
import NoteRoundedIcon from "@mui/icons-material/NoteRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import PersonRemoveRoundedIcon from "@mui/icons-material/PersonRemoveRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import ChangeCircleRoundedIcon from "@mui/icons-material/ChangeCircleRounded";
import MoveUpRoundedIcon from '@mui/icons-material/MoveUpRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';


const useStyles = makeStyles(() => ({
  notifMailIcon: {
    backgroundColor: "#ef6c00",
    borderRadius: "50%",
    padding: "2px",
  },
  notifRegisterIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifRejectIcon: {
    backgroundColor: "#e53935",
    borderRadius: "50%",
    padding: "2px",
  },
  notifCompletedIcon: {
    backgroundColor: "#00bfa5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifAssignmentIcon: {
    backgroundColor: "#00bfa5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifResumeIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifOfferIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifReferenceIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifNoteIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifScoreIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifMeetIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifTaskIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifSmsIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifSendMailIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifAddCandidateIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifArchiveIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifDeleteIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifStatusChangeIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifMoveIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifCopyIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
}));

const RenderBadge = ({ badgeType, icon }) => {
  const classes = useStyles();
  const [element, setElement] = useState();
  useEffect(() => {
    switch (badgeType) {
      case "incoming_mail":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifMailIcon}>
                <MailOutlineRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "register":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div className={classes.notifRegisterIcon}>
                <HowToRegRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "rejected":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifRejectIcon}>
                <CloseRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "completed":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifCompletedIcon}>
                <EmojiEventsRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "assignment":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifAssignmentIcon}>
                <AssignmentIndRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "resume":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifResumeIcon}>
                <UploadFileRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "document":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifResumeIcon}>
                <UploadFileRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "offer":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifOfferIcon}>
                <EmailRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "privateNote":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifNoteIcon}>
                <NoteRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "score":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifScoreIcon}>
                <CreditScoreRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "meet":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifMeetIcon}>
                <GroupsRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "task":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifTaskIcon}>
                <TaskRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "sms":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifSmsIcon}>
                <SmsRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "sendMail":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifSendMailIcon}>
                <MarkEmailReadRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "addCandidate":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifAddCandidateIcon}>
                <PersonAddRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "archive":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifArchiveIcon}>
                <PersonRemoveRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "delete":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifDeleteIcon}>
                <DeleteOutlineRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
      case "statusChange":
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <div className={classes.notifStatusChangeIcon}>
                <ChangeCircleRoundedIcon
                  sx={{ fontSize: "14px", color: "#fff" }}
                />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
        case "move":
          setElement(
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <div className={classes.notifMove}>
                  <MoveUpRoundedIcon
                    sx={{ fontSize: "14px", color: "#fff" }}
                  />{" "}
                </div>
              }
            >
              <Avatar sx={{ width: 50, height: 50 }} src={icon} />
            </Badge>
          );
          break;
          case "copy":
            setElement(
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <div className={classes.notifCopyIcon}>
                    <ContentCopyRoundedIcon
                      sx={{ fontSize: "14px", color: "#fff" }}
                    />{" "}
                  </div>
                }
              >
                <Avatar sx={{ width: 50, height: 50 }} src={icon} />
              </Badge>
            );
            break;

      default:
        setElement(
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            badgeContent={
              <div className={classes.notifRegisterIcon}>
                <HowToRegRoundedIcon sx={{ fontSize: "14px", color: "#fff" }} />{" "}
              </div>
            }
          >
            <Avatar sx={{ width: 50, height: 50 }} src={icon} />
          </Badge>
        );
        break;
    }
    return () => {};
  }, []);

  return <>{element}</>;
};

export default RenderBadge;
