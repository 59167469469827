import React from "react";
import { Canvas } from "@react-three/fiber";
import { EffectComposer, FXAA } from "@react-three/postprocessing";

import { Experience } from "./Experience";

type Props = {
  camera?: {
    orbitalTarget: any;
    cameraPosition: any;
  };
  avatar?: {
    url?: string;
    position?: {
      x: number;
      y: number;
      z: number;
    };
    rotate?: {
      x: number;
      y: number;
      z: number;
    };
    scale?: number;
    speechVoice?: string;
    speechText?: string;
    welcomeText?: string;
    background?: {
      id?: string;
      title?: string;
      author?: {
        name?: string;
        url?: string;
      };
      file?: string;
      url?: string;
    };
    room?: {
      position?: {
        x: number;
        y: number;
        z: number;
      };
      rotate?: {
        x: number;
        y: number;
        z: number;
      };
      scale?: number;
    };
  };
  viewMode: boolean;
  style?: any;
  onReady?: (value: boolean) => void;
  hidden?: boolean;
};

// camera={{ position: [0, 0, 5], fov: 42 }}

const index = React.memo((props: Props) => {
  const style = props?.style || {};

  return (
    <Canvas
      shadows
      style={{ ...style, display: props?.hidden ? "none" : "block" }}
    >
      <Experience {...props} />
    </Canvas>
  );
});

export default index;
