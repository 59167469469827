import axios from "utils/axios";

/**
 * Parse candidate resume data
 * @param {String} candidate : candidate ID
 * @param {Object} formData : resume file as form data
 * @returns Promise object
 */
export const parseResume = (candidate, formData) =>

  new Promise((resolve, reject) => {
    axios
      .post(
        `/cv/parser/parse/resume/candidate/${candidate}`,
        formData
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });


export const getResumeData = (candidate) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/cv/parser/resume/get/list/candidate/${candidate}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });


export const updateResume = (candidate, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/cv/parser/resume/update/candidate/${candidate}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

