import React from "react";

function ChatIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 12}
      height={size || 12}
      fill="#007bff"
      viewBox="0 0 256 256"
    >
      <path d="M132 23.992a100.113 100.113 0 00-100 100v84.334a15.684 15.684 0 0015.667 15.666H132a100 100 0 000-200zM83.998 139.993a12 12 0 1112-12 12 12 0 01-12 12zm48 0a12 12 0 1112-12 12 12 0 01-12 12zm48 0a12 12 0 1112-12 12 12 0 01-12 12z"></path>
    </svg>
  );
}

export default ChatIcon;
