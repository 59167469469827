import React from "react";
import {
  ThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from "@mui/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

import Router from "./router";

import "./assets/space/css/main.css";
import "./App.css";
import "./assets/dashboard/css/animate.min.css";
import "./assets/dashboard/css/light-dashboard.css";
// import "assets/dashboard/css/scroll-bar.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { darkTheme, lightTheme } from "./theme";

import { loadUser } from "redux/store";
import { loggedUser } from "redux/dashboard/reducers/userSlice";

// !important: Don't remove below import statement, it creates an user login broadcast channel
import broadcastChannel from "utils/BroadcastChannel";
import { SpaceProvider } from "context/spaceContext";

const generateClassName = createGenerateClassName({
  seed: `recode-${uuidv4()}`,
  productionPrefix: `recode-${uuidv4()}`,
});

function App() {
  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeProvider theme={darkTheme}>
        <SpaceProvider>
          <div className="App">
            <Router />
          </div>
        </SpaceProvider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
