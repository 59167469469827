import React, { useState, useEffect, useRef, useCallback } from "react";
import { Send } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { DateTime } from "luxon";
import Draggable from "react-draggable";
import "./support-chat-window-styles.css";
import { primaryIconButton } from "theme/elements";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import ChatComponent from "../ChatComponent";
import ChatIcon from "./ChatIcon";
import { createSupportChatMessage } from "api/supportChat";
import { SERVER_URL } from "constants/production";
import {
  disconnectSocket,
  initiateSupportChatSocket,
  sendEvent,
  subscribeToEvent,
} from "utils/socket.io";

interface IMessage {
  _id?: String | Number;
  sender: String;
  recipient: String;
  message_type: String;
  message: {
    text: String;
    send_by: String;
  };
  createdAt?: String;
}

interface IAssistant {
  _id?: String;
  id?: String;
  description?: String;
  file_ids?: Array<String>;
  instructions?: String;
  model?: String;
  fileNames?: [];
  name?: String;
  tools?: [
    {
      type: String;
    },
    {
      type: String;
    },
  ];
  createdAt?: String;
  updatedAt?: String;
}

type Props = {
  assistant: IAssistant;
  sender?: String;
  senderToken?: String;
  account?: String;
  open: boolean;
  onClose: (value: boolean) => void;
};

const firstMessages = [
  {
    _id: 1,
    sender: "asst_34235236246rr34",
    recipient: "me",
    message_type: "message",
    message: {
      text: "Hello",
      send_by: "asst_34235236246rr34",
    },
    createdAt: DateTime.now().toString(),
  },
  {
    _id: 2,
    sender: "asst_34235236246rr34",
    recipient: "me",
    message_type: "message",
    message: {
      text: "How can I assist you today?",
      send_by: "asst_34235236246rr34",
    },
    createdAt: DateTime.now().toString(),
  },
];

const ChatWindow: React.FC<Props> = ({
  assistant,
  sender,
  senderToken,
  account,
  open,
  onClose,
}) => {
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isChatFetching, setIsChatFetching] = useState(false);
  const ps = useRef(null);

  useEffect(() => {
    if (senderToken) {
      const room = sender;

      initiateSupportChatSocket(SERVER_URL, senderToken, room);

      subscribeToEvent("support_chat_user_joined", (response: IMessage) => {
        console.log("support chat connected, response: ", response);
      });

      subscribeToEvent("support_chat_typing", (response: any) => {
        console.log("event: support_chat_typing: ", response);
      });

      subscribeToEvent("support_chat", (response: IMessage) => {
        console.log("event: support_chat: ", response);
        setMessages((prevState) => [...prevState, response]);
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [senderToken]);

  useEffect(() => {
    if (open) {
      let delay = 0;
      firstMessages.forEach((msg, index) => {
        setTimeout(() => {
          if (!messages.includes(msg)) {
            setMessages((prevMessages) => [...prevMessages, msg]);
          }
        }, delay);
        delay += 1000; // 1-second delay between messages
      });
    }
    setIsWindowOpen(open);
  }, [open]);

  const handleWindowClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (message && message.trim().length) {
      const messageData = {
        sender,
        recipient: assistant?.id,
        message_type: "supportMessage",
        message: { text: message, send_by: account },
        account,
        createdAt: DateTime.now().toString(),
      };
      setMessages((prevMessages) => [...prevMessages, messageData]);
      setMessage("");

      sendEvent("support_chat_message", messageData, senderToken);
      // await createSupportChatMessage(messageData);
    }
  };

  return (
    <Draggable>
      <div
        className="absolute bottom-14 right-1 z-50 w-[325px] flex-col rounded-2xl bg-white shadow-lg transition-all delay-75 ease-in dark:bg-gray-700"
        style={{ display: isWindowOpen ? "flex" : "none" }}
        id="support-chat-window"
      >
        <div
          className="mb-2 flex items-center justify-between rounded-t-2xl bg-white p-3 shadow-sm dark:bg-gray-700"
          id="chat-header"
        >
          <div
            className="flex flex-1 flex-row items-center"
            id="chat-header-wrapper"
          >
            <ChatIcon size={24} />
            <div className="text-md font-poppins font-bold text-black dark:text-white">
              Support AI Assistant
            </div>
          </div>

          <button
            className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border-none bg-gray-700/[0.4] text-center text-red-500 shadow-sm outline-none hover:bg-red-500 hover:text-white hover:shadow-md hover:shadow-red-500"
            onClick={handleWindowClose}
          >
            <CloseRoundedIcon />
          </button>
        </div>
        {isChatFetching && (
          <div
            className="flex w-full flex-row items-center justify-center bg-white/[0.25] font-poppins text-xs text-blue-500"
            id="chat-fetching"
          >
            Fetching <div className="loader"></div>
          </div>
        )}

        <div
          className="flex h-[320px] max-h-[320px] flex-col px-1"
          id="chat-body"
        >
          <PerfectScrollbar
            className="flex h-[320px] max-h-[320px] flex-col px-1"
            containerRef={(el) => (ps.current = el)}
          >
            <ChatComponent
              messageList={messages}
              sender={"asst_34235236246rr34"}
            />
          </PerfectScrollbar>
        </div>

        <div
          className="flex items-center justify-between rounded-b-2xl bg-white p-2 dark:bg-gray-700"
          id="chat-footer"
        >
          <input
            id="name-input"
            className="m-1 ml-2 h-12 w-full rounded-md border-none bg-white p-1 px-2 outline-none dark:bg-gray-800 dark:text-white"
            placeholder="Type a message"
            value={message}
            onKeyPress={handleKeyPress}
            onChange={handleMessageChange}
            type="text"
            name="message"
          />

          <button
            className="delay-10 mx-1 flex cursor-pointer items-center justify-center rounded-full border-none bg-blue-500 p-2 text-lg text-white outline-none transition-all ease-in hover:shadow-md"
            onClick={handleSendMessage}
          >
            <Send className="text-inherit" />
          </button>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatWindow;
