import axios from "utils/axios";

export const getNotificationById = (id, limit = 0, page = 0) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/notification/find/${id}?limit=${limit}&page=${page}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getEmailNotificationList = (id, type, limit = 0, page = 0) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/notification/list/${id}/type/${type}?limit=${limit}&page=${page}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getNotificationByType = (type, limit = 0, page = 0) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/notification/list/data/${type}?limit=${limit}&page=${page}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUnreadNotificationCount = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`/notification/unread/count`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getUnreadEmailNotificationCount = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`/notification/unread/email/count`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateReadStatus = (type, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/notification/update/type/${type}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const updateNotificationStatus = (id, status) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/notification/update`, {
        _id: id,
        status,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateOne = (id, status) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/notification/status/update/${id}`, { status })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

// Get list based on candidate and job
export const getNotificationlist = (candidateId, jobId, limit = 0, page = 0) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/notification/list/candidate/${candidateId}/job/${jobId}/?limit=${limit}&page=${page}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Get list based on candidateId
export const getCandidateNotificationlist = (
  candidateId,
  limit = 0,
  page = 0
) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/notification/list/candidate/${candidateId}/?limit=${limit}&page=${page}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Get email list based on candidateId
export const getCandidateEmailList = (candidateId, limit, page) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/candidate/email/list/external/${candidateId}/?limit=${limit}&page=${page}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Get notification list based on candidateId
export const getCandidateNotificationList = (candidateId, limit, page) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/candidate/email/list/internal/${candidateId}/?limit=${limit}&page=${page}`
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
