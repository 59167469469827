import React from "react";
import "./index.css";

const NotificationBadge = ({ color, style }) => {
  return (
    <div
      // className="pulse" // uncomment this to see the pulse effect
      style={{
        width: 8,
        height: 8,
        backgroundColor: color || "#ffc400",
        borderRadius: 20,
        position: "relative",
        top: "-20px",
        left: "-10px",
        ...style,
      }}
    />
  );
};

export default NotificationBadge;
