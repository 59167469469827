import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface DepartmentState {
  cs_department: {
    _id: any;
    id: number;
    name: string;
    manager: string;
  };
}

const initialState: DepartmentState = {
  cs_department: {
    id: 0,
    name: "",
    manager: "",
  },
};

export const departmentSlice = createSlice({
  name: "cs_department",
  initialState,
  reducers: {
    addDepartment: (state, action: PayloadAction<DepartmentState["cs_department"]>) => {
      state.cs_department = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;