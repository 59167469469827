import React, { useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

// snack bar icons
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import { fontFamily } from "@mui/system";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    top: 20,
    right: 20,
    padding: 10,
    borderRadius: 10,
    boxShadow:
      "0 0.9px 1.8px rgba(0, 0, 0, 0.006), 0 2.2px 4.4px rgba(0, 0, 0, 0.009), 0 4.4px 9px rgba(0, 0, 0, 0.011), 0 9.1px 18.6px rgba(0, 0, 0, 0.014), 0 25px 51px rgba(0, 0, 0, 0.02)",
    zIndex: 99999,
  },
}));

type Props = {
  open: Boolean;
  status: number;
  message: string;
  style?: Object;
  size?: number | string;
};

const textStyle = {
  marginLeft: 10,
  paddingLeft: 10,
  fontFamily: "'Poppins', 'Inter', sans-serif",
};

const Snackbar = ({ open, status, message, style, size }: Props) => {
  // snack bar

  // render snack bar icon based on status
  const renderSnackBarIcon = () => {
    switch (status) {
      case 0:
        return <CircularProgress size={size} sx={{ color: "#007bff" }} />;
      case 1:
        return (
          <CheckCircleRoundedIcon sx={{ color: "#00e676", fontSize: size }} />
        );
      case 2:
        return (
          <ErrorOutlineRoundedIcon sx={{ color: "#ffab00", fontSize: size }} />
        );

      default:
        return <CircularProgress size={size} sx={{ color: "#007bff" }} />;
    }
  };

  const classes = useStyles();

  return (
    <div
      className={classes.container}
      style={{ ...style, display: open ? "flex" : "none" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderSnackBarIcon()}
        <Typography sx={textStyle} component="div">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default Snackbar;
