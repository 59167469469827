import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface InterviewState {
  cs_interview: {
    attempt: string;
  };
}

const initialState: InterviewState = {
  cs_interview: {
    attempt: "",
  },
};

export const interviewSlice = createSlice({
  name: "cs_interview",
  initialState,
  reducers: {
    addInterview: (state, action: PayloadAction<InterviewState>) => {
      state.cs_interview = action.payload.cs_interview;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addInterview } = interviewSlice.actions;

export default interviewSlice.reducer;
