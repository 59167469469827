import React from "react";
import { Button } from "@mui/material";

import "./expire-overlay-styles.css";
import { primaryFilledButton } from "theme/elements";

import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

interface SessionExpiredOverlayProps {
  onGoBack: () => void;
}

const SessionExpiredOverlay: React.FC<SessionExpiredOverlayProps> = ({
  onGoBack,
}) => {
  return (
    <div className="session-expire-overlay">
      <div className="session-expire-container">
        <div className="session-expire-title">Session expired</div>
        <div className="session-expire-description">
          Your session has expired. Please log in again.
        </div>
        <Button
          sx={primaryFilledButton}
          id="session-expire-button"
          onClick={onGoBack}
          style={{ fontSize: 18, paddingLeft: 15 }}
        >
          Go Back <NavigateNextRoundedIcon />
        </Button>
      </div>
    </div>
  );
};

export default SessionExpiredOverlay;
