import React from "react";
import { Button } from "@mui/material";

import { signInWithGithub } from "auth";

import githubLightIcon from "./assets/github-light.svg";
import githubDarkIcon from "./assets/github-dark.svg";

interface UserData {
  name?: string;
  accessToken?: string;
  email?: string;
  phone?: string;
  profilePhoto?: string;
  uid?: string;
}

interface GithubAuthProps {
  mode: string;
  onSignIn: (userData: UserData) => void;
  onError: (error: Error) => void;
  style: unknown;
  children: any;
}

const defaultStyle =
  "text-black font-poppins px-5 py-10 font-bold bg-white hover:shadow-md transition duration-300 ease-in-out";

const darkStyle =
  "text-white font-poppins px-5 py-10 font-bold bg-black hover:shadow-md transition duration-300 ease-in-out";

const GithubAuth = ({
  mode = "light",
  onSignIn,
  onError,
  children,
}: GithubAuthProps): JSX.Element => {
  const handleGithubSignUp = () => {
    signInWithGithub()
      .then((response) => {
        if (response?.user) {
          const { user } = response;
          const userData = {
            name: user?.displayName,
            accessToken: user?.accessToken,
            email: user?.email,
            phone: user?.phoneNumber,
            profilePhoto: user?.photoURL,
            uid: user?.uid,
          };
          onSignIn(userData);
        }
      })
      .catch((error) => {
        console.log("github sign in failed", error);
        onError(error);
      });
  };
  return (
    <button
      onClick={handleGithubSignUp}
      className="mx-3 flex h-10  items-center justify-center rounded-md border-none bg-white px-3 font-poppins font-bold text-black shadow-sm outline-none transition duration-100 ease-in-out hover:shadow-md"
    >
      <img
        src={mode === "light" ? githubDarkIcon : githubLightIcon}
        role="image"
        className="mx-1 h-6 w-6"
      />
      {children}
    </button>
  );
};

export default GithubAuth;
