import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from "../redux/store"; // Adjust the path as necessary
import { SERVER_URL } from "../constants/production"; // Adjust the path as necessary

const instance: AxiosInstance = axios.create({
  baseURL: SERVER_URL,
  timeout: 100 * 1000, // 100 seconds timeout
  headers: {
    // 'Connection': 'keep-alive', // Enable HTTP keep-alive
  },
});

// Setting the 'Accept-Encoding' header
// instance.defaults.headers.common["Accept-Encoding"] = "gzip, deflate, br";

// Request interceptor for setting common headers
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = store.getState()?.userReducer?.user?.token;
    if (token) {
      // config.headers.Authorization = token;
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor for handling common errors
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  },
);

export default instance;
