import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
export interface SetupaiState {
  groupData: {
    groupId: string;
    groupName: string;
    rule: any[];
  }[];
}

const initialState: SetupaiState = {
  groupData: [
    {
      groupId: uuid(),
      groupName: "FAQ",
      rule: [],
    },
  ],
};

export const setupaiSlice = createSlice({
  name: "groupData",
  initialState,
  reducers: {
    updateAiGroup: (state, action: PayloadAction<any>) => {
      state.groupData = action.payload;
    },
    deleteAiGroup: (state, action: PayloadAction<{ groupId: string }>) => {
      state.groupData = state.groupData.filter((item) => {
        return item.groupId !== action.payload.groupId;
      });
    },
    updateAIGroupName: (
      state,
      action: PayloadAction<{ groupId: string; groupName: string }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        group.groupName = action.payload.groupName;
      }
    },
    createAiGroupRule: (
      state,
      action: PayloadAction<{ groupId: string; rule: any[] }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        group.rule = action.payload.rule;
      }
    },
    updateAiGroupRule: (
      state,
      action: PayloadAction<{ groupId: string; rule: any[] }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        group.rule.push(...action.payload.rule);
      }
    },
    deleteAiGroupRule: (
      state,
      action: PayloadAction<{ groupId: string; ruleId: string }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        group.rule = group.rule.filter((_rule) => {
          return _rule.ruleId !== action.payload.ruleId;
        });
      }
    },

    updateAiGroupRuleKeyword: (
      state,
      action: PayloadAction<{
        groupId: string;
        ruleId: string;
        keyword: string;
      }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        const rule = group.rule.find(
          (item) => item.ruleId === action.payload.ruleId
        );
        if (rule) {
          rule.trainingPhrase = action.payload.keyword;
        }
      }
    },

    createAiGroupRuleResponceText: (
      state,
      action: PayloadAction<{
        groupId: string;
        ruleId: string;
        response: string;
      }>
    ) => {
      const group = state.groupData.find(
        (item) => item.groupId === action.payload.groupId
      );
      if (group) {
        const rule = group.rule.find(
          (_rule) => _rule.ruleId === action.payload.ruleId
        );
        if (rule) {
          rule.response.data.text.push(action.payload.response);
        }
      }
    },

    updateAiRuleGroupResponceText: (
      state,
      action: PayloadAction<{
        groupId: string;
        groupName: string;
        ruleName: string;
        responseId: string;
        text: string;
      }>
    ) => {
      const { groupId, groupName, ruleName, responseId, text } = action.payload;
      const group = state.groupData.find((item) => item.groupId === groupId);
      const rule = group?.rule.find((_rule) => _rule.ruleName === ruleName);
      if (rule) {
        const textObj = rule.response.data.text?.find(
          (_text) => _text.id === responseId
        );
        if (textObj) {
          textObj.reply = text;
        }
      }
    },

    deleteAiGroupResponceText: (
      state,
      action: PayloadAction<{
        groupId: string;
        groupName: string;
        ruleName: string;
        textId: string;
      }>
    ) => {
      const { groupId, groupName, ruleName, textId } = action.payload;
      const group = state.groupData.find((item) => item.groupId === groupId);
      const rule = group?.rule.find((_rule) => _rule.ruleName === ruleName);
      if (rule) {
        rule.response.data.text = rule.response.data.text?.filter((_text) => {
          return _text.id !== textId;
        });
      }
    },

    createAiGroupRuleResponceBlock: (
      state,
      action: PayloadAction<{
        groupId: string;
        groupName: string;
        ruleName: string;
        response: { type: string; text?: string };
      }>
    ) => {
      const { groupId, groupName, ruleName, response } = action.payload;
      const group = state.groupData.find((item) => item.groupId === groupId);
      const rule = group?.rule.find((_rule) => _rule.ruleName === ruleName);
      if (rule && rule.response.type !== "Text") {
        rule.response.type = response.type;
        if (rule.response.data?.text) {
          rule.response.data.text = response.text;
        } else {
          rule.response.data = { text: response.text };
        }
      } else {
        group?.rule.push({
          ruleName,
          response: {
            type: response.type,
            data: {
              text: response.text,
            },
          },
        });
      }
    },

    updateAiGroupRuleResponceBlock: (
      state,
      action: PayloadAction<{ groupId: string; ruleId: string; blockData: any }>
    ) => {
      const { groupId, ruleId, blockData } = action.payload;
      const group = state.groupData.find((item) => item.groupId === groupId);
      const rule = group?.rule.find((_rule) => _rule.ruleId === ruleId);
      if (rule) {
        rule.response.data = blockData;
      }
    },
    deleteAiGroupRuleResponceBlock: (
      state,
      action: PayloadAction<{ groupId: string; ruleId: string }>
    ) => {
      const { groupId, ruleId } = action.payload;
      const groupIndex = state.groupData.findIndex(
        (group) => group.groupId === groupId
      );
      if (groupIndex !== -1) {
        const ruleIndex = state.groupData[groupIndex].rule.findIndex(
          (rule) => rule.ruleId === ruleId
        );
        if (ruleIndex !== -1) {
          state.groupData[groupIndex].rule[ruleIndex].response.type = "";
          state.groupData[groupIndex].rule[ruleIndex].response.data = {};
        }
      }
    },
    updateIntent: (
      state,
      action: PayloadAction<{
        groupId: string;
        ruleId: string;
        intentId: string;
      }>
    ) => {
      const { groupId, ruleId, intentId } = action.payload;
      const group = state.groupData.find((item) => item.groupId === groupId);
      const rule = group?.rule.find((_rule) => _rule.ruleId === ruleId);
      if (rule) {
        rule.intentId = intentId;
      }
    },
  },
});

export const {
  updateAiGroup,
  deleteAiGroup,
  updateAIGroupName,
  updateIntent,
  deleteAiGroupRuleResponceBlock,
  updateAiGroupRuleResponceBlock,
  deleteAiGroupResponceText,
  createAiGroupRuleResponceBlock,
  createAiGroupRule,
  updateAiGroupRule,
  deleteAiGroupRule,
  updateAiGroupRuleKeyword,
  createAiGroupRuleResponceText,
  updateAiRuleGroupResponceText,
} = setupaiSlice.actions;

export default setupaiSlice.reducer;
