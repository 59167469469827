import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CandidateState {
  cs_candidate: Record<string, unknown>; // can be any object with string keys and unknown values
}

const initialState: CandidateState = {
  cs_candidate: {},
};

export const cs_candidateSlice = createSlice({
  name: "cs_candidate",
  initialState,
  reducers: {
    cs_addCandidate: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.cs_candidate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { cs_addCandidate } = cs_candidateSlice.actions;

export default cs_candidateSlice.reducer;
