import React, { useState, useEffect } from "react";
import { LinearProgress } from "@mui/material";

interface PasswordStrengthMeterProps {
  password: string;
}

const calculatePasswordStrength = (password: string): number => {
  let strength = 0;
  if (password.match(/[a-z]+/)) {
    strength += 1;
  }
  if (password.match(/[A-Z]+/)) {
    strength += 1;
  }
  if (password.match(/[0-9]+/)) {
    strength += 1;
  }
  if (
    password.match(/[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]+/) &&
    password.trim().length >= 6
  ) {
    strength += 1;
  }
  return strength;
};

const getPasswordStrengthColor = (strength: number): string => {
  switch (strength) {
    case 0:
      return "#ff1744";
    case 1:
      return "#ff9100";
    case 2:
      return "#ffea00";
    case 3:
      return "#00e676";
    case 4:
      return "#00c853";
    default:
      return "#ff1744";
  }
};

const PasswordStrengthMeter: React.FC<PasswordStrengthMeterProps> = ({
  password,
}) => {
  const [message, setMessage] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);

  useEffect(() => {
    const strength = calculatePasswordStrength(password);
    setPasswordStrength(strength);

    if (strength === 0) {
      setMessage("Strength: Less");
    } else if (strength === 1) {
      setMessage("Strength: Low");
    } else if (strength === 2) {
      setMessage("Strength: Medium");
    } else if (strength === 3) {
      setMessage("Strength: High");
    } else if (strength === 4) {
      setMessage("Strength: Strong");
    }
  }, [password]);

  const passwordStrengthPercentage = (passwordStrength / 4) * 100;
  const passwordStrengthColor = getPasswordStrengthColor(passwordStrength);

  return (
    <div style={{ padding: "0px 20px" }}>
      <LinearProgress
        variant="determinate"
        value={passwordStrengthPercentage}
        sx={{
          "& .MuiLinearProgress-root": {
            borderRadius: "46px",
          },
          "& .MuiLinearProgress-bar": {
            backgroundColor: passwordStrengthColor,
          },
        }}
      />
      <div style={{ textAlign: "center" }}>
        {message && (
          <div
            style={{
              fontSize: "12px",
              fontFamily: "'Poppins', 'Inter', sans-serif",
              color: "#bdbdbd",
            }}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
