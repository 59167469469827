import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ScreeningState {
  screening: {
    jobId: string;
    questions: any[];
    responses: any[];
  };
}

const initialState: ScreeningState = {
  screening: {
    jobId: "",
    questions: [],
    responses: [],
  },
};

function updateScreeningQuestion(questions: any[], action: { payload: any; type?: string; }) {
  const questionIndex = questions.findIndex((question: { id: any; }) => question.id === action.payload.id);
  if (questionIndex !== -1) {
    return questions.map((question: { id: any; }, index: any) => {
      if (question.id === action.payload.id) {
        return action.payload;
      }
      return question;
    });
  } else {
    return [...questions, action.payload];
  }
}

function updateScreeningResponse(responses: any[], action: { payload: any; type?: string; }) {
  const responseIndex = responses.findIndex((response: { id: any; }) => response.id === action.payload.id);
  if (responseIndex !== -1) {
    return responses.map((response: { id: any; }, index: any) => {
      if (response.id === action.payload.id) {
        return action.payload;
      }
      return response;
    });
  } else {
    return [...responses, action.payload];
  }
}

export const screeningSlice = createSlice({
  name: "screening",
  initialState,
  reducers: {
    addQuestion: (state, action: PayloadAction<any>) => {
      state.screening = {
        ...state.screening,
        questions: updateScreeningQuestion(state.screening.questions, action),
      };
    },
    editQuestion: (state, action: PayloadAction<any>) => {
      state.screening = {
        ...state.screening,
        responses: updateScreeningResponse(state.screening.responses, action),
      };
    },
    clearQuestions: (state, action: PayloadAction<any>) => {
      state.screening = {
        jobId: "",
        questions: [],
        responses: [],
      };
    },
  },
});

export const { addQuestion, editQuestion, clearQuestions } = screeningSlice.actions;

export default screeningSlice.reducer;
