import React, { useState, useEffect } from "react";
import { Popover, Typography, Button } from "@mui/material";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { Link } from "react-router-dom";
import moment from "moment";
import { Divider } from "@mui/material";

import RenderBadge from "./RenderBadge";

import {
  updateNotificationStatus,
  updateOne,
} from "api/dashboard/notification";
import { removeNotificationPath } from "redux/dashboard/reducers/notificationSlice";

const useStyles = makeStyles(() => ({
  TopBar: {
    display: "flex",

    // background: "#fff",
    borderRadius: "0px 0px 7px 7px",
    marginLeft: 18,
    marginRight: 16,
    boxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    webkitBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    mozBoxShadow: "0px 0px 5px 0px rgba(191,191,191,0.75)",
    // justifyContent: "space-between",
    alignItems: "center",
    minHeight: "10vh",
    margin: "10px",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    justifyItems: "flex-end",
  },
  menuStyle: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    width: "350px",
    marginTop: 20,
  },
  notificationWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "8px",
    paddingRight: "15px",
    paddingLeft: "15px",
    padding: "15px",
    width: "400px",
    maxHeight: "450px",
  },
  notificationHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    color: "#616161",
    padding: "10px",
  },
  notificationTitle: {
    fontSize: "22px",
    paddingLeft: "10px",
    paddingTop: "5px",
  },
  notificationFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "14px",
    color: "#9e9e9e",
    paddingLeft: "10px",
  },
  showAllButton: {
    color: "#007bff",
    cursor: "pointer",
    padding: "2px 5px",
    borderRadius: 5,
    "&:hover": {
      color: "#fff",
      backgroundColor: "#007bff",
    },
  },
  markAllRead: {
    color: "#007bff",
    textDecoration: "underline",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    alignSelf: "flex-end",
  },
  notificationLink: {
    textDecoration: "none !important",
    "&:hover": {
      textDecoration: "none !important",
    },
  },
  notificationBody: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    paddingRight: "10px",
    paddingLeft: "10px",
    borderRadius: "8px",
    alignItems: "center",
    color: "#212121",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  notificationContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingTop: "10px",
    paddingBottom: "5px",
    fontSize: "15px",
  },
  notificationTime: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    fontSize: "12px",
    color: "#9e9e9e",
  },
  notificationAvatar: {
    padding: "8px",
  },
  notifRegisterIcon: {
    backgroundColor: "#039be5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifRejectIcon: {
    backgroundColor: "#e53935",
    borderRadius: "50%",
    padding: "2px",
  },
  notifCompletedIcon: {
    backgroundColor: "#00bfa5",
    borderRadius: "50%",
    padding: "2px",
  },
  notifMailIcon: {
    backgroundColor: "#ef6c00",
    borderRadius: "50%",
    padding: "2px",
  },
  chatTimeLineMarkerContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  chatTimeLineMarkerWrapper: {
    padding: "5px 10px",
    margin: "2px 5px",
    background: "#e1f5fe",
    color: "#03a9f4",
    fontSize: 12,
    fontFamily: "poppins, Inter, sans-serif",
    fontWeight: "400",
    borderRadius: 20,
  },
}));

const EmailNotificationList = ({
  popoverId,
  notifAnchorEl,
  notifOpen,
  notifClose,
}) => {
  const classes = useStyles();
  const routerHistory = useHistory();
  const dispatch = useAppDispatch();

  const notificationData = useAppSelector(
    (state) => state?.emailNotificationReducer?.notifications,
  );

  // useEffect(() => {
  //   if (notifOpen && notificationData && notificationData.length > 0) {
  //     const emailNotificationList = notificationData
  //       .map((notification) => {
  //         if (notification.status === "unread") {
  //           return {
  //             _id: notification._id,
  //           };
  //         }
  //       })

  //     if (emailNotificationList.length > 0) {
  //       updateNotificationStatus(emailNotificationList, "read")
  //         .then((response) => {
  //           console.log(
  //             "email notification status updated, response",
  //             response
  //           );
  //         })
  //         .catch((error) => {
  //           console.log("Failed to update notification status", error);
  //         });
  //     }
  //   }
  // }, [notifOpen]);

  const handleNotificationClick = (item) => {
    const notificationId = item?._id;
    updateOne(notificationId, "read")
      .then((response) => {
      })
      .catch((error) => {
        console.error("Failed to update notification status", error);
      });
    dispatch(removeNotificationPath(item?.body?.data?.link));
    routerHistory.push(item?.body?.data?.link || "/");
    notifClose();
  };

  return (
    <Popover
      id={popoverId}
      open={notifOpen}
      anchorEl={notifAnchorEl}
      onClose={notifClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        elevation: 0,
        borderRadius: 15,
        sx: {
          borderRadius: 2,
          overflow: "visible",
          backgroundColor: "#fff",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.22))",
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 6,
            right: 5,
            width: 25,
            height: 12,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            borderRadius: "2px",
            zIndex: 0,
          },
        },
      }}
    >
      <div className={classes.notificationWrapper}>
        <div className={classes.notificationHeader}>
          <div className={classes.notificationTitle}>Notifications</div>
          {notificationData && notificationData.length ? (
            <div
              data-testid="show-all-emails"
              className={classes.showAllButton}
              onClick={() => {
                notifClose();
                routerHistory.push("/dashboard/AllEmails");
              }}
            >
              Show all
            </div>
          ) : (
            ""
          )}
        </div>
        <PerfectScrollbar>
          {notificationData && notificationData.length ? (
            <Typography
              style={{ paddingLeft: "20px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              new
            </Typography>
          ) : (
            ""
          )}
          {/* {notificationData && notificationData.length ? (
            notificationData.map((item) => (
              <div key={item?._id || Math.random()}>
                <Link
                  to={item?.body?.data?.link}
                  className={classes.notificationLink}
                >
                  <div className={classes.notificationBody}>
                    <div className={classes.notificationAvatar}>
                      <RenderBadge
                        badgeType={item?.body?.data?.badgeType}
                        icon={item?.body?.data?.icon}
                      />
                    </div>
                    <div className={classes.notificationContent}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: 13,
                          fontFamily: "'Poppins','Inter', sans-serif !important",
                        }}
                      >
                        {item?.body?.notification?.title}
                      </Typography>
                      <Typography style={{ fontSize: 12, fontFamily: "inter" }}>
                        {item?.body?.notification?.body}
                      </Typography>
                      <div className={classes.notificationTime}>
                        {item?.createdAt
                          ? DateTime.fromISO(item?.createdAt).toFormat(
                              "hh:mm a"
                            )
                          : null}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )) */}
          {notificationData && notificationData.length ? (
            notificationData.map((item, index) => {
              return (
                <div key={item?._id || Math.random()}>
                  <div
                    style={{
                      justifyContent: "left",
                      alignItems: "left",
                      textAlign: "left",
                    }}
                  >
                    <div className={classes.chatTimeLineMarkerContainer}>
                      <Divider>
                        {moment(item?.createdAt).format("MM/DD/YYYY ") ===
                        moment().format("MM/DD/YYYY ") ? (
                          notificationData[index - 1]?.createdAt !==
                          undefined ? (
                            (moment(
                              notificationData[index - 1]?.createdAt
                            ).format("MM/DD/YYYY ") ===
                              moment().format("MM/DD/YYYY ")) ===
                            true ? (
                              ""
                            ) : (
                              <div
                                className={classes.chatTimeLineMarkerWrapper}
                              >
                                TODAY
                              </div>
                            )
                          ) : (
                            <div className={classes.chatTimeLineMarkerWrapper}>
                              TODAY
                            </div>
                          )
                        ) : moment(item?.createdAt).format("MMMM D YYYY") ===
                          moment(notificationData[index - 1]?.createdAt).format(
                            "MMMM D YYYY"
                          ) ? (
                          ""
                        ) : (
                          <div className={classes.chatTimeLineMarkerWrapper}>
                            {moment(item?.createdAt).format("MMMM D YYYY")}
                          </div>
                        )}
                      </Divider>
                    </div>

                    <Button
                      className={classes.notificationLink}
                      onClick={() => handleNotificationClick(item)}
                      // style={{
                      //   paddingLeft: "20px",
                      //   fontSize: 14,

                      // }}
                    >
                      <div className={classes.notificationBody}>
                        <div className={classes.notificationAvatar}>
                          <RenderBadge
                            badgeType={item?.body?.data?.badgeType}
                            icon={item?.body?.data?.icon}
                          />
                        </div>
                        <div className={classes.notificationContent}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 13,
                              fontFamily:
                                "'Poppins','Inter', sans-serif !important",
                              textTransform: "none",
                              textAlign: "left",
                            }}
                          >
                            {item?.body?.notification?.title}
                          </Typography>
                          <Typography
                            style={{
                              fontSize: 11,
                              fontFamily: "poppins",
                              textAlign: "start",
                              textTransform: "capitalize",
                            }}
                          >
                            {item?.body?.notification?.body}
                          </Typography>

                          {moment(item?.createdAt).format("MM/DD//YYYY") ===
                          moment().format("MM/DD//YYYY") ? (
                            <div
                              className={classes.notificationTime}
                              style={{ textTransform: "lowercase" }}
                            >
                              {moment(item?.createdAt).fromNow()}
                            </div>
                          ) : (
                            <div className={classes.notificationTime}>
                              {item?.createdAt
                                ? DateTime.fromISO(item?.createdAt).toFormat(
                                    "hh:mm a"
                                  )
                                : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </Button>
                  </div>
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                padding: 20,
              }}
            >
              <Typography
                style={{ color: "#616161", fontSize: 16, fontFamily: "inter" }}
              >
                No Notifications
              </Typography>
            </div>
          )}
        </PerfectScrollbar>
      </div>
    </Popover>
  );
};

export default EmailNotificationList;
