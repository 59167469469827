import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface SchedulePayload {
  title: string;
  date: Date;
}
export interface ScheduleState {
  schedule: {
    scheduleData: SchedulePayload[];
    authStatus: boolean;
  };
}

const initialState: ScheduleState = {
  schedule: {
    scheduleData: [],
    authStatus: false,
  },
};
export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    addSchedule: (state, action: PayloadAction<SchedulePayload>) => {
      state.schedule = {
        ...state.schedule,
        scheduleData: [...state.schedule.scheduleData, action.payload],
        authStatus: true,
      };
    },
  },
});

export const { addSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
