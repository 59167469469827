import React from "react";

const CandidateSpaceLogin = React.lazy(() =>
  import("views/space/CandidateSpaceLogin/Login")
);
const CandidateStart = React.lazy(() => import("views/space/CandidateSpace/CandidateProfile"));
const CandidateScreeningScreen = React.lazy(() =>
  import("views/space/CandidateScreeningScreen")
);
const CandidateSpaceRegistration = React.lazy(() =>
  import("views/space/CandidateSpaceRegistration")
);

const CandidateInterviewSubmit = React.lazy(() =>
  import("views/space/CandidateInterviewSubmitScreen")
);

const candidateSpaceRoutes = [
  {
    path: "/candidate_space/:candidateId/login",
    component: CandidateSpaceLogin,
    exact: true,
  },
  {
    path: "/candidate_space/:candidateId/register",
    component: CandidateSpaceRegistration,
    exact: true,
  },
  {
    path: "/candidate_space/:candidateId/start",
    component: CandidateStart,
    exact: true,
  },
  {
    path: "/candidate_space/:candidateId/start/screening",
    component: CandidateScreeningScreen,
    exact: true,
  },

  {
    path: "/candidate_space/submit/response",
    component: CandidateInterviewSubmit,
    exact: true,
  },
];

export default candidateSpaceRoutes;
