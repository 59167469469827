
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCcZKniTDtK5sx7K9n6KbeDZgsHNBF7h4Q",
  authDomain: "sound-arcade-303810.firebaseapp.com",
  projectId: "sound-arcade-303810",
  storageBucket: "sound-arcade-303810.appspot.com",
  messagingSenderId: "95818451674",
  appId: "1:95818451674:web:f12ebbc7b72534afa133e3",
  measurementId: "G-711NY2RXLD",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();


export { app, analytics };
