import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompanyState {
  cs_company: Record<string, unknown>;
}

const initialState: CompanyState = {
  cs_company: {},
};

export const companySlice = createSlice({
  name: "cs_company",
  initialState,
  reducers: {
    addCompany: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.cs_company = action.payload;
  
    },
  },
});

export const { addCompany } = companySlice.actions;

export default companySlice.reducer;
