import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface JobApplicationPayload {
  name: string;
  age: number;
}

interface JobApplicationState {
  job: {
    jobApplication: JobApplicationPayload;
  };
}

const initialState: JobApplicationState = {
  job: {
    jobApplication: {
      name: "",
      age: 0,
    },
  },
};

export const jobApplicationSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    updateJobApplicationData: (
      state,
      action: PayloadAction<JobApplicationPayload>
    ) => {
      state.job.jobApplication = {
        ...state.job.jobApplication,
        ...action.payload,
      };
    },
  },
});

export const { updateJobApplicationData } = jobApplicationSlice.actions;

export default jobApplicationSlice.reducer;
