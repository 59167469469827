import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface assistantState {
  assistant: String;
  threadId: String;
}

const initialState: assistantState = {
  assistant: "",
  threadId: "",
};

export const assistantSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAssistant: (state, action: PayloadAction<any>) => {
      state.assistant = action.payload;
    },
    updateThreadId: (state, action: PayloadAction<any>) => {
      state.threadId = action.payload;
    },
  },
});
export const { updateAssistant, updateThreadId } = assistantSlice.actions;

export default assistantSlice.reducer;
