import React, { FC, ReactNode, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import PerfectScrollbar from "react-perfect-scrollbar";

import "react-perfect-scrollbar/dist/css/styles.css";

interface BootstrapDialogTitleProps {
  children: ReactNode;
  onClose: () => void;
  hideClose?: boolean;
}

const BootstrapDialogTitle: FC<BootstrapDialogTitleProps> = ({
  children,
  onClose,
  hideClose,
  ...props
}) => {
  const classes = useStyles();

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...props}>
      <div
        style={{ display: "flex", flex: 1, flexDirection: "row", padding: 5 }}
      >
        <div style={{ display: "flex", flex: 1 }}>{children}</div>
        {onClose && !hideClose ? (
          <IconButton
            className={classes.closeBtn}
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    display: "flex",
    flex: 1,
    borderRadius: 10,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  closeBtn: {
    color: "#9e9e9e",
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: "#f44336",
    },
  },
}));

interface CustomizedDialogsProps {
  open: boolean;
  handleClose?: () => void;
  title?: string;
  children?: ReactNode;
  actions?: ReactNode;
  headerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  hideBackdrop?: boolean;
  hideClose?: boolean;
}

const CustomizedDialogs: FC<CustomizedDialogsProps> = ({
  open,
  handleClose,
  title,
  children,
  actions,
  headerStyle,
  contentStyle,
  hideBackdrop,
  hideClose = false,
  ...other
}: CustomizedDialogsProps) => {
  const classes = useStyles();

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      hideBackdrop={hideBackdrop}
    >
      {title && (
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          hideClose={hideClose}
          style={{ alignItems: "center", ...headerStyle }}
        >
          {title}
        </BootstrapDialogTitle>
      )}
      <PerfectScrollbar>
        {children ? (
          <DialogContent style={contentStyle}>{children}</DialogContent>
        ) : (
          <></>
        )}
      </PerfectScrollbar>
      {actions ? <DialogActions>{actions}</DialogActions> : <></>}
    </BootstrapDialog>
  );
};

CustomizedDialogs.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  // actions: PropTypes.node,
  headerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  hideBackdrop: PropTypes.bool,
  hideClose: PropTypes.bool,
};

export default CustomizedDialogs;
