import React, { useEffect, useLayoutEffect, useState } from "react";
import clsx from "clsx";
import {
  Menu,
  MenuItem,
  Fade,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router";
import { useLocation, NavLink, Link } from "react-router-dom";
import _ from "lodash";

import "assets/dashboard/css/sidebar.css";

import { Nav } from "react-bootstrap";
import axios from "utils/axios";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExtensionOutlinedIcon from "@mui/icons-material/ExtensionOutlined";
import KeyIcon from "@mui/icons-material/Key";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";

import PersonIcon from "@mui/icons-material/Person";

import NotificationBadge from "components/dashboard/Notification/NotificationBadge";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
// import { setSelectedElements } from "react-flow-renderer/dist/store/actions";

import { updateSidebarConfiguration } from "redux/dashboard/reducers/configSlice";
import { CANDIDATE_PORTAL_URL } from "constants/production";

const useStyles = makeStyles(() => ({
  accordion: {
    backgroundColor: "rgba(255, 255, 255, 0.0) !important",
    color: "#9e9e9e !important",
    "&:before": {
      display: "none",
    },
    "&:hover": {
      color: "#03a9f4 !important",
    },
    "&.active": {
      color: "#03a9f4 !important",
    },
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  settingsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 10,
    "&.active": {
      borderLeft: "4px solid #03a9f4",
    },
  },
  accordionLinkWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "inherit",
  },
  accordionHeader: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: "3px 15px",
  },
  accordionHeaderIcon: {
    marginLeft: 2.5,
    marginRight: 16,
  },
  accordionLinkIcon: {
    marginLeft: 2.5,
    marginRight: 16,
  },
  accordionLink: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: "3px 15px",
    color: "#9e9e9e",
    "&.active": {
      color: "#4fc3f7",
    },
    "&:hover": {
      color: "#03a9f4",
    },
  },
  accordionTitle: {
    margin: 0,
    lineHeight: "31px",
    fontSize: "16px",
    textTransform: "capitalize",
    display: "inline-flex",
    fontFamily: "poppins",
  },
  logoutContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "end",
  },
  logoutBtn: {
    height: 50,
    margin: 10,
  },
  expandSection: {
    display: "flex",
    alignItems: "center",
  },
  collapseBtn: {
    width: 50,
  },
  shareContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    flexDirection: "row",
    margin: 10,
  },
  sidebarLogoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  paper2: {
    backgroundColor: "#ffffff",
    borderRadius: "7px",
    padding: "5px 10px",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: 5,
  },
  sceneLinkContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    margin: 5,
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    borderRadius: 5,
  },
  copyBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#bdbdbd",
    "&:hover": {
      color: "#4fc3f7",
    },
  },
  avatar: {
    "& .MuiAvatar-img": {
      maxHeight: "100% !important",
      // maxWidth: "100% !important",
      objectFit: "contain",
    },
  },
}));

function Sidebar({ color, image, routes }) {
  const routerHistory = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const userData = useAppSelector((state) => state?.userReducer?.user);
  const userType = userData?.loggedUser?.role;

  const [isSetupaiAccordionExpanded, setIsSetupaiAccordionExpanded] =
    useState(false);
  const [isSettingsAccordionExpanded, setIsSettingsAccordionExpanded] =
    useState(false);

  const [isOpen, setIsOpen] = useState(
    location.pathname.includes("/dashboard/settings"),
  );

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (!userData?.isFirstLogin) {
      // dispatch(updateSidebarConfiguration({ isExpanded: true }))
      setIsSetupaiAccordionExpanded(true);
      setIsSettingsAccordionExpanded(true);
    }
    return () => {};
  }, []);

  const handleSetupaiAccordionExpand = () => {
    setIsSetupaiAccordionExpanded(!isSetupaiAccordionExpanded);
  };

  const handleSettingsAccordionExpand = () => {
    setIsSettingsAccordionExpanded(!isSettingsAccordionExpanded);
  };

  const notificationPath = useAppSelector(
    (state) => state.notificationReducer.notificationPath,
  );
  const companyIdDetails = useAppSelector(
    (state) => state?.userReducer?.user?.company?._id,
  );

  const isExpanded = useAppSelector(
    (state) => state?.configReducer?.config?.sidebar?.isExpanded,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [companyId, setCompanyId] = useState(companyIdDetails?._id);

  const [notificationParts, setNotificationParts] = useState([]);

  const open = Boolean(anchorEl);

  useEffect(() => {
    handleCompanyDetailsRead();

    return () => {};
  }, []);

  useEffect(() => {
    let isExpandedValue = true;

    if (
      location.pathname.includes("/dashboard/scene-setup") ||
      location.pathname.includes("/dashboard/inbox") ||
      location.pathname.includes("/dashboard/spaces")
    ) {
      isExpandedValue = false;
    }

    dispatch(updateSidebarConfiguration({ isExpanded: true }));

    return () => {};
  }, [location?.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/dashboard/inbox")) {
      dispatch(updateSidebarConfiguration({ isExpanded: false }));
    } else {
      dispatch(updateSidebarConfiguration({ isExpanded: true }));
    }
    return () => {};
  }, [location?.pathname]);

  useEffect(() => {
    if (location.pathname.includes("/dashboard/spaces")) {
      dispatch(updateSidebarConfiguration({ isExpanded: false }));
    } else {
      dispatch(updateSidebarConfiguration({ isExpanded: true }));
    }
    return () => {};
  }, [location?.pathname]);

  // collapse side bar on smaller screen
  useLayoutEffect(() => {
    function updateSideBar() {
      if (window.innerWidth <= 1024) {
        dispatch(updateSidebarConfiguration({ isExpanded: false }));
      } else {
        if (
          location.pathname.includes("/dashboard/scene-setup") ||
          location.pathname.includes("/dashboard/inbox") ||
          location.pathname.includes("/dashboard/spaces")
        ) {
          !!isExpanded
            ? dispatch(updateSidebarConfiguration({ isExpanded: false }))
            : "";
        } else {
          dispatch(updateSidebarConfiguration({ isExpanded: true }));
        }
      }
    }

    window.addEventListener("resize", updateSideBar);

    return () => {
      window.removeEventListener("resize", updateSideBar);
    };
  }, [window.innerWidth, location.pathname]);

  // notification
  useEffect(() => {
    if (notificationPath) {
      // splitting notification path
      const parts = [];
      notificationPath.map((item) =>
        parts.push(...item.split("/").filter(Boolean)),
      );

      setNotificationParts(_.uniq(parts));
    }
  }, [notificationPath]);

  const RenderBadge = ({ item, style }) => {
    return notificationParts.includes(item) ? (
      <NotificationBadge style={style} />
    ) : (
      <></>
    );
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1
      ? " text-[#22b9f4] bg-[#22b9f4]/[0.12] "
      : " text-white bg-transparent ";
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCompanyProfile = () => {
    routerHistory.push("/dashboard/profilepage");
  };

  const handleCompanyDetailsRead = () => {
    const admin = localStorage.getItem("adminId");
    if (admin) {
      axios
        .get("/company/getdetails?admin=" + admin, {
          headers: { Authorization: localStorage.getItem("matchToken") },
        })
        .then((res) => {
          const companyId = res.data.data[0]._id;

          setCompanyId(companyId);
        })

        .catch((err) => {});
    }
  };

  //department create
  const isDepartmentCreateAllowed = useAppSelector(
    (state) =>
      state?.userReducer?.user?.departmentCreatePermission?.permissions,
  );

  const [allowedDepartment, setAllowedDepartment] = useState();

  // dept permission block
  const deptPermission = useAppSelector(
    (state) => state?.userReducer?.user?.deptPermissions?.permissions,
  );

  useEffect(() => {
    if (deptPermission) {
      setAllowedDepartment(Object.keys(deptPermission));
    }
    return () => {};
  }, [deptPermission]);

  const [isAllowedDepartmentPermission, setIsAllowedDepartmentPermission] =
    useState(false);

  useEffect(() => {
    if (allowedDepartment && allowedDepartment.length) {
      allowedDepartment.map((_department) => {
        if (deptPermission[_department].length) {
          setIsAllowedDepartmentPermission(true);
        }
      });
    }

    return () => {};
  }, [allowedDepartment]);

  //inbox permission

  const inboxPermissionValue = useAppSelector(
    (state) => state?.userReducer?.user?.permissions?.permissions,
  );

  const inboxUserPermission =
    inboxPermissionValue &&
    inboxPermissionValue[0] &&
    inboxPermissionValue[0]?.permission;

  const [isInboxPermissionAllowed, setIsInboxPermissionAllowed] =
    useState(false);

  useEffect(() => {
    if (inboxUserPermission && inboxUserPermission.length) {
      inboxUserPermission.map((_data) => {
        if (_data.status == true) {
          setIsInboxPermissionAllowed(true);
        }
      });
    }

    return () => {};
  }, [inboxUserPermission]);

  const userPermission = useAppSelector(
    (state) => state?.userReducer?.user?.permissions,
  );

  // settings permission
  const [settingsPermissionData, setSettingsPermissionData] = useState();

  useEffect(() => {
    if (userType !== "candidate") {
      if (
        userPermission &&
        userPermission?.permissions &&
        userPermission?.permissions.length
      ) {
        const settingsPermissions = userPermission?.permissions
          .map((_permission) => {
            if (
              _permission?.name.toLowerCase().replace(/ /g, "-") === "settings"
            ) {
              return _permission?.permission;
            }
          })
          .filter(Boolean);
        setSettingsPermissionData(settingsPermissions);
      }
    }
    return () => {};
  }, [userPermission]);

  const [integrationsPermission, setIntegrationsPermission] = useState(false);
  const [permission, setPermission] = useState(false);
  const [profilePermission, setProfilePermission] = useState(false);

  useEffect(() => {
    if (settingsPermissionData && settingsPermissionData.length) {
      settingsPermissionData[0]?.map((_permission) => {
        if (_permission?.title === "Integrations")
          setIntegrationsPermission(_permission.status);
        else if (_permission?.title === "Permissions")
          setPermission(_permission.status);
        else if (_permission?.title === "Profile")
          setProfilePermission(_permission.status);
      });
    }
    return () => {};
  }, [settingsPermissionData]);

  // setup permission

  const [setupAiPermissionData, setSetupAiPermissionData] = useState();

  useEffect(() => {
    if (userPermission?.permissions && userPermission?.permissions.length) {
      const setupAiPermissionsList = userPermission?.permissions
        .map((_permission) => {
          if (
            _permission.name.toLowerCase().replace(/ /g, "-") === "setup-ai"
          ) {
            return _permission.permission;
          }
        })
        .filter(Boolean);
      setSetupAiPermissionData(setupAiPermissionsList);
    }
    return () => {};
  }, [userPermission]);

  const [knowledgeBasePermission, setKnowledgeBasePermission] = useState(false);
  const [userInteractionsPermission, setUserInteractionsPermission] =
    useState(false);
  const [botSettingsPermission, setBotSettingsPermission] = useState(false);

  useEffect(() => {
    if (setupAiPermissionData && setupAiPermissionData.length) {
      setupAiPermissionData[0].map((_permission) => {
        if (_permission.title === "Knowledge Base")
          setKnowledgeBasePermission(_permission.status);
        else if (_permission.title === "User Interactions")
          setUserInteractionsPermission(_permission.status);
        else if (_permission.title === "Bot Settings")
          setBotSettingsPermission(_permission.status);
      });
    }
    return () => {};
  }, [setupAiPermissionData]);

  // seensetup permission

  const isSceneSetupAllowed = useAppSelector(
    (state) => state?.userReducer?.user?.sceneSetupPermission?.permissions,
  );

  const [permissionData, setPermissionData] = useState();
  useEffect(() => {
    if (userPermission) {
      let permissions = ["insights", "departments"];
      let temp;

      if (userPermission?.permissions && userPermission?.permissions.length) {
        temp = userPermission?.permissions.map((_permission) => {
          return _permission?.name.toLowerCase().replace(/ /g, "-");
        });
      }
      setPermissionData(permissions.concat(temp));
    }
    return () => {};
  }, [userPermission]);

  const RenderSideBarIcon = ({ name, className, style }) => {
    switch (name) {
      case "Candidates":
        return (
          <>
            <RenderBadge item="inbox" />
            <PersonIcon className={className} style={style} />
          </>
        );

      case "Insights":
        return (
          <>
            <RenderBadge item="dashboard" />
            <InsertChartOutlinedIcon className={className} style={style} />
          </>
        );

      case "Spaces":
        return (
          <>
            <RenderBadge item="spaces" />
            <RocketLaunchOutlinedIcon className={className} style={style} />
          </>
        );

      case "Departments":
        return (
          <>
            <RenderBadge item="departments" />
            <AppsOutlinedIcon className={className} style={style} />
          </>
        );

      case "Space Setup":
        return (
          <>
            <RenderBadge item="scene-setup" />
            <HomeOutlinedIcon className={className} style={style} />
          </>
        );

      case "AI Assistant":
        return (
          <>
            <RenderBadge item="ai-assistant" />
            <SmartToyOutlinedIcon className={className} style={style} />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`absolute bottom-0 left-0 top-0 z-10 block overflow-x-hidden bg-slate-900 bg-cover bg-center transition-all delay-200 ease-linear ${isExpanded ? "w-[260px]" : "w-[70px]"}`}
      data-image={image}
      data-color="#fff"
    >
      <div className="flex flex-1 flex-col" />
      <div className="flex flex-1 flex-col px-2">
        <PerfectScrollbar>
          <div id="sidebar-scroll" className="!overflow-x-hidden delay-500">
            {isExpanded ? (
              <div className="flex flex-row items-center justify-center">
                <a className="mx-1 mb-6">
                  <div
                    className="mx-2 my-4 h-auto w-[180px]"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/assets/images/logo/bellohire-logo.png"
                      alt="bellohire"
                      loading="lazy"
                      className="h-auto w-[180px] bg-contain"
                    />
                  </div>
                </a>
              </div>
            ) : (
              <div className="mt-2 flex flex-row items-center justify-center">
                <a className="mx-1 mb-6 ml-6">
                  <div
                    className="mx-2 my-4 h-auto w-[180px]"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src="/assets/images/logo/favicon.png"
                      alt="bellohire"
                      className="h-auto w-9 bg-contain"
                    />
                  </div>
                </a>
              </div>
            )}

            <Nav>
              {routes.map((prop, key) => {
                if (prop?.name) {
                  if (prop.name === "Space Setup" && !isSceneSetupAllowed) {
                    return false;
                  }
                  if (prop.name === "Candidates" && !isInboxPermissionAllowed) {
                    return false;
                  }

                  if (
                    prop.name === "Departments" &&
                    !isDepartmentCreateAllowed &&
                    !isAllowedDepartmentPermission
                  ) {
                    return false;
                  }
                }

                if (!prop.redirect && prop?.name && prop?.type === "user")
                  return (
                    <li
                      className={
                        ((activeRoute(prop.path),
                        prop.name === "Space Setup" ? "Scenesetup" : prop.name),
                        " flex flex-1 flex-row items-center")
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className={`m-0 my-1 flex flex-1 flex-row p-2 ${activeRoute(prop.path)}`}
                        activeClassName="active"
                      >
                        <div className="ml-3 flex flex-1 flex-row items-center">
                          <RenderSideBarIcon
                            name={prop?.name}
                            style={{ fontSize: 24 }}
                            className="mr-4 p-0 px-1 text-lg"
                          />

                          <span className="font-poppins">{prop.name}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                return null;
              })}
            </Nav>

            {!integrationsPermission &&
            !permission &&
            !profilePermission ? null : (
              <div className="w-full">
                <div
                  className={clsx(
                    "flex h-[46px] cursor-pointer items-center pl-[25px]",
                    activeRoute("/dashboard/settings"),
                  )}
                  onClick={toggleDropdown}
                >
                  <SettingsOutlinedIcon />
                  <p className="ml-[1.1rem]">Settings</p>
                  <ExpandMoreIcon
                    className={`ml-auto transform transition-transform ${
                      isOpen ? "rotate-180" : ""
                    }`}
                  />
                </div>

                {isOpen && (
                  <ul className="-ml-6 list-none space-y-2">
                    {integrationsPermission && (
                      <li
                        className={clsx(
                          "rounded p-2",
                          activeRoute("/dashboard/settings/integrations"),
                        )}
                      >
                        <Link
                          to="/dashboard/settings/integrations/mail"
                          className="flex items-center"
                        >
                          <ExtensionOutlinedIcon className="mr-5" />
                          Integrations
                        </Link>
                      </li>
                    )}
                    <li
                      className={clsx(
                        "rounded p-2",
                        activeRoute("/dashboard/settings/other-settings"),
                      )}
                    >
                      <Link
                        to="/dashboard/settings/other-settings/tags"
                        className="flex items-center"
                      >
                        <SettingsSuggestIcon className="mr-5" />
                        Other Settings
                      </Link>
                    </li>
                    {permission && (
                      <li
                        className={clsx(
                          "rounded p-2",
                          activeRoute("/dashboard/settings/permissions"),
                        )}
                      >
                        <Link
                          to="/dashboard/settings/permissions/users"
                          className="flex items-center"
                        >
                          <KeyIcon className="mr-5" />
                          Permissions
                        </Link>
                      </li>
                    )}
                    {profilePermission && (
                      <li
                        className={clsx(
                          "rounded p-2",
                          activeRoute("/dashboard/settings/profile"),
                        )}
                      >
                        <Link
                          to="/dashboard/settings/profile"
                          className="flex items-center"
                        >
                          <ManageAccountsOutlinedIcon className="mr-5" />
                          Profile
                        </Link>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            )}
          </div>
        </PerfectScrollbar>
      </div>
      <Menu
        className="menu-item"
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={open}
        anchorPosition={{ top: 196, left: 396 }}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            handleCompanyProfile();
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            // logout();
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
export default Sidebar;
