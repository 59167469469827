import React from "react";
import Modal from "components/Modal";

type Props = {
  open: boolean;
  handleClose: any;
};

import "./terms-condition.css";

const TermsConditionModal = ({ open, handleClose }: Props) => {
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title="Terms and Conditions"
      actions={<></>}
      headerStyle={{
        fontWeight: "900",
        fontSize: "1.5rem",
        fontFamily: "'Poppins', 'Inter', sans-serif",
      }}
    >
      <>
        <h2 className="subtitle">1. User Eligibility</h2>
        <p>
          You must be at least 18 years old to use our website and services. By
          using our website, you confirm that you have the legal capacity to
          enter into a contract.
        </p>

        <h2 className="subtitle">2. Account Registration</h2>
        <p>
          To access certain features of our website, you may need to create an
          account. You are responsible for maintaining the confidentiality of
          your account information and password. You agree to provide accurate
          and up-to-date information during the registration process.
        </p>

        <h2 className="subtitle">3. Job Listings</h2>
        <p>
          Our website provides job listings posted by employers and recruiters.
          We do not guarantee the accuracy or availability of these listings. We
          are not responsible for the content of job listings or the actions of
          employers or recruiters.
        </p>

        <h2 className="subtitle">4. Application Process</h2>
        <p>
          You may apply for jobs through our website by submitting your
          application and relevant information. We are not responsible for the
          outcome of your applications or any communication between you and the
          employer or recruiter.
        </p>

        <h2 className="subtitle">5. Content Usage</h2>
        <p>
          The content on our website, including but not limited to text, images,
          and logos, is protected by copyright and other intellectual property
          laws. You may not use, copy, distribute, or modify any content from
          our website without our prior written consent.
        </p>

        <h2 className="subtitle">6. User Conduct</h2>
        <p>
          You agree not to engage in any unlawful, harmful, or abusive behavior
          on our website. You must not upload or transmit any viruses, malware,
          or harmful code to our website.
        </p>

        <h2 className="subtitle">7. Privacy Policy</h2>
        <p>
          Our Privacy Policy governs how we collect, use, and disclose your
          personal information. By using our website, you consent to our Privacy
          Policy.
        </p>

        <h2 className="subtitle">8. Termination of Access</h2>
        <p>
          We reserve the right to terminate or suspend your access to our
          website at any time and for any reason without notice. You may also
          terminate your account at any time by contacting us.
        </p>

        <h2 className="subtitle">9. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We do not
          endorse or control these websites and are not responsible for their
          content or practices.
        </p>

        <h2 className="subtitle">10. Disclaimer of Warranty</h2>
        <p>
          We provide our website and services "as is" without any warranty or
          representation, express or implied. We do not guarantee the accuracy,
          reliability, or completeness of the information on our website.
        </p>

        <h2 className="subtitle">11. Limitation of Liability</h2>
        <p>
          We are not liable for any direct, indirect, incidental, special, or
          consequential damages resulting from your use of our website or
          services.
        </p>

        <h2 className="subtitle">12. Modification of Terms</h2>
        <p>
          We may update or modify these Terms from time to time. Any changes
          will be effective upon posting on our website.
        </p>

        <h2 className="subtitle">13. Governing Law and Jurisdiction</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of [Your Country/Region]. Any disputes arising from these Terms
          shall be subject to the exclusive jurisdiction of the courts in [Your
          Country/Region].
        </p>

        <h2 className="subtitle">14. Contact Information</h2>
        <p>
          If you have any questions or concerns about these Terms or our
          website, please contact us at [Your Contact Information].
        </p>

        <h1 className="title">Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>
            <p>By email: admin@recodeai.com</p>
          </li>
          <li>
            <p>By phone number: +918943133501</p>
          </li>
        </ul>
        <footer>
          <p>&copy; 2023 Bellohire. All rights reserved.</p>
        </footer>
      </>
    </Modal>
  );
};

export default TermsConditionModal;
