import axios from "utils/axios";

export const getCandidateAssistantList = (candidateId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/candidate/${candidateId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getCompanyAssistantList = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/company/${companyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getCompanyList = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/company/list/${companyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getDepartmentList = (departmentId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/department/list/${departmentId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getDepAssistantList = (departmentId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/dep/${departmentId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const getJobAssistantList = (jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/job/${jobId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const updateCandidateAssistant = (assistantId, data) =>
  new Promise((resolve, reject) => {
    axios
      .patch(`/assistant/update/assistant/${assistantId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createCandidateAssistant = (_candidate, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/candidate/create/assistant/${_candidate}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createCompanyAssistant = (_company, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/company/create/assistant/${_company}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createDepartmentAssistant = (_department, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/department/create/assistant/${_department}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const createJobAssistant = (_job, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/job/create/assistant/${_job}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteAssistant = (assistantId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/assistant/delete/assistants/${assistantId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const assistantChat = (assistantId, content, threadId) =>
  new Promise((resolve, reject) => {
    axios
      .post("/assistant/thread/create/thread", {
        assistantId,
        content,
        threadId,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const assistantInteraction = (assistantId, content) =>
  new Promise((resolve, reject) => {
    axios
      .post("/assistant/thread/create/interaction", { assistantId, content })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const listMessages = (assistantId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/thread/list/${assistantId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const listThreadMessages = (threadId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/thread/list/thread/${threadId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const deleteMessage = (messageId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/assistant/thread/delete/message/${messageId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const trainAssistant = (candidateId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/train/candidate/${candidateId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const filterAssistant = ({ type, companyId, departmentId }) =>
  new Promise((resolve, reject) => {
    const queryParams = {
      type,
      companyId: companyId || undefined,
      departmentId: departmentId || undefined,
    };

    axios
      .get(`/assistant/list/filter`, {
        params: queryParams,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const fileUpload = (assistantId, formData) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/upload/file/${assistantId}`, formData)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const findAssistant = (assistantId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/find/data/${assistantId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findAssistantByJob = (jobId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/find/data/job/${jobId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findAssistantByCandidate = (candidateId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/find/data/candidate/${candidateId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const findAssistantByCompany = (companyId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/find/data/company/${companyId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const listTraining = (assistantId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/assistant/list/training/${assistantId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });

export const training = (assistantId, data) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/assistant/train/data/${assistantId}`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
