import { app } from "../config/firebase";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  GithubAuthProvider,
  TwitterAuthProvider,
} from "firebase/auth";

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();
const twitterProvider = new TwitterAuthProvider();

const signInWithGoogle = (): Promise<any> =>
  new Promise((resolve, reject) => {
    signInWithPopup(auth, googleProvider)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });

const signInWithGithub = (): Promise<any> =>
  new Promise((resolve, reject) => {
    githubProvider.addScope("read:user");
    signInWithPopup(auth, githubProvider)
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        resolve(result);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        reject(error);
      });
  });

const signInWithTwitter = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    twitterProvider.addScope("repo");
    signInWithPopup(auth, twitterProvider)
      .then((result) => {
        // This gives you the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        // const credential = TwitterAuthProvider.credentialFromResult(result);

        // The signed-in user info.
        const user = result.user;
        // ...
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { signInWithGoogle, signInWithGithub, signInWithTwitter };
