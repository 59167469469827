import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface JobState {
  cs_job: any;
}

const initialState: JobState = {
  cs_job: {},
};

export const jobSlice = createSlice({
  name: "cs_job",
  initialState,
  reducers: {
    addJob: (state, action: PayloadAction<any>) => {
      state.cs_job = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addJob } = jobSlice.actions;

export default jobSlice.reducer;
