import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Notification {
  title: string;
}

export interface EmailNotificationState {
  emailNotificationCount: number;
  notifications: Notification[];
}

const initialState: EmailNotificationState = {
  emailNotificationCount: 0,
  notifications: [],
};

export const emailNotificationSlice = createSlice({
  name: "emailNotification",
  initialState,
  reducers: {
    addEmailNotification: (state: EmailNotificationState, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
    },
    updateEmailNotificationList: (state: EmailNotificationState, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    setEmailNotificationCount: (state: EmailNotificationState, action: PayloadAction<number>) => {
      state.emailNotificationCount = action.payload;
    },
    updateEmailNotificationCount: (state: EmailNotificationState, action: PayloadAction<number>) => {
      state.emailNotificationCount += action.payload;
    },
  },
});

export const {
  addEmailNotification,
  updateEmailNotificationList,
  setEmailNotificationCount,
  updateEmailNotificationCount,
} = emailNotificationSlice.actions;

export default emailNotificationSlice.reducer;
