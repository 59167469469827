import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface InboxState {
  config: {
    [key: string]: string | number | boolean;
  };
}

const initialState: InboxState = {
  config: {},
};

interface InboxConfigPayload {
  [key: string]: string | number | boolean;
}

export const inboxSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    updateInboxConfig: (
      state: InboxState,
      action: PayloadAction<InboxConfigPayload>
    ) => {
      // use the immer library to update the state
      return { ...state, config: { ...state.config, ...action.payload } };
    },
  },
});

export const { updateInboxConfig } = inboxSlice.actions;

export default inboxSlice.reducer;
