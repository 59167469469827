import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ScreeningState {
  cs_screening: Record<string, unknown>;
}

const initialState: ScreeningState = {
  cs_screening: {},
};

export const screeningSlice = createSlice({
  name: "cs_screening",
  initialState,
  reducers: {
    addScreening: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.cs_screening = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addScreening } = screeningSlice.actions;

export default screeningSlice.reducer;
