import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/space/css/main.css";
import { Upgrade } from "@mui/icons-material";
import Admin from "layouts/Admin";
// import EmployerPage from "views/space/website/Home/Employer";

// space pages

const { LinkedInCallback } = React.lazy(() => import("hooks/LinkedIn"));
const DepartmentShare = React.lazy(
  () => import("views/space/Share/Department"),
);
const JobShare = React.lazy(() => import("views/space/Share/Job"));

const TechnicalInterviewStart = React.lazy(
  () => import("views/space/TechnicalInterview"),
);

const ScreeningStart = React.lazy(() => import("views/space/Screening"));
const InterviewStart = React.lazy(() => import("views/space/Interview"));

// website pages
const HomePage = React.lazy(() => import("views/space/website/Home/HomePage"));
const UserDashboardSpacePage = React.lazy(
  () => import("views/dashboard/Space/SpacePage"),
);

// dashboard pages
const profilePage = React.lazy(() => import("views/dashboard/profilePage"));
const userProfilePage = React.lazy(
  () => import("views/dashboard/UserProfilePage"),
);
const SceneSetting = React.lazy(() => import("views/dashboard/SceneSetting"));
const departmentList = React.lazy(
  () => import("views/dashboard/department/departmentList"),
);
const JobEditScreen = React.lazy(
  () => import("views/dashboard/job/JobEditScreen"),
);
const KnowledgeBase = React.lazy(() => import("views/dashboard/Knowledge"));
const TrainingList = React.lazy(
  () => import("views/dashboard/Knowledge/TrainingList"),
);
const Interactions = React.lazy(
  () => import("views/dashboard/Knowledge/Interactions"),
);
// const ResetPassword = React.lazy(() =>
//   import("views/space/LoginScreen/ResetPassword")
// );
const BotList = React.lazy(() => import("views/dashboard/Knowledge/BotList"));
const DashboardPage = React.lazy(() => import("views/dashboard/Insight"));

const Records = React.lazy(() => import("views/dashboard/Records"));
const AllNotifications = React.lazy(
  () => import("views/dashboard/AllNotifications"),
);
const AllEmails = React.lazy(() => import("views/dashboard/AllEmails"));
const CandidatePage = React.lazy(
  () => import("views/dashboard/candidate/CandidatePage"),
);
const IntegrationPage = React.lazy(
  () => import("views/dashboard/settings/IntegrationPage"),
);
const OtherSettingsPage = React.lazy(
  () => import("views/dashboard/settings/otherSettings"),
);
const PermissionsPage = React.lazy(
  () => import("views/dashboard/permissions/PermissionsPage"),
);
const CompanyRegistration = React.lazy(
  () => import("views/dashboard/auth/CompanyRegistration"),
);

const Login = React.lazy(() => import("views/auth/Login"));
const CompanyUserRegister = React.lazy(
  () => import("views/auth/CompanyUserRegister"),
);

const CandidateRegistration = React.lazy(
  () => import("views/dashboard/auth/CandidateRegistration"),
);
const CandidateLogin = React.lazy(
  () => import("views/dashboard/auth/CandidateLogin"),
);

const ForgotPassword = React.lazy(() => import("views/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("views/auth/ResetPassword"));
const CandidateResetPassword = React.lazy(
  () => import("views/dashboard/auth/CandidateResetPassword"),
);
const ScreenRecording = React.lazy(
  () => import("views/space/Recording/ScreenRecording"),
);
const RolePermissions = React.lazy(
  () => import("views/dashboard/permissions/RolePermissions"),
);
const CreateRole = React.lazy(
  () => import("views/dashboard/permissions/CreateRole"),
);
const ModalTestScreen = React.lazy(
  () => import("views/dashboard/ModalTestScreen"),
);
const RolesList = React.lazy(() => import("views/dashboard/RolesList"));
const JobList = React.lazy(() => import("views/dashboard/department/JobList"));

const companyAssistant = React.lazy(
  () => import("views/dashboard/Knowledge/BotList/companyAssistant"),
);

const jobAssistant = React.lazy(
  () => import("views/dashboard/Knowledge/BotList/jobAssistant"),
);

const jobAssistantPage = React.lazy(
  () => import("views/dashboard/Knowledge/BotList/jobAssistantPage"),
);

const departmentAssistant = React.lazy(
  () => import("views/dashboard/Knowledge/BotList/departmentAssistant"),
);

const depAssistantPage = React.lazy(
  () => import("views/dashboard/Knowledge/BotList/depAssistantPage"),
);

const TaskDetails = React.lazy(
  () => import("views/dashboard/inbox/messagePanel/TaskDetails"),
);
const InboxScreen = React.lazy(
  () => import("views/dashboard/inbox/inboxScreen"),
);
const Pipeline = React.lazy(() => import("views/dashboard/pipeline/Pipeline"));
const AccessCodePage = React.lazy(
  () => import("views/dashboard/settings/otherSettings/AccessCodePage"),
);
const actionPage = React.lazy(
  () => import("views/dashboard/settings/otherSettings/actionPage"),
);

//candidate Login
const CandidateInsight = React.lazy(
  () => import("views/dashboard/Candidates/Insights/CandidateInsight"),
);
const CandidateScreening = React.lazy(
  () => import("views/dashboard/Candidates/Screening/CandidateScreening"),
);
const CandidateInbox = React.lazy(
  () => import("views/dashboard/Candidates/Inbox/CandidateInbox"),
);
// const CandidateSceneSetup = React.lazy(
//   () => import("views/dashboard/Candidates/SceneSetup/CandidateSceneSetup"),
// );
const CandidateSceneSetting = React.lazy(
  () => import("views/dashboard/Candidates/SceneSetup/CandidateSceneSetting"),
);
const CandidateSetupAi = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/CandidateSetupAi"),
);
const CandidateSettings = React.lazy(
  () => import("views/dashboard/Candidates/Settings/CandidateSettings"),
);
const CandidateKnowledgeBase = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/knowledges"),
);
const candidateTrainings = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/TrainingList"),
);
const CandidateBotList = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/assistant/index"),
);
const CandidateInteractions = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/Interactions"),
);

const ScenePreview = React.lazy(() => import("views/dashboard/ScenePreview"));

// Auth pages
const CreateAccount = React.lazy(() => import("views/auth/CreateAccount"));
const CandidateVerification = React.lazy(
  () => import("views/auth/CandidateVerification"),
);

// Layouts
const AdminLayout = React.lazy(() => import("layouts/Admin"));
const AuthLayout = React.lazy(() => import("layouts/Auth"));
const CandidateLayout = React.lazy(() => import("layouts/Candidate"));
const WebsiteLayout = React.lazy(() => import("layouts/Website/WebsiteLayout"));
const SpaceBasicLayout = React.lazy(() => import("layouts/Space/BasicLayout"));
const CompanySpaceLayout = React.lazy(
  () => import("layouts/Space/CompanySpaceLayout"),
);
const CompanyRecruiterVerify = React.lazy(
  () => import("views/dashboard/auth/CompanyOtpVerification"),
);
const TwoFactorVerify = React.lazy(
  () => import("views/dashboard/auth/TwoFactorVerify"),
);

// chat inbox
const ChatInboxScreen = React.lazy(() => import("views/dashboard/Chat/Inbox"));
const MailInboxScreen = React.lazy(
  () => import("views/dashboard/Candidates/Mail/Inbox"),
);
const NotificationInboxScreen = React.lazy(
  () => import("views/dashboard/Candidates/Notification/Inbox"),
);

const AssistantScreen = React.lazy(() => import("views/dashboard/assistant"));

const candidateAssistant = React.lazy(
  () =>
    import("views/dashboard/Candidates/SetupAI/assistant/candidateAssistant"),
);
const candidateAssistantScreen = React.lazy(
  () => import("views/dashboard/Candidates/SetupAI/assistant/inde"),
);

// const candidateAssistantInteractions = React.lazy(() =>
//   import("views/dashboard/Candidates/SetupAI/assistant/")
// );

const flow = React.lazy(() => import("views/flow"));

const PaymentPage = React.lazy(() => import("views/dashboard/Payment"));

const UpgradePage = React.lazy(() => import("views/dashboard/Payment/upgrade"));

const successPage = React.lazy(
  () => import("views/dashboard/Payment/successPage"),
);

const trialPage = React.lazy(() => import("views/dashboard/Payment/trialPage"));

const EmployerPage = React.lazy(
  () => import("views/space/website/Home/Employer"),
);

const EmployeesPage = React.lazy(
  () => import("views/space/website/Home/Employees"),
);

const SpacePage = React.lazy(() => import("views/space/website/Home/Spaces"));

const CaseStudyPage = React.lazy(
  () => import("views/space/website/Home/CaseStudy"),
);

const routes = [
  {
    path: "/",
    component: HomePage,
    layout: WebsiteLayout,
    exact: true,
  },

  {
    path: "/case-study",
    component: CaseStudyPage,
    layout: WebsiteLayout,
    exact: true,
  },
  {
    path: "/create_account",
    component: CreateAccount,
    layout: AuthLayout,
    exact: true,
  },
  {
    path: "/start/interview/technical/:companyId/:jobId/:candidateId",
    component: TechnicalInterviewStart,
    layout: CompanySpaceLayout,
  },
  {
    path: "/start/screening/:companyId/:jobId/:candidateId",
    component: ScreeningStart,
    layout: CompanySpaceLayout,
  },
  {
    path: "/start/interview/:companyId/:jobId/:candidateId",
    component: InterviewStart,
    layout: CompanySpaceLayout,
  },
  {
    path: "/share/company/:companyId/department/:departmentId/job/:jobId",
    component: JobShare,
  },
  {
    path: "/share/company/:companyId/department/:departmentId",
    component: DepartmentShare,
  },
  {
    path: "/linkedin",
    component: LinkedInCallback,
    exact: true,
  },
  // end of candidate space routes
  {
    path: "/login",
    component: Login,
    layout: AuthLayout,
    exact: true,
  },
  {
    path: "/forgot/password",
    component: ForgotPassword,
    layout: AuthLayout,
    exact: true,
  },
  {
    path: "/reset/password",
    component: ResetPassword,
    layout: AuthLayout,
    exact: true,
  },
  {
    path: "/verify/candidate/:userId/:verificationCode",
    component: CandidateVerification,
    layout: AuthLayout,
  },
  // {
  //   path: "/reset/password",
  //   component: ResetPassword,
  //   layout: AuthLayout,
  //   exact: true,
  // },
  // {
  //   path: "/candidate/reset/password",
  //   component: CandidateResetPassword,
  //   layout: AuthLayout,
  //   exact: true,
  // },
  {
    path: "/registration/:company/invite/:inviteId",
    component: CompanyUserRegister,
  },
  {
    path: "/register/company",
    component: CompanyRegistration,
    layout: AuthLayout,
    exact: true,
  },

  // candidate sidebar
  // {
  //   path: "/candidate/insights",
  //   name: "Insights",
  //   component: CandidateInsight,
  //   layout: CandidateLayout,
  //   type: "candidate-dashboard",
  //   exact: true,
  // },
  {
    path: "/screen/recording",
    // name: "Recording",
    component: ScreenRecording,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/spaces",
    name: "Spaces",
    component: UserDashboardSpacePage,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/screening",
    name: "Screening",
    component: CandidateScreening,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/chats",
    name: "Chats",
    component: ChatInboxScreen,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/scene-setup",
    name: "Space Setup",
    component: CandidateSceneSetting,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/scene/preview/type/:type/:id",
    component: ScenePreview,
  },

  {
    path: "/candidate/setup-ai/bots/knowledge-base/bot/:bot",
    component: CandidateKnowledgeBase,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/setup-ai/assistant",
    component: candidateAssistantScreen,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/setup-ai/bots",
    component: candidateAssistant,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/setup-ai/interactions",
    component: CandidateInteractions,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/setup-ai/bots/knowledge-base/:knowledgeBaseId/bot/:bot/trainings",
    component: candidateTrainings,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/settings",
    name: "Settings",
    component: CandidateSettings,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  // {
  //   path: "/candidate/chats/:selectedUserId/:company_Ids",
  //   component: ChatInboxScreen,
  //   layout: CandidateLayout,
  //   type: "candidate-dashboard",
  //   exact: true,
  // },
  {
    path: "/candidate/chats/:selectedUserId",
    component: ChatInboxScreen,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/chats/:selectedUserId/:company_Ids",
    component: ChatInboxScreen,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },

  {
    path: "/candidate/AllEmails",
    component: AllEmails,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/notifications",
    component: AllNotifications,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },

  {
    path: "/scene/preview/type/:type/:id",
    component: ScenePreview,
  },
  {
    path: "/candidate/setup-ai/bots/knowledge-base/bot/:bot",
    component: CandidateKnowledgeBase,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },

  // {
  //   path: "/knowledge-base/:knowledgeBaseId/bot/:bot/trainings",
  //   component: candidateTrainings,
  //   layout: CandidateLayout,
  //   type: "candidate-dashboard",
  //   exact: true,
  // },
  {
    path: "/candidate/setup-ai/bots",
    component: CandidateBotList,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/candidate/setup-ai/interactions",
    component: CandidateInteractions,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },
  {
    path: "/dashboard/insights",
    name: "Insights",
    component: DashboardPage,
    layout: AdminLayout,
    type: "user",
    // exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/spaces",
    name: "Spaces",
    component: UserDashboardSpacePage,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/departments/:departmentId/job/:jobId?/:tab?",
    component: JobEditScreen,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/departments/:departmentId",
    component: JobList,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/departments",
    name: "Departments",
    component: departmentList,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  // inbox
  {
    path: "/dashboard/inbox/taskDetails/:jobId/:candidateId",
    component: TaskDetails,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/inbox/records/:jobId?/:candidateId?",
    component: InboxScreen,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/chat/inbox/:selectedUserId?",
    component: ChatInboxScreen,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  // Pipeline
  {
    path: "/dashboard/inbox",
    component: Pipeline,
    name: "Candidates",
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/inbox/:departmentId?/:jobId?/:candidateId?",
    defaultUrl: "/inbox",
    component: CandidatePage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/candidate/:departmentId/:jobId/:candidateId",
    component: CandidatePage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/scene-setup",
    name: "Space Setup",
    component: SceneSetting,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/profile",
    component: profilePage,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/user-profile",
    component: userProfilePage,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/integrations",
    component: IntegrationPage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/other-settings",
    component: OtherSettingsPage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/meet/oauth/redirect",
    component: AccessCodePage,
    exact: true,
  },
  {
    path: "/actions",
    component: actionPage,
    exact: true,
  },
  {
    path: "/dashboard/settings/permissions/users",
    component: PermissionsPage,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/permissions/settings",
    component: RolePermissions,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/permissions/create",
    component: CreateRole,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/settings/permissions/roles",
    component: RolesList,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/modal",
    component: ModalTestScreen,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/records/:candidateId/:jobId",
    component: Records,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/notifications",
    component: AllNotifications,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/AllEmails",
    component: AllEmails,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/bots/department/:_departmentId/job/:jobId/assistant",
    component: jobAssistantPage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/setup-ai/bots/department/:_departmentId/job/:jobId",
    component: jobAssistant,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/setup-ai/bots/department/:_departmentId/assistant",
    component: depAssistantPage,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/dashboard/setup-ai/bots/department/:_departmentId",
    component: departmentAssistant,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/bots",
    name: "AI Assistant",
    component: BotList,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/bots/:companyId",
    component: companyAssistant,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/bots/knowledge-base/bot/:bot",
    component: KnowledgeBase,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/interactions",
    component: Interactions,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/dashboard/setup-ai/bots/knowledge-base/:knowledgeBaseId/bot/:bot/trainings",
    component: TrainingList,
    layout: AdminLayout,
    type: "user",
    protected: true,
    redirectPath: "/login",
  },
  {
    path: "/login/verify/user/:userId",
    component: TwoFactorVerify,
    layout: AuthLayout,
  },
  {
    path: "/login/recruiter/verifyOtp/:userId",
    component: CompanyRecruiterVerify,
    layout: AuthLayout,
  },
  {
    path: "/start/flow/:userType?/:step?/:userId?",
    component: flow,
  },

  {
    path: "/dashboard/setup-ai/assistant",
    component: AssistantScreen,
    layout: AdminLayout,
    type: "user",
    exact: true,
    protected: true,
    redirectPath: "/login",
  },

  {
    path: "/candidate/setup-ai/assistant",
    component: candidateAssistantScreen,
    layout: CandidateLayout,
    type: "candidate-dashboard",
    exact: true,
  },

  {
    path: "/dashboard/payment",
    component: PaymentPage,
    layout: AdminLayout,
    exact: true,
    type: "user",
    redirectPath: "/login",
  },

  {
    path: "/dashboard/upgrade",
    component: UpgradePage,
    layout: AdminLayout,
    exact: true,
    type: "user",
    redirectPath: "/login",
  },

  {
    path: "/dashboard/successPage",
    component: successPage,
    layout: AdminLayout,
    exact: true,
    type: "user",
    redirectPath: "/login",
  },

  {
    path: "/dashboard/trial",
    component: trialPage,
    layout: AdminLayout,
    exact: true,
    type: "user",
    redirectPath: "/login",
  },
  {
    path: "/employer",
    component: EmployerPage,
    layout: WebsiteLayout,
    exact: true,
  },
  {
    path: "/employees",
    component: EmployeesPage,
    layout: WebsiteLayout,
    exact: true,
  },
  {
    path: "/spaces",
    component: SpacePage,
    layout: WebsiteLayout,
    exact: true,
  },
];

export default routes;
